import { asArray, asNumber, asObject } from '@restless/sanitizers'
import { HistoryEntry } from 'types'
import { asScoreExplanation, ScoreExplanation } from './scoreExplanation'

export interface ScoreMetadata {
  rank: number
  percentile: number
  explanations: ScoreExplanation[]
  history?: HistoryEntry[]
}

export const asScoreMetadata = asObject<ScoreMetadata>({
  rank: asNumber,
  percentile: asNumber,
  explanations: asArray(asScoreExplanation),
})
