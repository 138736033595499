import { Box, Spinner } from 'components'
import { lazy, Suspense } from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'

const PassportPage = lazy(() => import('./pages/Passport/PassportPage'))
const RankingPage = lazy(() => import('./pages/Rankings/RankingsPage'))
const SkinsPage = lazy(() => import('./pages/Skins/SkinsPage'))
const StakingPage = lazy(() => import('./pages/Staking/StakingPage'))
const BorrowPage = lazy(() => import('./pages/Borrow/BorrowPage'))
const SupplyPage = lazy(() => import('pages/Supply/SupplyPage'))

export const Routes = () => {
  return (
    <Suspense
      fallback={
        <Box
          style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'transform: translate(-50%, -50%)',
          }}
        >
          <Spinner />
        </Box>
      }
    >
      <Switch>
        <Route path="/supply" exact>
          <SupplyPage />
        </Route>
        <Route path="/rankings" exact>
          <Redirect to="/rankings/arcx.credit" />
        </Route>
        <Route path="/rankings/:protocol">
          <RankingPage />
        </Route>
        <Route path="/borrow">
          <BorrowPage />
        </Route>
        <Route path="/staking">
          <StakingPage />
        </Route>
        <Route path="/skins">
          <SkinsPage />
        </Route>
        <Route path={['/passport', '/passport/:address', '/passport/:address/:protocol']} exact>
          <PassportPage />
        </Route>
        <Redirect to="/borrow" />
      </Switch>
    </Suspense>
  )
}
