import styled from 'styled-components'
import { Colors } from '../../constants'
import { loading } from './animations'

export const SkeletonCircle = styled.div`
  width: 40px;
  height: 40px;
  background-color: ${Colors.PURPLE_4}50;
  border-radius: 20px;
  position: relative;
  overflow: hidden;

  &:before {
    content: '';
    display: block;
    position: absolute;
    left: -300px;
    top: 0;
    height: 100%;
    width: 100%;
    background: linear-gradient(to right, ${Colors.PURPLE_4} 0%, rgba(256, 256, 256, 0.1) 100%);
    animation: ${loading} 1000ms ease-in-out infinite;
  }
`
