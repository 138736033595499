import { ExternalLinkIcon } from '@heroicons/react/outline'
import _ from 'lodash'
import styled from 'styled-components'
import { getSpacing } from 'utils'
import { SpeedometerIcon } from '../../assets'
import { Colors } from '../../constants'
import { Box } from '../Box'
import { Tooltip } from '../Tooltip'
import { Link, Text, Title } from '../Typography'
import { CreditScoreChart } from './CreditScoreChart'
import { DataPoint } from './DataPoint'

const CardContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`

const CardBackground = styled.div`
  border-radius: 14px;
  background-color: ${Colors.BG_ELEVATED};
  height: 100%;
  width: 100%;
  overflow: hidden;
`

const TextContainer = styled.div`
  position: absolute;
  top: ${getSpacing('m')};
  bottom: ${getSpacing('m')};
  right: ${getSpacing('m')};
  left: ${getSpacing('m')};
`

export interface ICreditScoreCardProps {
  chartData: DataPoint[]
  maxScore: number
  currentScore: number | null
  aboutCreditScoreUrl: string
  last24hChange?: number
  tooltip?: string
  loading?: boolean
}

export const CreditScoreCard = ({
  chartData,
  maxScore,
  aboutCreditScoreUrl,
  currentScore = null,
  last24hChange,
  tooltip,
  loading,
}: ICreditScoreCardProps) => {
  // If `chartData` is null, populate it with 0 values so that a line is still shown
  let data = _.cloneDeep(chartData)
  if (data.length === 0) {
    data = [
      {
        timestamp: Date.now(),
        score: 0,
      },
      {
        timestamp: Date.now() + 1,
        score: 0,
      },
    ]
  }

  /**
   * If all points are identical, increase the last point by 0.0001 as a workaround of the
   * disappearing line bug when all points have the same value:
   * https://github.com/recharts/recharts/issues/1234
   */
  if (allEqualValues(data)) {
    data[data.length - 1].score += 0.0001
  }

  return (
    <CardContainer>
      <CardBackground>
        {!loading && <CreditScoreChart data={data} maxScore={maxScore} />}
      </CardBackground>
      <TextContainer>
        <Box flexDirection="column" alignItems="start" justifyContent="space-between" height="100%">
          <Box>
            <Box flexDirection="row" alignItems="center">
              <Title tag="h2" size="m" weight="semi-bold">
                Credit score
              </Title>
              {tooltip && (
                <Box marginLeft={getSpacing('xs')}>
                  <Tooltip content={tooltip} />
                </Box>
              )}
            </Box>
            <Box marginTop={getSpacing('xl')} flexDirection="row" alignItems="baseline">
              <Box paddingRight={getSpacing('s')}>
                <img src={SpeedometerIcon} width="32px" />
              </Box>
              <Box flexDirection="row" alignItems="baseline">
                <Box paddingRight={getSpacing('xs')}>
                  <Text overwriteSize={36}>
                    {loading || currentScore === null ? '-' : currentScore}
                  </Text>
                </Box>
                <Text size="s" color="TEXT_DARK">
                  /999
                </Text>
              </Box>
            </Box>
            <Box marginTop={getSpacing('s')} flexDirection="row">
              <Box>
                <Text color="TEXT_DARK" weight="semi-bold" size="s">
                  {last24hChange !== undefined && last24hChange >= 0 && !loading ? '+' : ''}
                  {loading ? '-' : last24hChange || '0'}
                </Text>
              </Box>
              <Text color="TEXT_DARK" size="s" weight="regular">
                &nbsp;in the last 24h
              </Text>
            </Box>
          </Box>
          <Box flexDirection="row">
            <Link color="TEXT_DARK" externalURL={aboutCreditScoreUrl} size="xs" weight="regular">
              About our Credit Score&nbsp;
            </Link>
            <ExternalLinkIcon color={Colors.TEXT_DARK} width="16px" />
          </Box>
        </Box>
      </TextContainer>
    </CardContainer>
  )
}

const allEqualValues = (arr: DataPoint[]) => arr.every((dp) => dp.score === arr[0].score)
