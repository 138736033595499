import { Text } from 'components'
import CSS from 'csstype'
import { useState } from 'react'
import styled from 'styled-components'
import { getIconUrlFromGCP } from 'utils'

type Source = 'ethereum' | 'polygon' | 'avalanche' | 'snapshot'

export const SourceIconContainer = styled.img`
  width: 28px;
`

const HoverMessageContainer = styled.div`
  position: absolute;
  left: 15px;
  bottom: 40px;
  border-radius: 10px;
  padding: 10px;
  background-color: #2d2a37;
  color: #fff;
  white-space: nowrap;
`

interface ISocialButton {
  source: string
  style?: CSS.Properties
}

export const SourceIcon = ({ source, style }: ISocialButton) => {
  const [hover, setHover] = useState(false)
  return (
    <div
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      style={{ position: 'relative' }}
    >
      <SourceIconContainer style={style} src={getIconUrlFromGCP(source as Source)} />
      {hover && (
        <HoverMessageContainer>
          <Text weight="medium" size="s">
            {source.charAt(0).toUpperCase() + source.slice(1)}
          </Text>
        </HoverMessageContainer>
      )}
    </div>
  )
}
