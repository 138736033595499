import { Link as LinkRR } from 'react-router-dom'
import styled from 'styled-components'
import { Colors } from '../../constants/colors'
import { combinedCss } from './styles'
import { TextInterface, TextProps } from './types'

const StyledLink = styled.a<TextInterface>`
  ${(p) => combinedCss(p)}
  text-decoration: none;
  &:hover {
    cursor: pointer;
    color: ${Colors.WHITE};
  }
`

const StyledLinkText = styled.div<TextInterface>`
  ${(p) => combinedCss(p)}
  text-decoration: none;
  width: 100%;
  height: 100%;
`

interface LinkProps extends TextProps {
  to?: string
  externalURL?: string
  onClick?: () => void
}

export const Link = ({ children, to, externalURL, onClick, ...props }: LinkProps) => {
  if (!externalURL && !to) {
    throw Error('externalURL or to prop needed.')
  }

  if (externalURL) {
    return (
      <StyledLink onClick={onClick} href={externalURL} target="_blank" {...props}>
        {children}
      </StyledLink>
    )
  }
  return (
    <LinkRR onClick={onClick} style={{ textDecoration: 'none' }} to={{ pathname: to }}>
      <StyledLinkText {...props}>{children}</StyledLinkText>
    </LinkRR>
  )
}
