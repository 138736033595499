import goerliConfig from './config.goerli'
import mainnetConfig from './config.mainnet'
import mumbaiConfig from './config.mumbai'
import polygonConfig from './config.polygon'

export function getConfig(chainId: number) {
  switch (chainId) {
    case 1:
      return mainnetConfig
    case 5:
      return goerliConfig
    case 137:
      return polygonConfig
    case 80001:
      return mumbaiConfig
    default:
      return mainnetConfig
  }
}

export const rpcUrls = {
  1: mainnetConfig.rpcUrl,
  5: goerliConfig.rpcUrl,
  137: polygonConfig.rpcUrl,
  80001: mumbaiConfig.rpcUrl,
}
