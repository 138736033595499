import {
  BaseERC20Factory,
  SapphirePool,
  SapphirePoolFactory,
} from '@arcxgame/contracts/src/typings'
import { BigNumber, providers, Signer } from 'ethers'
import { AssetInfo } from 'types'
import { ethMulticaller } from 'utils'
import { CapsuleRefreshEmitter } from './CapsuleRefreshEmitter'

type PoolAssetInfo = AssetInfo & {
  /**
   * Amount of tokens currently in the pool
   */
  balance: BigNumber
}

export class PoolCapsule extends CapsuleRefreshEmitter {
  /* -------------------------------------------------------------------------- */
  /*                                 Properties                                 */
  /* -------------------------------------------------------------------------- */

  /* --------------------------- Protected properties --------------------------- */
  protected _pool: SapphirePool

  /* ---------------------------- Dynamic properties --------------------------- */
  totalSupply!: BigNumber
  fundsBorrowed!: BigNumber
  fundsAvailable!: BigNumber
  /**
   * Amount of tokens currently in the pool
   */
  supportedDepositAssets!: PoolAssetInfo[]

  /* -------------------------- Calculated properties ------------------------- */
  get poolValue(): BigNumber {
    return this.fundsAvailable.add(this.fundsBorrowed)
  }

  /* -------------------------------------------------------------------------- */
  /*                                   Methods                                  */
  /* -------------------------------------------------------------------------- */

  /* ------------------------------- Constructor ------------------------------ */

  constructor(
    public readonly contractAddress: string,
    public readonly provider: providers.JsonRpcProvider | Signer,
  ) {
    super(contractAddress, provider)

    this._pool = SapphirePoolFactory.connect(contractAddress, provider)
  }

  /* ----------------------------- Public methods ----------------------------- */

  // Override method, but eslint pre-commit doesn't like it
  async initializeState() {
    await this._refresh()
  }

  /* ----------------------- Protected / private methods ---------------------- */

  protected async _refresh() {
    // It is possible the provider changed because of a wallet reconnection. In that case,
    // make sure the contract uses the updated provider/signer
    if (this.provider !== this._pool.provider) {
      this._pool = SapphirePoolFactory.connect(this.contractAddress, this.provider)
    }

    await Promise.all([this._refreshState(), this._refreshDepositAssets()])
  }

  private async _refreshState() {
    type ReturnTypes = [BigNumber, BigNumber, BigNumber, string[]]

    const [totalSupply, totalFunds, fundsBorrowed] = await ethMulticaller<ReturnTypes>(this._pool, [
      'totalSupply',
      'getPoolValue',
      'stablesLent',
    ])

    const fundsAvailable = totalFunds.sub(fundsBorrowed)

    this.totalSupply = totalSupply
    this.fundsBorrowed = fundsBorrowed
    this.fundsAvailable = fundsAvailable
  }

  private async _refreshDepositAssets() {
    const supportedAssets = await this._pool.getDepositAssets()

    this.supportedDepositAssets = await Promise.all<PoolAssetInfo>(
      supportedAssets.map(async (assetAddress) => {
        const erc20 = BaseERC20Factory.connect(assetAddress, this.provider)

        const [decimals, symbol, balance] = await ethMulticaller<[number, string, BigNumber]>(
          erc20,
          ['decimals', 'symbol', ['balanceOf', [this._pool.address]]],
        )

        return {
          address: assetAddress,
          decimals,
          symbol,
          balance,
        }
      }),
    )
  }
}
