import { Area, AreaChart, ResponsiveContainer, XAxis, YAxis } from 'recharts'
import { DataPoint } from './DataPoint'

interface ICreditScoreChartProps {
  data: DataPoint[]
  maxScore: number
}

export const CreditScoreChart = ({ data, maxScore }: ICreditScoreChartProps) => {
  return (
    <ResponsiveContainer>
      <AreaChart data={data} margin={{ top: 1, right: -1, bottom: 1, left: -1 }}>
        <defs>
          <linearGradient id="blueRedGradient">
            <stop offset="0%" stopColor="#51B6F9" />
            <stop offset="100%" stopColor="#D94CD6" />
          </linearGradient>
          <linearGradient id="areaGradient">
            <stop offset="0%" stopColor="#51B6F9" stopOpacity={0.25} />
            <stop offset="100%" stopColor="#D94CD6" stopOpacity={0.25} />
          </linearGradient>
        </defs>
        <XAxis
          dataKey="timestamp"
          domain={[data[0].timestamp, data[data.length - 1].timestamp]}
          scale="time"
          type="number"
          hide
        />
        <YAxis dataKey="score" domain={[0, maxScore]} hide />
        <Area
          type="basis"
          dataKey="score"
          stroke="url(#blueRedGradient)"
          strokeWidth="2px"
          fillOpacity={1}
          fill="url(#areaGradient)"
        />
      </AreaChart>
    </ResponsiveContainer>
  )
}
