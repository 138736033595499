import { Button, ConnectInterface } from 'components'
import { useModal } from 'contexts'
import { useResponsive } from 'hooks'

export const ConnectButton = () => {
  const { openWith } = useModal()
  const { width } = useResponsive()
  const onConnectClick = () => openWith(<ConnectInterface />)

  return (
    <Button
      variant="fancy"
      onClick={onConnectClick}
      text={width < 400 ? 'Connect' : 'Connect wallet'}
    />
  )
}
