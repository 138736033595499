import { BigNumber, utils } from 'ethers'
import { useEffect, useState } from 'react'
import styled from 'styled-components'
import { getFontSize, getSpacing } from 'utils'
import { Box, SkeletonCircle, SkeletonRectangle, Text } from '..'
import { Colors } from '../../constants'

const DECIMAL_REGEX = /^[+-]?([0-9]+\.?[0-9]*|\.[0-9]+)$/

const Input = styled.input`
  background-color: transparent;
  font-size: ${getFontSize('s')};
  width: 100%;
  font-family: Matter;
  border: 0;
  color: #ffffff;
  text-align: right;

  &:focus {
    outline: none;
  }

  &::placeholder {
    color: ${Colors.TEXT_DARK};
  }
`

const InputContainer = styled.div`
  background-color: transparent;
  border: 1px solid #ffffff20;
  padding: 12px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border-radius: 13px;
`

const MaxButton = styled.button`
  height: 100%;
  background-color: rgba(255, 255, 255, 0.1);
  border: none;
  padding: ${getSpacing('xs')};
  margin-left: ${getSpacing('s')};
  border-radius: ${getSpacing('xs')};

  &:hover {
    cursor: pointer;
  }
`

interface INumberInput {
  value?: BigNumber | undefined
  maxValue?: BigNumber
  placeholder?: string
  onChange?: (value: BigNumber | undefined) => void
  tokenSymbol?: string
  tokenIconUrl?: string
  loading?: boolean
  disabled?: boolean
  decimals?: number
}

const NumberInputSkeleton = () => (
  <InputContainer>
    <Box width="30px" height="30px" borderRadius="15px" marginRight="10px">
      <SkeletonCircle style={{ width: '30px', height: '30px', borderRadius: '15px' }} />
    </Box>
    <SkeletonRectangle width="50px" />
    <Input placeholder="0.0" type="number" />
  </InputContainer>
)

export const NumberInput = ({
  value,
  maxValue,
  onChange,
  tokenSymbol,
  tokenIconUrl,
  placeholder,
  loading,
  disabled,
  decimals,
}: INumberInput) => {
  const [shownValue, setShownValue] = useState<string>('')

  useEffect(() => {
    if (disabled || loading || (!value && !shownValue)) return
    if (!value) {
      setShownValue('')
    } else if (
      !shownValue ||
      !utils.parseUnits(truncateDecimals(shownValue, decimals), decimals).eq(value)
    ) {
      setShownValue(utils.formatUnits(value, decimals))
    }
  }, [value, decimals, shownValue, disabled, loading])

  if (loading) return <NumberInputSkeleton />
  return (
    <InputContainer>
      {tokenIconUrl && <img src={tokenIconUrl} width={30} style={{ marginRight: '10px' }} />}
      {tokenSymbol && (
        <Text style={{ paddingRight: '8px' }} color="TEXT_DARK" weight="medium" size="s">
          {tokenSymbol}
        </Text>
      )}
      <Input
        disabled={disabled || loading}
        onPaste={(e) => {
          if (!e.clipboardData.getData('Text').match(DECIMAL_REGEX)) {
            e.preventDefault()
          }
        }}
        onKeyPress={(e) => {
          if (!(shownValue + e.key).match(DECIMAL_REGEX)) e.preventDefault()
        }}
        placeholder={placeholder}
        value={shownValue}
        onChange={(e) => {
          const { value } = e.currentTarget

          if (value === '') {
            onChange && onChange(undefined)
            setShownValue(value)
            return
          }
          let newValue: BigNumber
          try {
            const formattedShownValue = truncateDecimals(value, decimals)
            newValue = utils.parseUnits(formattedShownValue, decimals)
          } catch (e) {
            // don't update the input on invalid values
            return
          }
          onChange && onChange(newValue)
          setShownValue(value)
        }}
        type="text"
      />
      {maxValue && (
        <MaxButton onClick={() => onChange && onChange(maxValue)}>
          <Text size="xs" weight="regular" color="WHITE">
            Max
          </Text>
        </MaxButton>
      )}
    </InputContainer>
  )
}

function truncateDecimals(value: string, decimals?: number) {
  const splittedValue = value.split('.')

  if (splittedValue.length > 1) {
    const wholePart = splittedValue[0]
    const decimalPart = splittedValue[1]

    return wholePart + '.' + decimalPart.substring(0, decimals)
  }

  return value
}
