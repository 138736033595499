import { TrendingUpIcon } from '@heroicons/react/solid'
import { Box, Text } from 'components'
import { Colors } from 'constants/colors'
import { ScoreExplanation } from 'types'

interface IExplanationsList {
  explanations: ScoreExplanation[]
}

export const ExplanationsList = ({ explanations }: IExplanationsList) => (
  <Box
    borderRadius="16px"
    style={{
      height: '200px',
      backgroundColor: Colors.PURPLE_10,
    }}
  >
    <ExplanationListHeader />
    <Box
      style={{
        overflowX: 'hidden',
        overflowY: 'auto',
      }}
    >
      {explanations
        ?.sort((a, b) => Number(b.impact) - Number(a.impact))
        .map((e, i) => (
          <ExplanationListItem
            key={`suggestion_account_${i}`}
            text={e.explanation}
            value={e.impact}
            suggestion={e.suggestion}
          />
        ))}
    </Box>
  </Box>
)

const ExplanationListHeader = () => (
  <Box
    flexDirection="row"
    justifyContent="space-between"
    style={{ borderBottom: `1px solid ${Colors.WHITE_10}` }}
    padding="8px 16px"
  >
    <Text style={{ color: Colors.WHITE_30 }} size="xs">
      Description
    </Text>
    <Text style={{ color: Colors.WHITE_30 }} size="xs">
      Score Impact
    </Text>
  </Box>
)

const ExplanationListItem = ({
  text,
  value,
  suggestion,
}: {
  text?: string
  value?: string
  suggestion?: string
}) => (
  <Box
    flexDirection="row"
    justifyContent="space-between"
    style={{ borderBottom: `1px solid ${Colors.WHITE_10}` }}
    padding="8px 16px"
  >
    <Box flex="4">
      <Text size="s">{text}</Text>
      {suggestion && (
        <Box marginTop="4px" flexDirection="row" alignItems="center">
          <TrendingUpIcon style={{ marginRight: '4px', width: '18px', color: Colors.WHITE_50 }} />
          <Text size="s" color="WHITE_60">
            {suggestion}
          </Text>
        </Box>
      )}
    </Box>
    <Text style={{ color: Colors.GREEN_5 }} size="s">
      {value}
    </Text>
  </Box>
)
