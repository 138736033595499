/* eslint-disable @typescript-eslint/indent */
import { ErrorBoundary } from '@rollbar/react'
import { AppFooter } from 'components/AppFooter'
import { AppNavigation } from 'components/AppNavigation'
import { ErrorBox } from 'components/ErrorBox'
import { ReactNode } from 'react'

type IProps = {
  children: ReactNode
}

export const PageLayout = ({ children }: IProps) => {
  return (
    <div className="flex flex-col min-h-screen">
      <AppNavigation />
      <div className="container flex-grow max-w-screen-lg mx-auto">
        <ErrorBoundary fallbackUI={() => <ErrorBox />}>
          <div className="mx-auto">{children}</div>
        </ErrorBoundary>
      </div>
      <AppFooter />
    </div>
  )
}
