import { Box, Text } from 'components'
import { ColorType } from 'constants/colors'
import styled from 'styled-components'
import { getFontSize } from 'utils'
import { TextSize, TextWeight } from '../Typography/types'

interface ICheckboxInput {
  checked: boolean
  label: string
  onChange?: () => void
  labelColor?: ColorType
  labelSize?: TextSize
  labelWeight?: TextWeight
}

export const Checkbox = ({
  checked,
  label,
  onChange,
  labelColor = 'WHITE',
  labelSize = 'm',
  labelWeight = 'thin',
}: ICheckboxInput) => (
  <Box>
    <label>
      <CheckboxContainer>
        <HiddenCheckbox checked={checked} onChange={onChange} />
        <StyledCheckbox checked={checked} label={label} labelSize={labelSize}>
          <Icon viewBox="0 0 24 24" checked={checked}>
            <polyline points="20 6 9 17 4 12" />
          </Icon>
        </StyledCheckbox>
      </CheckboxContainer>
      <Text size={labelSize} weight={labelWeight} color={labelColor}>
        {label}
      </Text>
    </label>
  </Box>
)

const CheckboxContainer = styled.div`
  display: inline-block;
  vertical-align: middle;
`

const Icon = styled.svg<{ checked: boolean }>`
  fill: none;
  stroke: ${({ checked }) => (checked ? '#fff' : '#D9D9D9')};
  stroke-width: 4px;
`

const StyledCheckbox = styled.div<ICheckboxInput>`
  display: inline-block;
  width: ${(props) => getFontSize(props.labelSize)};
  height: ${(props) => getFontSize(props.labelSize)};
  border-radius: 4px;
  background: 'transparent';
  margin-right: ${(props) => getFontSize(props.labelSize)};
  box-shadow: ${(props) => (props.checked ? '0 0 0 4px #fff ' : '0 0 0 4px #D9D9D9')};

  ${Icon} {
    visibility: ${(props) => (props.checked ? 'visible' : 'hidden')};
  }
`

// Hide checkbox visually but remain accessible to screen readers.
// Source: https://polished.js.org/docs/#hidevisually
const HiddenCheckbox = styled.input.attrs({ type: 'checkbox' })`
  border: 0;
  clip: rect(0 0 0 0);
  clippath: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`
