type TEXT = 'TEXT_DARK' | 'TEXT_LIGHT'

type BLUE =
  | 'BLUE_1'
  | 'BLUE_2'
  | 'BLUE_3'
  | 'BLUE_4'
  | 'BLUE_5'
  | 'BLUE_6'
  | 'BLUE_7'
  | 'BLUE_8'
  | 'BLUE_9'
  | 'BLUE_10'

type PURPLE =
  | 'PURPLE_1'
  | 'PURPLE_2'
  | 'PURPLE_3'
  | 'PURPLE_4'
  | 'PURPLE_5'
  | 'PURPLE_6'
  | 'PURPLE_7'
  | 'PURPLE_8'
  | 'PURPLE_9'
  | 'PURPLE_10'

type PINK =
  | 'PINK_1'
  | 'PINK_2'
  | 'PINK_3'
  | 'PINK_4'
  | 'PINK_5'
  | 'PINK_6'
  | 'PINK_7'
  | 'PINK_8'
  | 'PINK_9'
  | 'PINK_10'

type RED =
  | 'RED_1'
  | 'RED_2'
  | 'RED_3'
  | 'RED_4'
  | 'RED_5'
  | 'RED_6'
  | 'RED_7'
  | 'RED_8'
  | 'RED_9'
  | 'RED_10'

type GREEN =
  | 'GREEN_1'
  | 'GREEN_2'
  | 'GREEN_3'
  | 'GREEN_4'
  | 'GREEN_5'
  | 'GREEN_6'
  | 'GREEN_7'
  | 'GREEN_8'
  | 'GREEN_9'
  | 'GREEN_10'

type GRAY =
  | 'GRAY_1'
  | 'GRAY_2'
  | 'GRAY_3'
  | 'GRAY_4'
  | 'GRAY_5'
  | 'GRAY_6'
  | 'GRAY_7'
  | 'GRAY_8'
  | 'GRAY_9'
  | 'GRAY_10'

type TRANSLUCENT =
  | 'WHITE_95'
  | 'WHITE_90'
  | 'WHITE_80'
  | 'WHITE_70'
  | 'WHITE_60'
  | 'WHITE_50'
  | 'WHITE_40'
  | 'WHITE_30'
  | 'WHITE_25'
  | 'WHITE_20'
  | 'WHITE_15'
  | 'WHITE_10'
  | 'WHITE_05'

type WHITE = 'WHITE'

type BLACK = 'BLACK'

type YELLOW = 'YELLOW'

type TRANSPARENT = 'TRANSPARENT'

type BG_ELEVATED = 'BG_ELEVATED'

const BlueColors: { [key in BLUE]: string } = {
  BLUE_1: '#E9F6FE',
  BLUE_2: '#BCE3FD',
  BLUE_3: '#8FD0FB',
  BLUE_4: '#62BDFA',
  BLUE_5: '#35AAF8',
  BLUE_6: '#077CCA',
  BLUE_7: '#05609D',
  BLUE_8: '#044570',
  BLUE_9: '#022943',
  BLUE_10: '#010E16',
}

const PurpleColors: { [key in PURPLE]: string } = {
  PURPLE_1: '#D7D4E4',
  PURPLE_2: '#BDB8D2',
  PURPLE_3: '#A29CC0',
  PURPLE_4: '#7A57DD',
  PURPLE_5: '#6D639C',
  PURPLE_6: '#5A5180',
  PURPLE_7: '#463F63',
  PURPLE_8: '#322D47',
  PURPLE_9: '#1E1B2B',
  PURPLE_10: '#0A090E',
}

const PinkColors: { [key in PINK]: string } = {
  PINK_1: '#FBECFB',
  PINK_2: '#F3C6F2',
  PINK_3: '#EB9FE9',
  PINK_4: '#E379E0',
  PINK_5: '#DA53D8',
  PINK_6: '#D22DCF',
  PINK_7: '#AC25A9',
  PINK_8: '#861C84',
  PINK_9: '#60145E',
  PINK_10: '#390C38',
}

const RedColors: { [key in RED]: string } = {
  RED_1: '#FBECEC',
  RED_2: '#F3C5C5',
  RED_3: '#EB9F9F',
  RED_4: '#E37979',
  RED_5: '#DB5252',
  RED_6: '#D32C2C',
  RED_7: '#AD2424',
  RED_8: '#861C1C',
  RED_9: '#601414',
  RED_10: '#3A0C0C',
}

const GreenColors: { [key in GREEN]: string } = {
  GREEN_1: '#EBFCF4',
  GREEN_2: '#C2F7DD',
  GREEN_3: '#99F1C7',
  GREEN_4: '#70EBB0',
  GREEN_5: '#47E699',
  GREEN_6: '#1FE083',
  GREEN_7: '#19B76B',
  GREEN_8: '#148F53',
  GREEN_9: '#0E663B',
  GREEN_10: '#083D24',
}

const GrayColors: { [key in GRAY]: string } = {
  GRAY_1: '#F2F1F3',
  GRAY_2: '#D8D6DB',
  GRAY_3: '#BDBBC3',
  GRAY_4: '#A3A0AB',
  GRAY_5: '#898593',
  GRAY_6: '#6F6C7A',
  GRAY_7: '#57545F',
  GRAY_8: '#3E3C44',
  GRAY_9: '#252429',
  GRAY_10: '#0C0C0E',
}

const TranslucentColors: { [key in TRANSLUCENT]: string } = {
  WHITE_05: '#ffffff05',
  WHITE_10: '#ffffff10',
  WHITE_15: '#ffffff15',
  WHITE_20: '#ffffff20',
  WHITE_25: '#ffffff25',
  WHITE_30: '#ffffff30',
  WHITE_40: '#ffffff40',
  WHITE_50: '#ffffff50',
  WHITE_60: '#ffffff60',
  WHITE_70: '#ffffff70',
  WHITE_80: '#ffffff80',
  WHITE_90: '#ffffff90',
  WHITE_95: '#ffffff95',
}

const TextColors: { [key in TEXT]: string } = {
  TEXT_DARK: '#9895A1',
  TEXT_LIGHT: '#FFFFFF',
}

export type ColorType =
  | BLUE
  | PINK
  | PURPLE
  | RED
  | GREEN
  | YELLOW
  | GRAY
  | TRANSLUCENT
  | WHITE
  | BLACK
  | TRANSPARENT
  | BG_ELEVATED
  | TEXT

type ColorsType = {
  [key in ColorType]: string
}

export const Colors: ColorsType = {
  ...BlueColors,
  ...PurpleColors,
  ...PinkColors,
  ...RedColors,
  ...GreenColors,
  ...GrayColors,
  ...TranslucentColors,
  ...TextColors,
  YELLOW: '#F5C05A',
  WHITE: '#ffffff',
  BLACK: '#000000',
  BG_ELEVATED: '#2D2A37',
  TRANSPARENT: 'transparent',
}

export type GradientType = 'PINK_BLUE' | 'BLUE_PURPLE'

type GradientsType = {
  [key in GradientType]: string
}

export const Gradients: GradientsType = {
  PINK_BLUE: 'linear-gradient(108.2deg, #D94CD6 12.66%, #51B6F9 121.19%)',
  BLUE_PURPLE: 'linear-gradient(159.51deg, #312642 -21.15%, #221538 86.4%)',
}
