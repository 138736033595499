import CSS from 'csstype'
import React from 'react'
import { Colors, ColorType } from '../../constants'

interface BoxProps {
  margin?: CSS.Property.Margin
  marginBottom?: CSS.Property.MarginBottom
  marginTop?: CSS.Property.MarginTop
  marginLeft?: CSS.Property.MarginLeft
  marginRight?: CSS.Property.MarginRight
  padding?: CSS.Property.Padding
  paddingBottom?: CSS.Property.PaddingBottom
  paddingTop?: CSS.Property.PaddingTop
  paddingLeft?: CSS.Property.PaddingLeft
  paddingRight?: CSS.Property.PaddingRight
  display?: CSS.Property.Display
  flex?: CSS.Property.Flex
  flexBasis?: CSS.Property.FlexBasis
  flexDirection?: CSS.Property.FlexDirection
  flexGrow?: CSS.Property.FlexGrow
  flexShrink?: CSS.Property.FlexShrink
  flexWrap?: CSS.Property.FlexWrap
  gap?: CSS.Property.Gap
  justifyContent?: CSS.Property.JustifyContent
  alignItems?: CSS.Property.AlignItems
  alignContent?: CSS.Property.AlignContent
  borderRadius?: CSS.Property.BorderRadius
  backgroundColor?: ColorType
  textAlign?: CSS.Property.TextAlign
  onClick?: () => void
  onMouseEnter?: () => void
  onMouseLeave?: () => void
  width?: CSS.Property.Width
  height?: CSS.Property.Height
  style?: CSS.Properties
  dataCy?: string
}

export const Box: React.FC<BoxProps> = (props) => {
  const {
    display = 'flex',
    flexDirection = 'column',
    backgroundColor,
    style,
    onClick,
    onMouseEnter,
    onMouseLeave,
    children,
  } = props
  return (
    <div
      onClick={onClick}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      data-cy={props.dataCy}
      style={{
        display,
        flexDirection,
        ...style,
        ...props,
        ...(backgroundColor ? { backgroundColor: Colors[backgroundColor] } : {}),
      }}
    >
      {children}
    </div>
  )
}
