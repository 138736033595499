import { BigNumber, utils } from 'ethers'

export const configConstants = {
  creditProtocol: 'arcx.credit',
  maxCreditScore: 999,
  atRiskThreshold: utils.parseEther('0.9'),
}

export const SECONDS_IN_YEAR = BigNumber.from(365 * 24 * 60 * 60)

export const nowInSeconds = () => Math.floor(Date.now() / 1000)
