import { useState } from 'react'
import styled from 'styled-components'
import { Spinner } from '../Spinner'

const ArcPassportImage = styled.img<{ size: number; loaded: boolean }>`
  width: ${(p) => (p.size ? p.size : '300')}px;
  ${(p) => (!p.loaded ? 'display: none;' : '')}
`

const Placeholder = styled.div<{ size: number }>`
  width: ${(p) => p.size || 300}px;
  height: ${(p) => (p.size ? p.size * 1.4666 : 300 * 1.466)}px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

interface IArcPassportCardProps {
  src?: string
  size?: number
}

export const ArcPassportCard = ({
  src = 'https://stage.api.arcx.money/skins/image/arcx/purple?glow=false',
  size = 175,
}: IArcPassportCardProps) => {
  const [loaded, setLoaded] = useState<boolean>(false)

  const onLoad = () => {
    setLoaded(true)
  }

  return (
    <>
      {!loaded && (
        <Placeholder size={size}>
          <Spinner />
        </Placeholder>
      )}
      <ArcPassportImage loaded={loaded} size={size} src={src} onLoad={onLoad} />
    </>
  )
}
