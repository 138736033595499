/* eslint-disable @typescript-eslint/indent */
import { SparklesIcon } from '@heroicons/react/solid'
import { SocialButton } from 'components'
import { Colors } from 'constants/colors'
import { useAnimationMode } from 'hooks'
import { useState } from 'react'

export const AnimationToggle = () => {
  const { showAnimation, changeAnimationMode } = useAnimationMode()
  const [hover, setHover] = useState(false)
  const color = hover ? `${Colors.BLACK}90` : showAnimation ? Colors.WHITE : Colors.PURPLE_3
  return (
    <SocialButton
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      onClick={changeAnimationMode}
      tooltip="Toggle Animation"
    >
      <SparklesIcon
        width={23}
        style={{
          cursor: 'pointer',
          fill: color,
        }}
      />
    </SocialButton>
  )
}
