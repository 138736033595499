export function getBlockExplorersBaseLink(network?: string | number) {
  if (!network || network === 1) {
    return 'https://etherscan.io'
  } else if (network === 5) return 'https://goerli.etherscan.io'
  else if (network === 137) return 'https://polygonscan.com'
  else if (network === 80001) return 'https://mumbai.polygonscan.com'
  else throw new Error(`Network ${network} is not supported`)
}

export function getBlockExplorerTxLink(network: string | number, txnHash: string) {
  return `${getBlockExplorersBaseLink(network)}/tx/${txnHash}`
}

export function getBlockExplorerAddressLink(network: string | number, address: string) {
  return `${getBlockExplorersBaseLink(network)}/address/${address}`
}
