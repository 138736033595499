import { ExternalLinkIcon } from '@heroicons/react/solid'
import { Box } from 'components'
import { Colors } from 'constants/colors'
import { useState } from 'react'
import styled from 'styled-components'

const LearnMoreButton = styled.a`
  font-size: 15px;
  color: ${Colors.WHITE};
  background-color: ${Colors.WHITE_15};
  padding: 13px 16px;
  border-radius: 10px;
  text-decoration: none;
  cursor: pointer;
  transition: 0.25s;
  &:hover {
    background-color: ${Colors.WHITE};
    color: ${Colors.PURPLE_10};
  }
`

export const LearnMore = ({ externalUri }: { externalUri: string }) => {
  const [hover, setHover] = useState(false)
  return (
    <LearnMoreButton
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      href={externalUri}
      target="_blank"
      rel="noreferrer"
    >
      <Box flexDirection="row" alignItems="center" justifyContent="center">
        <span style={{ marginRight: '4px' }}>Learn More</span>
        <ExternalLinkIcon width="15px" color={hover ? Colors.PURPLE_9 : '#ffffff'} />
      </Box>
    </LearnMoreButton>
  )
}
