import { useWeb3React } from '@web3-react/core'
import { usePassport } from 'hooks'
import { createContext, FC, useContext, useEffect, useState } from 'react'
import { useAnalytics } from '../contexts/AnalyticsContext'

interface IAnimationModeContext {
  showAnimation: boolean
  changeAnimationMode: () => void
}

const AnimationModeContext = createContext<IAnimationModeContext>({
  showAnimation: true,
  changeAnimationMode: () => {},
})

export const AnimationModeProvider: FC = ({ children }) => {
  const [showAnimation, setMode] = useState<boolean>(true)
  const { account } = useWeb3React()
  const { passportMetadata: passport } = usePassport(account)
  const { track } = useAnalytics()

  const changeAnimationMode = () => {
    setMode((isAnimated) => {
      const analyticsData = {
        SkinAddress: passport?.activeSkin ? passport.activeSkin.contract : null,
        SkinId: passport?.activeSkin ? passport.activeSkin.tokenIds[0] : null,
      }
      const trackData = {
        ...analyticsData,
        WalletStatus: account ? 'Connected' : 'Disconnected',
        WalletAddress: account || null,
      }
      if (isAnimated) {
        localStorage.setItem('hide_animation', 'true')
        track('AnimationToggleHide', trackData)
      } else {
        localStorage.removeItem('hide_animation')
        track('AnimationToggleShow', trackData)
      }
      return !isAnimated
    })
  }

  useEffect(() => {
    if (localStorage.getItem('hide_animation')) {
      setMode(false)
    }
  }, [])

  return (
    <AnimationModeContext.Provider value={{ showAnimation, changeAnimationMode }}>
      {children}
    </AnimationModeContext.Provider>
  )
}

export const useAnimationMode = () => useContext(AnimationModeContext)
