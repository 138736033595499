import { useWeb3React } from '@web3-react/core'
import { NetworkConnector } from '@web3-react/network-connector'
import { rpcUrls } from 'config'
import { createContext, ReactNode, useContext, useState } from 'react'
import { isMetamaskInstalled } from 'utils'
import { useAsync, useConnect } from '../../hooks'

interface IProps {
  children: ReactNode
}

interface IAutoConnectContext {
  isAutoConnecting: boolean
}

const AutoConnectContext = createContext<IAutoConnectContext>({
  isAutoConnecting: true,
})

export const AutoConnect = ({ children }: IProps) => {
  const { activate, active } = useWeb3React()
  const { connectors } = useConnect()
  const storedConnectorName = localStorage.getItem('connector')
  const [isAutoConnecting, setIsAutoConnecting] = useState(true)

  const activateNetworkConnector = () => {
    return activate(
      new NetworkConnector({
        urls: rpcUrls,
        defaultChainId: 137,
      }),
    )
  }

  useAsync(async () => {
    if (!active) {
      if (!storedConnectorName || (storedConnectorName === 'Metamask' && !isMetamaskInstalled())) {
        await activateNetworkConnector()
        setIsAutoConnecting(false)
      } else {
        const connectorInfo = connectors.find(({ name }) => name === storedConnectorName)
        if (!connectorInfo) {
          throw new Error('No stored connector.')
        }
        try {
          await activate(connectorInfo.connector)
        } catch {
          await activateNetworkConnector()
        } finally {
          setIsAutoConnecting(false)
        }
      }
    }
  }, [active])

  return (
    <AutoConnectContext.Provider value={{ isAutoConnecting }}>
      {children}
    </AutoConnectContext.Provider>
  )
}

export const useAutoConnectStatus = () => {
  return useContext(AutoConnectContext)
}
