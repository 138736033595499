import { asArray, asObject, asString } from '@restless/sanitizers'
import { SimpleScore } from './simpleScore'

export type ScoreWithProof = SimpleScore & { merkleProof: string[] }

export const asScoreWithProof = asObject<ScoreWithProof>({
  account: asString,
  protocol: asString,
  score: asString,
  merkleProof: asArray(asString),
})
