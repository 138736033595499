import { ReactNode, useState } from 'react'
import styled from 'styled-components'
import { Text } from '..'
import { Colors } from '../../constants'

export const SocialButtonContainer = styled.button`
  padding: 10px 12px;
  background-color: ${Colors.WHITE_15};
  border-radius: 10px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border: solid 3px transparent;
  text-decoration: none;
  transition: 0.25s;
  &:hover {
    background-color: white;
    color: ${Colors.PURPLE_10};
  }
`

const HoverMessageContainer = styled.div`
  position: absolute;
  right: 25px;
  top: 55px;
  border-radius: 10px;
  padding: 10px;
  background-color: #2d2a37;
  color: #fff;
  white-space: nowrap;
`

interface ISocialButton {
  children: ReactNode
  onClick?: () => void
  onMouseEnter?: () => void
  onMouseLeave?: () => void
  tooltip?: string
}

export const SocialButton = ({
  children,
  onClick,
  onMouseEnter,
  onMouseLeave,
  tooltip,
}: ISocialButton) => {
  const [hover, setHover] = useState(false)
  return (
    <div style={{ position: 'relative' }}>
      <SocialButtonContainer
        onClick={onClick}
        onMouseEnter={() => {
          setHover(true)
          onMouseEnter && onMouseEnter()
        }}
        onMouseLeave={() => {
          setHover(false)
          onMouseLeave && onMouseLeave()
        }}
      >
        {children}
      </SocialButtonContainer>
      {hover && tooltip && (
        <HoverMessageContainer>
          <Text weight="medium" size="s">
            {tooltip}
          </Text>
        </HoverMessageContainer>
      )}
    </div>
  )
}
