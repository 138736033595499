import { useDebugMode } from 'hooks'
import { useEffect, useState } from 'react'

export const useEasterEgg = () => {
  const [code, setCode] = useState('')

  const { changeMode } = useDebugMode()

  useEffect(() => {
    if (code === 'arcx') {
      changeMode()
    }
    const timeout = setTimeout(() => setCode(''), 1000)
    return () => clearTimeout(timeout)
    // eslint-disable-next-line
  }, [code])

  const handleClick = (event) => {
    if (['a', 'r', 'c', 'x'].includes(event.key)) {
      setCode((prev) => prev + event.key)
    }
  }

  useEffect(() => {
    document.addEventListener('keydown', handleClick, false)
    return () => document.removeEventListener('keydown', handleClick, false)
  }, [])
}
