import styled from 'styled-components'
import { ColoredBar } from './ColoredBar'
import { IGrowthSteps } from './types'

export interface ICurrentGrowthBarProps {
  currentGrowthPercentage: number
  orderedSteps: IGrowthSteps[]
}

interface ICurrentBarContainerProps {
  currentGrowthPercentage: number
}

const CurrentBarContainer = styled.div<ICurrentBarContainerProps>`
  height: 100%;
  clip-path: inset(0% ${(p) => 100 - p.currentGrowthPercentage}% 0% 0% round 8px);
`

const ShadowWrap = styled.div`
  position: absolute;
  top: 0px;
  right: 0px;
  left: 0px;
  bottom: 0px;
  filter: drop-shadow(4px 0px 5px rgba(0, 0, 0, 0.1));
`

export const CurrentGrowthBar = ({
  currentGrowthPercentage,
  orderedSteps,
}: ICurrentGrowthBarProps) => {
  return (
    <ShadowWrap>
      <CurrentBarContainer currentGrowthPercentage={currentGrowthPercentage}>
        <ColoredBar orderedSteps={orderedSteps} />
      </CurrentBarContainer>
    </ShadowWrap>
  )
}
