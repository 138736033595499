import { AbstractConnector } from '@web3-react/abstract-connector'
import { useWeb3React } from '@web3-react/core'
import { InjectedConnector } from '@web3-react/injected-connector'
import { WalletConnectConnector } from '@web3-react/walletconnect-connector'
import { MetamaskIconAsset, WalletConnectIconAsset } from 'assets'
import { rpcUrls } from 'config'
import { useAnalytics } from '../contexts/AnalyticsContext'

export const injected = new InjectedConnector({
  supportedChainIds: [1, 5, 137, 80001],
})

type ConnectorName = 'Metamask' | 'WalletConnect'

export const walletconnect = new WalletConnectConnector({
  rpc: rpcUrls,
  qrcode: true,
})

export const connectors: Connector[] = [
  {
    name: 'Metamask',
    icon: MetamaskIconAsset,
    connector: injected,
  },
  {
    name: 'WalletConnect',
    icon: WalletConnectIconAsset,
    connector: walletconnect,
  },
]

interface Connector {
  name: ConnectorName
  icon: string
  connector: AbstractConnector
}

export function useConnect() {
  const {
    chainId,
    active,
    account,
    activate,
    deactivate,
    connector: currentConnector,
  } = useWeb3React()
  const { track } = useAnalytics()

  const onConnectWalletClicked = async (connector: AbstractConnector, name: ConnectorName) => {
    await activate(connector, (err) => console.error('web3 activate', err))
    localStorage.setItem('connector', name)
    const account = await connector.getAccount()

    track('WalletConnected', {
      WalletName: name,
      WalletAddress: account,
      chainId,
    })
  }

  const onDisconnectWalletClicked = () => {
    const walletName = localStorage.getItem('connector')
    localStorage.removeItem('connector')
    deactivate()

    track('WalletDisconnected', {
      WalletName: walletName,
      WalletAddress: account,
      chainId,
    })
  }

  const getActiveConnectorName = () => {
    const activeConnector = localStorage.getItem('connector')
    if (!activeConnector) return null
    return activeConnector
  }

  return {
    account,
    active,
    currentConnector,
    onConnectWalletClicked,
    onDisconnectWalletClicked,
    connectors,
    getActiveConnectorName,
  }
}
