import { Box, Button, Row, Text } from 'components'
import { Colors } from 'constants/colors'
import { useModal } from 'contexts'
import styled from 'styled-components'
import { getSpacing, isMetamaskInstalled } from 'utils'
import { useConnect, useResponsive } from '../../hooks'
import { Wallet } from '../Wallet'

const OptionButton = styled.button<{ active: boolean }>`
  border: 0;
  padding: 30px 40px;
  background-color: ${(p) => (p.active ? Colors.BLUE_6 : Colors.GRAY_8)};
  width: 100%;
  height: 100px;
  border-radius: 15px;
  cursor: pointer;
  &:hover {
    background-color: ${Colors.BLUE_6};
  }
`

export const ConnectInterface = () => {
  const {
    account,
    onConnectWalletClicked,
    onDisconnectWalletClicked,
    connectors,
    getActiveConnectorName,
  } = useConnect()

  const { width } = useResponsive()

  const { closeModal } = useModal()

  return (
    <Box
      width={width < 700 ? `${width * 0.8}px` : '600px'}
      borderRadius="28px"
      padding="25px"
      style={{
        backgroundColor: Colors.PURPLE_10,
      }}
    >
      <Box marginBottom={getSpacing('l')}>
        {account && (
          <Row justifyContent="center">
            <Wallet />
          </Row>
        )}
      </Box>
      <Box marginBottom={getSpacing('l')}>
        {connectors.map(({ name, connector, icon }, index) => (
          <OptionButton
            key={`connector_${name}__${index}`}
            onClick={() => {
              if (name === 'Metamask' && !isMetamaskInstalled()) {
                window.open('https://metamask.app.link/dapp/arcx.money/', '_blank')
                return
              }
              onConnectWalletClicked(connector, name).then(() => closeModal())
            }}
            style={{
              marginBottom: index === connectors.length - 1 ? '0px' : getSpacing('m'),
            }}
            active={getActiveConnectorName() === name}
          >
            <Row alignItems="center" justifyContent="space-between">
              <Text weight="semi-bold" size="m">
                {name}
              </Text>
              <img src={icon} style={{ width: '45px' }} />
            </Row>
          </OptionButton>
        ))}
      </Box>
      {account && (
        <Row justifyContent="center">
          <Button
            onClick={onDisconnectWalletClicked}
            size="m"
            text="Disconnect"
            variant="primary"
          />
        </Row>
      )}
    </Box>
  )
}
