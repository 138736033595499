import { useDebugMode, useResponsive } from 'hooks'
import { useEasterEgg } from 'hooks/useEasterEgg'
import { AppNavigationDesktop } from './components'
import { AppNavigationMobile } from './components/AppNavigationMobile'
import { ILink } from './types'

const prodLinks: ILink[] = [
  {
    title: 'Borrow',
    to: '/borrow',
  },
  {
    title: 'Rankings',
    to: '/rankings',
  },
  {
    title: 'Documentation',
    to: 'https://wiki.arcx.money',
    external: true,
  },
]

const devLinks: ILink[] = [
  {
    title: 'Supply',
    to: '/supply',
  },
]

export const AppNavigation = () => {
  useEasterEgg()
  const { debug } = useDebugMode()

  const { width } = useResponsive()

  const links = debug ? [...prodLinks, ...devLinks] : prodLinks

  if (width < 800) {
    return <AppNavigationMobile links={links} />
  }

  return <AppNavigationDesktop links={links} />
}
