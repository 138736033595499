import { providers, Signer } from 'ethers'
import { CompleteScore } from 'types'
import { ArcxApi } from '../ArcxApi'
import { AbstractCapsuleFactory } from './AbstractCapsuleFactory'
import { IContractCapsule } from './CapsuleRefreshEmitter'
import { CoreCapsuleWithAccount } from './CoreCapsuleWithAccount'

export class CoreCapsuleWithAccountFactory extends AbstractCapsuleFactory {
  async create(
    contractAddress: string,
    provider: providers.JsonRpcProvider | Signer,
    completeScore: CompleteScore,
    arcxApi: ArcxApi,
    account?: string,
  ): Promise<IContractCapsule> {
    const capsule = new CoreCapsuleWithAccount(contractAddress, provider, account)

    capsule.creditScoreAttributes = completeScore.attributes
    await capsule.initializeState(arcxApi)

    if (account) {
      await capsule.initializeAccountProperties(arcxApi, completeScore)
    }

    return capsule
  }
}
