import styled from 'styled-components'

export const ModalContainer = styled.div`
  backdrop-filter: blur(5px);
  position: fixed;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 100%;
  transform: translate(-50%, -50%);
  z-index: 10000;
  background-color: rgba(0, 0, 0, 0.5);
  overflow-y: auto;
`

export const ModalInnerContainer = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-height: calc(100vh - 15vh);
`

export const CloseButtonPosition = styled.div`
  position: absolute;
  right: 50px;
  top: 50px;
  z-index: 999999;
`
