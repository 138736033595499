import styled from 'styled-components'
import { FlexProps } from './FlexProps'

export const Row = styled.div<FlexProps>`
  display: flex;
  flex-direction: row;
  ${(p) => `
    ${p.alignItems ? `align-items: ${p.alignItems};` : ''}
    ${p.alignContent ? `align-content: ${p.alignContent};` : ''}
    ${p.justifyContent ? `justify-content: ${p.justifyContent};` : ''}
    ${p.flexWrap ? `flex-wrap: ${p.flexWrap}` : ''}
  `}
`
