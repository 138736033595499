import { asEthAddress } from '@restless/ethereum'
import { asArray, asEnum, asNumber, asObject, asOptional, asString } from '@restless/sanitizers'

export interface SkinMetadata {
  group: string
  description: string
  title: string
  name: string
  contract: string
  tokenIds: string[]
  imageUrl: string
  videoUrl: string
  chainId: string
}

export const asSkinMetadata = asObject<SkinMetadata>({
  title: asString,
  group: asString,
  description: asString,
  name: asString,
  contract: asEthAddress,
  tokenIds: asArray(asString),
  imageUrl: asString,
  videoUrl: asString,
  chainId: asString,
})

/* -------------------------------------------------------------------------- */

export type PassportHolder = {
  address: string
  date_claimed: number
}

export type PassportMetadataStatus = 'active' | 'unclaimed'

/* -------------------------------------------------------------------------- */

export interface PassportMetadata {
  address: string
  status: PassportMetadataStatus
  videoUrl: string
  dateIssued: number
  imageUrlFront: string
  imageUrlBack: string
  activeSkin?: SkinMetadata
}

export const asPassportMetadata = asObject<PassportMetadata>({
  address: asString,
  status: asEnum<PassportMetadataStatus>(['active', 'unclaimed'], 'PassportMetadataStatus'),
  imageUrlFront: asString,
  imageUrlBack: asString,
  videoUrl: asString,
  activeSkin: asOptional(asSkinMetadata),
  dateIssued: asNumber,
})
