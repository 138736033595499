import { Box, Text } from 'components'
import { Colors } from 'constants/colors'
import { useState } from 'react'
import styled from 'styled-components'
import { ScoreAttributes } from 'types'
import { getSpacing } from 'utils'

function isInt(n: number) {
  return n % 1 === 0
}

const formatScoreInPercentile = (score: number) => {
  return isInt(score) ? score : score.toFixed(1)
}

interface IDistributionCardHeader {
  score: string
  percentile: number
  selectedCandle?: ISelectionCandle
  scoreAttributes: ScoreAttributes
}

const DistributionCardHeader = ({
  score,
  percentile,
  selectedCandle,
  scoreAttributes: { min, max },
}: IDistributionCardHeader) => {
  let minScoreInPercentile: number
  let maxScoreInPercentile: number
  const scoreSlice = (parseInt(max) - parseInt(min)) / 10

  if (selectedCandle) {
    minScoreInPercentile = parseInt(min) + scoreSlice * selectedCandle.index
    maxScoreInPercentile = minScoreInPercentile + scoreSlice
  }

  const distributionDescription = () => {
    if (selectedCandle) {
      return (
        <>
          {'Users with a score between '}
          <span style={{ color: Colors.WHITE }}>
            {formatScoreInPercentile(minScoreInPercentile)}
          </span>
          {' and '}
          <span style={{ color: Colors.WHITE }}>
            {formatScoreInPercentile(maxScoreInPercentile)}
          </span>
          .
        </>
      )
    } else if (score) {
      return (
        <>
          You are above <span style={{ color: Colors.WHITE }}>{Math.round(percentile)}%</span> of
          the population.
        </>
      )
    }
    return null
  }

  const displayNumber = () => {
    if (selectedCandle) {
      return selectedCandle.bucket
    } else {
      return score || '-'
    }
  }

  return (
    <Box padding={getSpacing('s')}>
      <Box marginBottom="6px">
        <Text size="xs" weight="regular" style={{ color: Colors.WHITE_50 }}>
          {selectedCandle ? 'Distribution' : 'Score'}
        </Text>
      </Box>
      <Box marginBottom="8px">
        <Text weight="medium" size="xl">
          {displayNumber()}
        </Text>
      </Box>
      {(selectedCandle || score) && (
        <Text weight="regular" size="xs" style={{ color: Colors.WHITE_50, lineHeight: '16px' }}>
          {distributionDescription()}
        </Text>
      )}
    </Box>
  )
}

const SelectionLine = styled.div`
  position: absolute;
  height: 328px;
  width: 0px;
  border-right: 1px dashed ${Colors.WHITE_20};
  bottom: -35px;
  left: 7px;
  z-index: 99;
`

interface IDistributionCandle {
  percentage: number
  insidePercentile: boolean
  setSelectedCandle: (candle?: ISelectionCandle) => void
  index: number
  bucket: number
}

const DistributionCandle = ({
  bucket,
  index,
  percentage,
  insidePercentile = false,
  setSelectedCandle,
}: IDistributionCandle) => {
  const height = 90 * percentage
  const [hover, setHover] = useState(false)

  const onHover = (value: boolean) => {
    setHover(value)
    if (!value) {
      setSelectedCandle()
      return
    }
    setSelectedCandle({ bucket, index })
  }

  return (
    <Box style={{ position: 'relative' }}>
      {hover && <SelectionLine />}
      <Box
        onMouseEnter={() => onHover(true)}
        onMouseLeave={() => onHover(false)}
        display="block"
        borderRadius="4px"
        width="16px"
        height={`${height + 10}px`}
        style={{
          maxHeight: '100px',
          backgroundColor: hover || insidePercentile ? Colors.WHITE : Colors.WHITE_30,
          zIndex: 100,
        }}
      />
    </Box>
  )
}

interface IDistributionGraph {
  scoreAttributes: ScoreAttributes
  score: string
  setSelectedCandle: (candle?: ISelectionCandle) => void
}

const Distribution = ({ scoreAttributes, setSelectedCandle, score }: IDistributionGraph) => {
  const populationMax = Math.max(...scoreAttributes.populations.map((item) => parseInt(item)))
  return (
    <Box>
      <Box flexDirection="row" justifyContent="space-between" alignItems="flex-end">
        {scoreAttributes.ranges.map((bounds, i) => {
          const low = parseFloat(bounds[0])
          const high = parseFloat(bounds[1])
          const bucket = parseInt(scoreAttributes.populations[i])

          const isUsersBucket = parseInt(score) >= low && parseInt(score) <= high

          return (
            <DistributionCandle
              setSelectedCandle={setSelectedCandle}
              insidePercentile={isUsersBucket}
              key={`candle_${i}`}
              percentage={bucket / populationMax}
              index={i}
              bucket={bucket}
            />
          )
        })}
      </Box>
      <Box flexDirection="row" justifyContent="space-between" padding="4px 4px">
        <Text size="xs" style={{ color: Colors.WHITE_30 }}>
          {scoreAttributes.min}
        </Text>
        <Text size="xs" style={{ color: Colors.WHITE_30 }}>
          {scoreAttributes.max}
        </Text>
      </Box>
    </Box>
  )
}

interface IDistributionCard {
  scoreAttributes: ScoreAttributes
  score: string
  percentile: number
}

interface ISelectionCandle {
  index: number
  bucket: number
}

export const DistributionCard = ({ scoreAttributes, score, percentile }: IDistributionCard) => {
  const [selectedCandle, setSelectedCandle] = useState<ISelectionCandle>()
  return (
    <Box
      borderRadius="20px"
      style={{
        width: '230px',
        height: '330px',
        backgroundColor: Colors.PURPLE_8,
        flexShrink: 0,
      }}
    >
      <Box style={{ height: '100%' }} justifyContent="space-between" padding="4px 8px">
        <DistributionCardHeader
          selectedCandle={selectedCandle}
          percentile={percentile}
          score={score}
          scoreAttributes={scoreAttributes}
        />
        <Distribution
          score={score}
          scoreAttributes={scoreAttributes}
          setSelectedCandle={setSelectedCandle}
        />
      </Box>
    </Box>
  )
}
