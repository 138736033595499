import { SocialButton } from '.'
import { TwitterAsset } from '../../assets'
import { Box } from '../Box'
import { Text } from '../index'

interface ITwitterShareButton {
  text?: string
  href?: string
  onClick?: () => void
}

export const TwitterButton = ({ text, onClick }: ITwitterShareButton) => {
  return (
    <SocialButton onClick={onClick} tooltip="Share on Twitter">
      <Box flexDirection="row" alignItems="center" justifyContent="center">
        <img style={{ width: '20px' }} src={TwitterAsset} />
        {text && (
          <Text weight="medium" size="s" style={{ color: '#51B6F9', marginLeft: '6px' }}>
            Share
          </Text>
        )}
      </Box>
    </SocialButton>
  )
}
