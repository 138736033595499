import { AppProviders } from 'AppProviders'
import { BrowserRouter as Router } from 'react-router-dom'
import { AnalyticsWrapper } from './components'
import { Routes } from './routes'

export default function App() {
  return (
    <Router>
      <AppProviders>
        <AnalyticsWrapper>
          <Routes />
        </AnalyticsWrapper>
      </AppProviders>
    </Router>
  )
}
