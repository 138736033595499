import { createContext, FC, useContext } from 'react'

type AnalyticsAttributes = Record<string, string | number | Record<string, string | number>>

export type IAnalytics = {
  isAttributionSdkInitialized: boolean
  track: (event: string, attributes?: AnalyticsAttributes) => void
  identify: (account?: string, attributes?: AnalyticsAttributes) => void
  page: (url?: string, attributes?: AnalyticsAttributes) => void
}

export const AnalyticsContext = createContext<IAnalytics>({
  isAttributionSdkInitialized: false,
  track: () => {},
  identify: () => {},
  page: () => {},
})

export const AnalyticsProvider: FC = ({ children }) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const attributionSdk = (window as any).arcx

  const identify = async (account?: string, attributes?: AnalyticsAttributes) => {
    window.analytics.identify(account, attributes)
  }

  const track = (event: string, attributes?: AnalyticsAttributes) => {
    window.analytics.track(event, { attributes })

    if (attributionSdk) {
      attributionSdk.event(event, attributes)
    }
  }

  const page = (url?: string, attributes?: AnalyticsAttributes) => {
    window.analytics.page(undefined, url, attributes)
  }

  return (
    <AnalyticsContext.Provider
      value={{
        isAttributionSdkInitialized: !!attributionSdk,
        track,
        identify,
        page,
      }}
    >
      {children}
    </AnalyticsContext.Provider>
  )
}

export const useAnalytics = () => useContext(AnalyticsContext)
