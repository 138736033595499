import { TestTokenFactory } from '@arcxgame/contracts/src/typings'
import { BigNumber } from '@ethersproject/bignumber'
import { useWeb3React } from '@web3-react/core'
import { useState } from 'react'
import { Button } from '../Button'

interface IMintingButton {
  mintAmount: BigNumber
  tokenAddress: string
  mintCallback?: () => void
}

export const MintingButton = ({ mintAmount, tokenAddress, mintCallback }: IMintingButton) => {
  const { library, account } = useWeb3React()
  const [loading, setLoading] = useState(false)

  const mint = async () => {
    if (!account) {
      throw new Error('Minting button: not connected')
    }

    const signer = library.getSigner()
    const testToken = TestTokenFactory.connect(tokenAddress, signer)
    setLoading(true)
    try {
      const mintingTx = await testToken.mintShare(account, mintAmount)
      await mintingTx.wait()

      if (mintCallback) {
        mintCallback()
      }
    } catch (error) {
      console.log(error)
    }
    setLoading(false)
  }

  return (
    <Button
      variant="primary"
      onClick={mint}
      disabled={!account}
      loading={loading}
      text="Mint Test Tokens"
    />
  )
}
