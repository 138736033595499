import { useWeb3React } from '@web3-react/core'
import { constants, providers } from 'ethers'
import { createContext, useContext } from 'react'

interface IENSContext {
  resolveAddress: (id: string) => Promise<string>
  searchName: (id: string) => Promise<string | undefined>
}

const ENSContext = createContext<IENSContext>({
  resolveAddress: async () => '',
  searchName: async () => undefined,
})

export const ENSProvider = ({ children }: JSX.ElementChildrenAttribute) => {
  const { library } = useWeb3React<providers.Web3Provider>()

  const resolveAddress = async (addressOrEnsName: string) => {
    if (!addressOrEnsName) {
      throw new Error('addressOrEnsName is empty')
    }

    if (isEthAddress(addressOrEnsName)) return addressOrEnsName

    try {
      return await library.resolveName(addressOrEnsName)
    } catch {
      // Returning address zero to preserve the original behavior
      return constants.AddressZero
    }
  }

  const searchName = async (addressOrEnsName: string): Promise<string | undefined> => {
    if (!isEthAddress(addressOrEnsName)) return undefined

    return library.lookupAddress(addressOrEnsName)
  }

  return (
    <ENSContext.Provider value={{ resolveAddress, searchName }}>{children}</ENSContext.Provider>
  )
}

export const useENS = () => useContext(ENSContext)

const isEthAddress = (address: string) => !!address.match(/^0x[a-fA-F0-9]{40}$/)
