/* eslint-disable @typescript-eslint/no-explicit-any */

import { useCallback, useLayoutEffect, useState } from 'react'

type PromiseVoid = () => Promise<void>

const INITIAL_STATE: [any, any, boolean, PromiseVoid] = [undefined, undefined, true, async () => {}]

/**
 * Structure for useAsync state: [data, error, loading, refresh]
 */
export function useAsync<T>(
  execute: () => Promise<T>,
  deps: ReadonlyArray<any>,
  forceValue?: T,
  errorIdentifier = '',
): [T | undefined, any, boolean, PromiseVoid] {
  const [state, setState] = useState<[T | undefined, any, boolean, PromiseVoid]>(INITIAL_STATE)

  const refresh = useCallback(async () => {
    try {
      setState([await execute(), undefined, false, refresh])
    } catch (err) {
      console.error('useAsyncRefresh', err)
      setState([undefined, err, false, refresh])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deps])

  useLayoutEffect(() => {
    if (forceValue) {
      setState([forceValue, undefined, false, refresh])
      return
    }
    setState((prev) => [prev[0], prev[1], true, refresh])

    let cancelled = false

    execute().then(
      (result) => cancelled === false && setState([result, undefined, false, refresh]),
      (err) => {
        console.error(errorIdentifier, err)
        return cancelled === false && setState([undefined, err, false, refresh])
      },
    )

    return () => {
      cancelled = true
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps)

  return state
}
