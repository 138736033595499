import { useWeb3React } from '@web3-react/core'
import {} from 'components'
import { useAsync } from 'hooks'
import { useArcxApi } from './useArcxApi'

export const useAllScoreAttributes = () => {
  const { account } = useWeb3React()
  const arcxApi = useArcxApi()
  const [userScoreWithAttributes] = useAsync(async () => {
    if (!account || !arcxApi) {
      return undefined
    } else {
      return arcxApi.getScoresWithAttributes(account)
    }
  }, [arcxApi, account])

  return {
    userScoreWithAttributes,
    getUserScoreWithAttributes: (protocol: string) =>
      userScoreWithAttributes?.find((fullValue) => fullValue.attributes.name === protocol),
  }
}
