import { Box } from 'components/Box'
import CSS from 'csstype'
import styled from 'styled-components'
import { Colors } from '../../constants'
import { loading } from './animations'

export const StyledLoadingRectangle = styled.div`
  height: 12px;
  width: 100%;
  border-radius: 10px;
  position: relative;
  overflow: hidden;
  background-color: ${Colors.PURPLE_4}50;
  &:before {
    content: '';
    display: block;
    position: absolute;
    left: -300px;
    top: 0;
    height: 100%;
    border-radius: 10px;
    width: 100%;
    background: linear-gradient(to right, ${Colors.PURPLE_4} 0%, rgba(256, 256, 256, 0.1) 100%);
    animation: ${loading} 1200ms ease-in-out infinite;
  }
`

interface ISkeletonRectangle {
  style?: CSS.Properties
  width?: CSS.Property.Width
}

export const SkeletonRectangle = ({ style, width }: ISkeletonRectangle) => (
  <Box style={{ width }}>
    <Box flex="1">
      <StyledLoadingRectangle style={style} />
    </Box>
  </Box>
)
