import styled from 'styled-components'
import { getSpacing } from 'utils'
import { INavigationProps } from '../types'
import { AppNavigationLinks } from './AppNavigationLinks'

const AppNavigationContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: ${getSpacing('l')} ${getSpacing('xxxxl')} ${getSpacing('xxl')} ${getSpacing('xxxxl')};
`

export const AppNavigationDesktop = ({ links = [] }: INavigationProps) => {
  return (
    <AppNavigationContainer>
      <AppNavigationLinks links={links} />
    </AppNavigationContainer>
  )
}
