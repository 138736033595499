import { ArrowRightIcon } from '@heroicons/react/solid'
import { Box, Link, SocialButtonContainer, Text } from 'components'
import { Colors } from 'constants/colors'
import { useState } from 'react'

export const ViewSkinsLink = () => {
  const [hover, setHover] = useState(false)
  return (
    <SocialButtonContainer onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)}>
      <Link to="/skins">
        <Box
          style={{ cursor: 'pointer', alignSelf: 'flex-start' }}
          flexDirection="row"
          alignItems="center"
        >
          <Text
            size="s"
            center
            weight="regular"
            style={{ color: hover ? Colors.PURPLE_2 : Colors.WHITE }}
          >
            View all skins
          </Text>
          <ArrowRightIcon
            style={{
              marginLeft: '4px',
              marginTop: '1px',
              width: '15px',
              color: hover ? Colors.PURPLE_2 : Colors.WHITE,
            }}
          />
        </Box>
      </Link>
    </SocialButtonContainer>
  )
}
