import { createContext, ElementType, useContext, useRef, useState } from 'react'
import { Modal } from '../components'
import { DefaultErrorWrapper } from '../components/DefaultErrorWrapper'

interface ModalContextInterface {
  isOpened: boolean
  openWith: (content: JSX.Element, options?: ModalOptions) => void
  content?: JSX.Element
  closeModal: () => void
  handleError<T>(promiseFn: () => Promise<T> | T): Promise<T | void>
}

export const ModalContext = createContext<ModalContextInterface>({
  isOpened: false,
  openWith: () => {},
  content: <div />,
  closeModal: () => {},
  handleError: async () => {},
})

interface ModalOptions {
  onClose?: () => void
  hideCloseButton?: boolean
}

interface IModalProviderProps {
  children: JSX.Element
  ErrorMessageWrapper?: ElementType
}

type ModalProps = Parameters<typeof Modal>[0]
type ModalState = [boolean, ModalProps?]

export const ModalProvider = ({
  children,
  ErrorMessageWrapper = DefaultErrorWrapper,
}: IModalProviderProps) => {
  const [modalState, setModalState] = useState<ModalState>([false])
  const [, triggerModalUpdate] = useState(false)
  const ref = useRef(<div />)
  const onCloseOverrideRef = useRef<() => void>()

  const openWith = (content: JSX.Element, options?: ModalOptions) => {
    ref.current = content

    onCloseOverrideRef.current = options?.onClose

    if (modalState) {
      triggerModalUpdate((prev) => !prev)
    }
    setModalState([true, options])
  }

  const closeModal = () => {
    const callback = onCloseOverrideRef.current
    onCloseOverrideRef.current = undefined
    callback?.()
    setModalState([false])
  }

  async function handleError<T>(promiseFn: () => Promise<T> | T): Promise<T | void> {
    try {
      return await promiseFn()
    } catch (e) {
      if (e instanceof Error) {
        openWith(<ErrorMessageWrapper>{e.message}</ErrorMessageWrapper>)
      }
      console.error(e)
    }
  }

  return (
    <ModalContext.Provider
      value={{
        isOpened: modalState[0],
        openWith,
        content: ref.current,
        closeModal,
        handleError,
      }}
    >
      <Modal {...modalState[1]} />
      {children}
    </ModalContext.Provider>
  )
}

export const useModal = () => {
  return useContext(ModalContext)
}
