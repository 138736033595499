import { useWeb3React } from '@web3-react/core'
import { getConfig } from 'config'
import { useMemo } from 'react'
import { ArcxApi } from '../entities'

export const useArcxApi = () => {
  const { chainId } = useWeb3React()
  const arcxApi = useMemo<ArcxApi | undefined>(() => {
    if (chainId) {
      return new ArcxApi(getConfig(chainId).backendUrl, chainId.toString())
    }
  }, [chainId])
  return arcxApi
}
