import { IGrowthSteps } from 'components'

// TODO: move this into GrowthBar.tsx if it's not reused anywhere else
export const growthSteps: IGrowthSteps[] = [
  {
    background: 'linear-gradient(270deg, #C7F3E3 0%, #EDFDF5 100%)',
    step: 25,
  },
  {
    background: 'linear-gradient(270deg, #6BE6A4 0%, #C6F3E2 100%)',
    step: 50,
  },
  {
    background: 'linear-gradient(270deg, #B0C292 0%, #60E4A2 41.63%, #66E5A3 47.7%, #69E6A3 100%)',
    step: 70,
  },
  {
    background: 'linear-gradient(270deg, #E0706F 0%, #FA9E62 46.38%, #B0C292 100%)',
    step: 90,
  },
  {
    background: 'linear-gradient(270deg, #E07170 0%, #E0706F 100%)',
    step: 100,
  },
]
