import { Colors } from 'constants/colors'
import styled from 'styled-components'

export const ButtonContainer = styled.button`
  padding: 8px;
  background-color: ${Colors.PURPLE_8};
  border-radius: 14px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border: solid 3px transparent;
  text-decoration: none;
  transition: 0.15s;
  &:hover {
    background-color: white;
    color: ${Colors.PURPLE_10};
  }
`

export const ChainList = styled.div`
  position: absolute;
  top: 55px;
  right: 0;
  background-color: ${Colors.PURPLE_8};
  border-radius: 14px;
  @media (max-width: 600px) {
    right: unset;
  }
  z-index: 99999;
`

export const ChainItem = styled.div`
  font-size: 14px;
  font-weight: 400;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px 14px;
  border-radius: 14px;
  min-width: 190px;
  cursor: pointer;
  transition: 0.15s;
  &:hover {
    background-color: white;
    color: ${Colors.PURPLE_10};
  }
`
