import { useWeb3React } from '@web3-react/core'
import { useAnalytics } from '../contexts/AnalyticsContext'

/* eslint-disable-next-line  @typescript-eslint/no-explicit-any */
const _window = window as any

export const chains = {
  ethereum: async () => {
    await _window.ethereum?.request({
      method: 'wallet_switchEthereumChain',
      params: [{ chainId: '0x1' }],
    })
  },
  polygon: async () => {
    try {
      await _window.ethereum?.request({
        method: 'wallet_switchEthereumChain',
        params: [{ chainId: '0x89' }],
      })
    } catch (error) {
      await _window.ethereum?.request({
        method: 'wallet_addEthereumChain',
        params: [
          {
            chainId: '0x89',
            chainName: 'Polygon Mainnet',
            nativeCurrency: { name: 'MATIC', symbol: 'MATIC', decimals: 18 },
            rpcUrls: ['https://polygon-rpc.com/'],
            blockExplorerUrls: ['https://polygonscan.com/'],
          },
        ],
      })
    }
  },
  goerli: async () => {
    await _window.ethereum?.request({
      method: 'wallet_switchEthereumChain',
      params: [{ chainId: '0x5' }],
    })
  },
  mumbai: async () => {
    try {
      await _window.ethereum?.request({
        method: 'wallet_switchEthereumChain',
        params: [{ chainId: '0x13881' }],
      })
    } catch (error) {
      await _window.ethereum?.request({
        method: 'wallet_addEthereumChain',
        params: [
          {
            chainId: '0x13881',
            chainName: 'Polygon Testnet Network',
            nativeCurrency: { name: 'MATIC', symbol: 'MATIC', decimals: 18 },
            rpcUrls: ['https://rpc-endpoints.superfluid.dev/mumbai/'],
            blockExplorerUrls: ['https://explorer-mumbai.maticvigil.com/'],
          },
        ],
      })
    }
  },
}

export const useChainSwitcher = () => {
  const { account } = useWeb3React()
  const { track } = useAnalytics()

  const switchChainId = async (chainId: number) => {
    switch (chainId) {
      case 1:
        await chains.ethereum()
        break
      case 5:
        await chains.goerli()
        break
      case 137:
        await chains.polygon()
        break
      case 80001:
        await chains.mumbai()
        break
      default:
        await chains.ethereum()
    }

    track('NetworkChanged', {
      WalletStatus: account ? 'Connected' : 'Disconnected',
      ...(account ? { WalletAddress: account } : {}),
      chainId: chainId || 1,
    })
  }

  return {
    switchChainId,
  }
}
