async function getResponseValue(res: Response) {
  return res.text().then((text) => {
    try {
      return JSON.parse(text)
    } catch (err) {
      return text
    }
  })
}

export async function handleHttpResponse(res: Response) {
  if (res.ok) {
    return getResponseValue(res)
  }
  return getResponseValue(res).then((value) => Promise.reject(value))
}
