import styled from 'styled-components'
import { Breakpoints } from '../../constants'

export type IconSize = 'xxs' | 'xs' | 's' | 'm' | 'l' | 'xl'

interface IconProps {
  size?: IconSize
  alt?: string
  src: string
}

const iconSizes = {
  xxs: 20,
  xs: 30,
  s: 40,
  m: 50,
  l: 85,
  xl: 100,
}

const StyledIcon = styled.img<{ size?: IconSize }>`
  @media only screen and (max-width: ${Breakpoints.TABLET}px) {
    width: ${({ size }) => (size ? `${iconSizes[size]}` : `${iconSizes.m}`)}px;
  }

  @media only screen and (min-width: ${Breakpoints.TABLET}px) {
    width: ${({ size }) => (size ? `${iconSizes[size]}` : `${iconSizes.m}`)}px;
  }

  @media only screen and (min-width: ${Breakpoints.DESKTOP}px) {
    width: ${({ size }) => (size ? `${iconSizes[size]}` : `${iconSizes.m}`)}px;
  }
`

export const Icon = ({ size, src, alt }: IconProps) => (
  <StyledIcon src={src} size={size} alt={alt} />
)
