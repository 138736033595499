import { useModal } from 'contexts'
import { useEffect, useRef } from 'react'
import ReactDOM from 'react-dom'
import { Button } from '..'
import { CloseButtonPosition, ModalContainer, ModalInnerContainer } from './styles'

export interface IModalProps {
  hideCloseButton?: boolean
}

export const Modal = ({ hideCloseButton }: IModalProps) => {
  const { isOpened, closeModal, content } = useModal()
  const bodyRoot = document.querySelector('body')
  const bodyWidth = bodyRoot?.clientWidth

  if (bodyRoot) {
    bodyRoot.setAttribute('style', `overflow-y: ${isOpened ? 'hidden' : 'auto'}`)
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const modalRef = useRef<any>()

  useEffect(() => {
    const checkIfClickedOutside = (e: Event) => {
      if (isOpened && modalRef.current && !modalRef.current.contains(e.target)) {
        closeModal()
      }
    }

    document.addEventListener('mousedown', checkIfClickedOutside)

    return () => {
      document.removeEventListener('mousedown', checkIfClickedOutside)
    }
  }, [isOpened, closeModal])

  if (isOpened) {
    const modalRoot = document.querySelector('#modal-root')
    if (!modalRoot) {
      throw new Error('Modal root is not found')
    }
    return ReactDOM.createPortal(
      <ModalContainer>
        <CloseButtonPosition>
          {bodyWidth && bodyWidth > 600 && !hideCloseButton && (
            <Button onClick={closeModal} size="s" variant="primary" text="Close" />
          )}
        </CloseButtonPosition>
        <ModalInnerContainer ref={modalRef} style={{ zIndex: 11000 }}>
          {content}
        </ModalInnerContainer>
      </ModalContainer>,
      modalRoot,
    )
  }

  return null
}
