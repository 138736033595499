import CSS from 'csstype'
import { FC, ReactNode } from 'react'
import { Box } from '../index'
import { LoadingDots } from './LoadingAnimation'
import { StyledButton } from './styles'
import { ButtonSize, ButtonVariant } from './types'

interface ButtonProps {
  variant?: ButtonVariant
  size?: ButtonSize
  disabled?: boolean
  loading?: boolean
  fullWidth?: boolean
  width?: CSS.Property.Width
  borderWidth?: CSS.Property.BorderWidth
  text?: string
  href?: string
  onClick?: () => void
  style?: CSS.Properties
  children?: ReactNode
}

export const Button: FC<ButtonProps> = ({
  variant = 'primary',
  size = 'm',
  disabled = false,
  loading = false,
  fullWidth = false,
  text,
  href,
  width,
  borderWidth,
  onClick,
  style,
  children,
}) => {
  if (!(text || children)) {
    throw Error('Button: either text or children must be given')
  }

  return (
    <StyledButton
      style={style}
      $loading={loading}
      variant={variant}
      size={size}
      width={width}
      borderWidth={borderWidth}
      $disabled={disabled}
      disabled={disabled}
      $fullWidth={fullWidth}
      onClick={() => {
        if (href) {
          window.open(href)
        } else if (onClick) {
          onClick()
        }
      }}
    >
      {loading ? (
        <Box flexDirection="row" alignItems="center" justifyContent="center">
          <LoadingDots />
        </Box>
      ) : (
        children || text
      )}
    </StyledButton>
  )
}
