import { BaseERC20Factory } from '@arcxgame/contracts/src/typings'
import { BigNumber, providers, Signer } from 'ethers'
import { PoolCapsule } from './PoolCapsule'

type AccountProperties = {
  depositAssetBalances: Record<string, BigNumber>
  lpBalance: BigNumber
  lpTokensValue: BigNumber
}

export class PoolCapsuleWithAccount extends PoolCapsule {
  /* -------------------------------------------------------------------------- */
  /*                                 Properties                                 */
  /* -------------------------------------------------------------------------- */

  accountProperties?: AccountProperties

  /* -------------------------------------------------------------------------- */
  /*                                   Methods                                  */
  /* -------------------------------------------------------------------------- */

  /* ------------------------------- Constructor ------------------------------ */

  constructor(
    public readonly contractAddress: string,
    public provider: providers.JsonRpcProvider | Signer,
    public account?: string,
  ) {
    super(contractAddress, provider)
  }

  /* ----------------------------- Public methods ----------------------------- */

  async initializeState(): Promise<void> {
    return this._refresh()
  }

  /* ---------------------------- Internal methods ---------------------------- */

  // Override method, but eslint pre-commit doesn't like it
  protected async _refresh() {
    await super._refresh()

    if (this.account) {
      await this._refreshAccountProperties()
    }
  }

  private async _refreshAccountProperties() {
    const [lpBalance, accountBalances] = await Promise.all([
      this._pool.balanceOf(this.account),
      this._getAccountBalances(),
    ])

    this.accountProperties = {
      lpBalance,
      lpTokensValue: this.totalSupply.gt(0)
        ? lpBalance.mul(this.poolValue).div(this.totalSupply)
        : BigNumber.from(0),
      depositAssetBalances: accountBalances,
    }
  }

  /**
   * Returns a dictionary of asset addresses and the balances of the account
   */
  private async _getAccountBalances() {
    const balances: Record<string, BigNumber> = {}

    await Promise.all(
      this.supportedDepositAssets.map(async ({ address: assetAddress }) => {
        const erc20 = BaseERC20Factory.connect(assetAddress, this.provider)

        balances[assetAddress] = await erc20.balanceOf(this.account)
      }),
    )

    return balances
  }
}
