import { CastError, Result, Sanitizer } from '@restless/sanitizers'

export function extendedCast<T>(data: unknown, sanitizer: Sanitizer<T>, message = ''): T {
  const result = sanitizer(data, 'path')

  if (Result.isOk(result)) {
    return result.ok
  } else {
    const extractedSanitizerErrorOutput = JSON.stringify(result)
    message = message.concat(' => ', extractedSanitizerErrorOutput)
    throw new CastError(result.error, message)
  }
}
