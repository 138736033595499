import { DefaultPassport } from '@arcxmoney/passport-js'
import { ArcPassportCard, BodyText, Box, Title, ViewSkinsLink } from 'components'
import { AnimationToggle } from 'components/AppNavigation/components/AnimationToggle'
import { Colors } from 'constants/colors'
import { useAnimationMode, useResponsive } from 'hooks'
import { getSpacing } from 'utils'
import { ClaimButton } from './ClaimButton'
import { DescriptionPoint } from './DescriptionPoint'

const PassportClaimMobile = () => {
  const { width } = useResponsive()
  const { showAnimation } = useAnimationMode()

  return (
    <Box alignItems="center">
      <Title
        style={{ textAlign: 'center', marginBottom: getSpacing('m'), lineHeight: '40px' }}
        size="xxl"
        tag="h1"
        weight="semi-bold"
      >
        Claim your Passport
      </Title>
      <BodyText
        style={{
          color: Colors.WHITE_50,
          textAlign: 'center',
          marginBottom: getSpacing('l'),
        }}
        weight="regular"
        size="s"
      >
        <>
          Build scores which give you access to
          <br />
          exclusive opportunities in DeFi
        </>
      </BodyText>
      {showAnimation ? (
        <Box flexDirection="row" justifyContent="center">
          <DefaultPassport width={width * 0.75} height={width * 0.75} />
        </Box>
      ) : (
        <Box justifyContent="center" flexDirection="row" marginBottom={getSpacing('s')}>
          <ArcPassportCard size={200} />
        </Box>
      )}
      <Box
        flexDirection="row"
        justifyContent="center"
        alignItems="center"
        marginBottom={getSpacing('l')}
      >
        <ViewSkinsLink />
        <Box marginLeft="8px">
          <AnimationToggle />
        </Box>
      </Box>
      <Box>
        <DescriptionPoint
          icon="🏆"
          primaryMessage="Earn reputation"
          secondaryMessage="Start building your on-chain history"
        />
        <DescriptionPoint
          icon="👀"
          primaryMessage="Discover scores"
          secondaryMessage="Learn how you rank among others"
        />
        <DescriptionPoint
          icon="🤑"
          primaryMessage="Gain access"
          secondaryMessage="Exclusive perks based on your scores"
        />
      </Box>
      <Box width="100%">
        <ClaimButton />
      </Box>
    </Box>
  )
}

export const PassportClaim = () => {
  const { showAnimation } = useAnimationMode()
  const { width } = useResponsive()

  if (width < 800) {
    return <PassportClaimMobile />
  }

  return (
    <Box paddingBottom="50px">
      <Title
        style={{ textAlign: 'center', marginBottom: getSpacing('m') }}
        size="xxl"
        tag="h1"
        weight="semi-bold"
      >
        Claim your passport
      </Title>
      <BodyText
        style={{
          color: Colors.WHITE_50,
          textAlign: 'center',
          marginBottom: getSpacing('m'),
        }}
        weight="regular"
        size="s"
      >
        <>
          Build scores which give you access to
          <br />
          exclusive opportunities in DeFi
        </>
      </BodyText>
      <Box flexDirection="row" alignItems="center" justifyContent="center">
        <Box>
          {showAnimation ? (
            <DefaultPassport width={335} height={335} />
          ) : (
            <Box alignItems="center" marginBottom={getSpacing('s')}>
              <ArcPassportCard size={200} />
            </Box>
          )}
          <Box flexDirection="row" alignItems="center" justifyContent="center">
            <ViewSkinsLink />
            <Box marginLeft="8px">
              <AnimationToggle />
            </Box>
          </Box>
        </Box>
        <Box
          justifyContent="space-between"
          padding={`${getSpacing('m')} 0`}
          marginLeft={showAnimation ? undefined : getSpacing('xxxxl')}
          style={{ maxHeight: '325px' }}
        >
          <DescriptionPoint
            icon="🏆"
            primaryMessage="Earn reputation"
            secondaryMessage="Start building your on-chain history"
          />
          <DescriptionPoint
            icon="👀"
            primaryMessage="Discover scores"
            secondaryMessage="Learn how you rank among others"
          />
          <DescriptionPoint
            icon="🤑"
            primaryMessage="Gain access"
            secondaryMessage="Exclusive perks based on your scores"
          />
          <ClaimButton />
        </Box>
      </Box>
    </Box>
  )
}
