import { BigNumber, constants } from 'ethers'
import { quickWeiValuePow, SECONDS_IN_YEAR } from 'utils'

export function calculateAPY(
  distributedTokens: BigNumber,
  distributionDurationSecs: BigNumber,
  priceOfDistributedToken: BigNumber,
  stakedUsdValue: BigNumber,
) {
  if (stakedUsdValue.isZero()) {
    return constants.Zero
  }
  const durationInMonths = Math.round(
    (distributionDurationSecs.toNumber() / SECONDS_IN_YEAR.toNumber()) * 12,
  )
  const r = distributedTokens.mul(priceOfDistributedToken).div(stakedUsdValue)
  const N = BigNumber.from(12).div(durationInMonths)
  return quickWeiValuePow(constants.WeiPerEther.add(r), N).sub(constants.WeiPerEther)
}
