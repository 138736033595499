/* eslint-disable @typescript-eslint/indent */
import { Box, Text, Tooltip } from 'components'
import { useResponsive } from 'hooks'
import { useState } from 'react'
import styled from 'styled-components'
import { getSpacing, SpacingType } from 'utils'
import { SkeletonRectangle } from '../Skeleton'
import { TextButton } from './TextButton'

export interface IHeader {
  name: string
  alignContent?: 'left' | 'center' | 'right'
  tooltip?: string
}

export interface ITableRow {
  cells: (string | JSX.Element)[]
  onClick?: () => void
}

export interface IArcTableProps {
  headers: IHeader[]
  rows?: ITableRow[]
  itemsPerPage?: number
  loading?: boolean
  rowSpacing?: SpacingType
  /**
   * If true, the table will show all items and the pages text will be hidden.
   * Also, the `itemsPerPage` prop will be ignored.
   */
  hidePagination?: boolean
}

export const ArcTable = ({
  headers,
  rows = [],
  itemsPerPage = 10,
  rowSpacing = 'm',
  loading,
  hidePagination,
}: IArcTableProps) => {
  const [pageIndex, setPageIndex] = useState(1)
  const { width } = useResponsive()
  const isMobile = width <= 800

  const skeletonRows = (
    <CustomRow>
      {headers.map((header, i) => (
        <td key={i}>
          <Box
            paddingLeft={
              !header.alignContent || header.alignContent === 'left' ? 0 : getSpacing('s')
            }
            paddingRight={header.alignContent === 'right' ? 0 : getSpacing('s')}
          >
            <SkeletonRectangle />
          </Box>
        </td>
      ))}
    </CustomRow>
  )

  const contentRows = rows?.map((row, i) => (
    <CustomRow key={i} enableHover={row.onClick !== undefined} onClick={row.onClick}>
      {row.cells.map((cell, j) => (
        <td key={j} style={{ textAlign: headers[j].alignContent ?? 'left' }}>
          {typeof cell === 'string' ? (
            <Text
              weight="regular"
              size="xs"
              color="WHITE"
              style={{ opacity: row.onClick === undefined ? 0.5 : 1 }}
            >
              {cell}
            </Text>
          ) : (
            cell
          )}
        </td>
      ))}
    </CustomRow>
  ))

  const paginatedRows = contentRows?.slice((pageIndex - 1) * itemsPerPage, pageIndex * itemsPerPage)

  const maxPageNr = contentRows?.length > 0 ? Math.ceil(contentRows.length / itemsPerPage) : 1

  let rowsToShow: JSX.Element | JSX.Element[]
  if (loading) {
    rowsToShow = skeletonRows
  } else if (hidePagination) {
    rowsToShow = contentRows
  } else {
    rowsToShow = paginatedRows
  }

  return (
    <>
      <CustomTable isMobile={isMobile} rowSpacing={rowSpacing}>
        <thead>
          <tr>
            {headers.map((header, i) => (
              <th key={i}>
                <Box
                  flexDirection="row"
                  justifyContent={getJustifyContent(headers[i].alignContent)}
                  alignItems="center"
                  marginBottom={getSpacing('s')}
                >
                  <Text weight="regular" size="xs" color="TEXT_DARK">
                    {header.name}
                  </Text>
                  {header.tooltip && (
                    <Box marginLeft={getSpacing('xs')}>
                      <Tooltip content={header.tooltip} />
                    </Box>
                  )}
                </Box>
              </th>
            ))}
          </tr>
        </thead>
        <tbody>{rowsToShow}</tbody>
      </CustomTable>
      {!hidePagination && (
        <Box
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
          marginTop={getSpacing('s')}
          marginLeft={isMobile ? '0px' : getSpacing('m')}
          marginRight={isMobile ? '0px' : getSpacing('m')}
        >
          <Text weight="regular" size="xs" color="TEXT_DARK">
            {`Page ${pageIndex} of ${maxPageNr}`}
          </Text>
          <Box flexDirection="row">
            <TextButton disabled={pageIndex === 1} onClick={() => setPageIndex(pageIndex - 1)}>
              Back
            </TextButton>
            <Box marginLeft={getSpacing('s')}>
              <TextButton
                disabled={pageIndex === maxPageNr}
                onClick={() => setPageIndex(pageIndex + 1)}
              >
                Next
              </TextButton>
            </Box>
          </Box>
        </Box>
      )}
    </>
  )
}

interface ICustomTableProps {
  rowSpacing?: SpacingType
  isMobile?: boolean
}

const CustomTable = styled.table<ICustomTableProps>`
  border-collapse: collapse;

  tr {
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  }

  tr:last-child {
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  }

  th:first-child,
  td:first-child {
    padding-left: ${(props) => (props.isMobile ? '0px' : getSpacing('m'))};
  }

  th:last-child,
  td:last-child {
    padding-right: ${(props) => (props.isMobile ? '0px' : getSpacing('m'))};
  }

  td {
    padding-top: ${(p) => getSpacing(p.rowSpacing)};
    padding-bottom: ${(p) => getSpacing(p.rowSpacing)};
  }

  th,
  td,
  tr {
    padding-right: ${(props) => (props.isMobile ? '0px' : 'initial')};
  }
`

interface ICustomRowProps {
  enableHover?: boolean
}

const CustomRow = styled.tr<ICustomRowProps>`
  transition: 0.1s;

  &:hover {
    background-color: ${(p) => p.enableHover && 'rgba(255, 255, 255, 0.05)'};
    cursor: ${(p) => p.enableHover && 'pointer'};
  }
`

function getJustifyContent(alignContent?: 'left' | 'center' | 'right') {
  switch (alignContent) {
    case 'left':
      return 'flex-start'
    case 'right':
      return 'flex-end'
    case 'center':
      return 'center'
    default:
      return 'flex-start'
  }
}
