import { ErrorBoundary, Provider as RollbarProvider } from '@rollbar/react'
import { Web3ReactProvider } from '@web3-react/core'
import { AutoConnect, Box, ErrorBox, NotificationsWrapper } from 'components'
import { sharedConfig } from 'config/config.shared'
import { AnalyticsProvider, CreditProvider, ENSProvider, ModalProvider } from 'contexts'
import { providers } from 'ethers'
import { AnimationModeProvider, DebugModeProvider, PassportProvider, useConfig } from 'hooks'
import { FC } from 'react'
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'

function getLibrary(provider: providers.ExternalProvider) {
  return new providers.Web3Provider(provider)
}

export const AppProviders: FC = ({ children }) => {
  const { reCaptchaSiteKey } = useConfig()

  return (
    <RollbarProvider config={sharedConfig.rollbarConfig}>
      <Web3ReactProvider getLibrary={getLibrary}>
        <AnalyticsProvider>
          <GoogleReCaptchaProvider reCaptchaKey={reCaptchaSiteKey}>
            <ErrorBoundary
              fallbackUI={() => (
                <Box justifyContent="center" style={{ height: '100vh' }}>
                  <ErrorBox />
                </Box>
              )}
            >
              <CreditProvider>
                <NotificationsWrapper>
                  <PassportProvider>
                    <ENSProvider>
                      <ModalProvider>
                        <AutoConnect>
                          <DebugModeProvider>
                            <AnimationModeProvider>{children}</AnimationModeProvider>
                          </DebugModeProvider>
                        </AutoConnect>
                      </ModalProvider>
                    </ENSProvider>
                  </PassportProvider>
                </NotificationsWrapper>
              </CreditProvider>
            </ErrorBoundary>
          </GoogleReCaptchaProvider>
        </AnalyticsProvider>
      </Web3ReactProvider>
    </RollbarProvider>
  )
}
