import { useWeb3React } from '@web3-react/core'
import {} from 'components'
import { AbstractCapsuleFactory } from 'entities/capsules/AbstractCapsuleFactory'
import { providers } from 'ethers'
import { useArcxApi, useAsync, useCreditScore } from 'hooks'
import { createContext, useContext, useState } from 'react'
import { CompleteScore } from 'types'
import { loadContracts } from 'utils'
import {
  ArcxApi,
  CoreCapsuleWithAccount,
  CoreCapsuleWithAccountFactory,
  IContractCapsule,
  PoolCapsuleWithAccount,
  PoolCapsuleWithAccountFactory,
} from '../entities'

export interface ICreditContext {
  coreCapsules: Map<string, CoreCapsuleWithAccount>
  poolCapsules: Map<string, PoolCapsuleWithAccount>
  refreshAllCapsules: () => Promise<void>

  /**
   * `isGlobalRefreshing` is `null` if no refresh has been attempted yet
   */
  isGlobalRefreshing: boolean | null
  creditScore?: CompleteScore
}

const defaultContext: ICreditContext = {
  coreCapsules: new Map(),
  poolCapsules: new Map(),
  refreshAllCapsules: async () => {},
  isGlobalRefreshing: null,
  creditScore: null,
}

export const CreditContext = createContext<ICreditContext>(defaultContext)

export const CreditProvider = ({ children }) => {
  const { library, account, chainId } = useWeb3React<providers.Web3Provider>()
  const arcxApi = useArcxApi()
  const [coreCapsules, setCoreCapsules] = useState<Map<string, CoreCapsuleWithAccount>>(new Map())
  const [poolCapsules, setPoolCapsules] = useState<Map<string, PoolCapsuleWithAccount>>(new Map())
  const creditScore = useCreditScore(account)
  const [isGlobalRefreshing, setIsGlobalRefreshing] = useState<boolean | null>(null)

  const loadCapsules = async (
    addresses: string[],
    factory: AbstractCapsuleFactory,
    creditScoreInfo?: CompleteScore,
  ): Promise<Map<string, IContractCapsule>> => {
    const capsules = new Map<string, IContractCapsule>()
    const signer = account ? library.getSigner() : library
    await Promise.all(
      addresses.map(async (address) => {
        const capsule = await factory.create(
          ...[address, signer, creditScoreInfo, arcxApi, account].filter(
            (ele) => ele !== undefined,
          ),
        )

        capsules.set(address, capsule)
      }),
    )

    return capsules
  }

  const createAllCapsules = async (creditScoreInfo: CompleteScore) => {
    if (coreCapsules.size > 0) {
      // Do not create new capsules if already created
      return {
        cores: coreCapsules,
        pools: poolCapsules,
      }
    }

    const coreContractDetails = loadContracts({
      source: 'ArcProxy',
      name: 'SapphireCoreProxy',
      network: chainId.toString(),
    })
    const poolContractDetails = loadContracts({
      source: 'ArcProxy',
      name: 'SapphirePoolProxy',
      network: chainId.toString(),
    })

    const [newCoreCapsules, newPoolCapsules] = await Promise.all([
      loadCapsules(
        coreContractDetails.map(({ address }) => address),
        new CoreCapsuleWithAccountFactory(),
        creditScoreInfo,
      ),
      loadCapsules(
        poolContractDetails.map(({ address }) => address),
        new PoolCapsuleWithAccountFactory(),
      ),
    ])

    return {
      cores: newCoreCapsules as Map<string, CoreCapsuleWithAccount>,
      pools: newPoolCapsules as Map<string, PoolCapsuleWithAccount>,
    }
  }

  const refreshCapsules = async (
    arcxApi: ArcxApi,
    coreCapsules: CoreCapsuleWithAccount[],
    poolCapsules: PoolCapsuleWithAccount[],
    account: string,
    library: providers.JsonRpcProvider,
    creditScore: CompleteScore,
  ) => {
    if (coreCapsules.length === 0 || poolCapsules.length === 0) return

    const poolRefreshes = poolCapsules.map((capsule) => {
      if (capsule.account && !account) {
        delete capsule.account
        capsule.provider = library
      } else if (account && account !== capsule.account) {
        capsule.account = account
        capsule.provider = library.getSigner()
      }

      return capsule.refresh()
    })

    // The pool refreshes must come before the core refreshes because of the calculation of
    // max repay amounts
    await Promise.all(poolRefreshes)

    const coreRefreshes = coreCapsules.map(async (capsule) => {
      if (capsule.account && !account) {
        delete capsule.account
        capsule.provider = library
      } else if (account && account !== capsule.account) {
        capsule.account = account
        capsule.provider = library.getSigner()
        await capsule.initializeAccountProperties(arcxApi, creditScore)
      }

      await capsule.refresh()
    })

    await Promise.all(coreRefreshes)
  }

  useAsync(async () => {
    if (
      (coreCapsules.size === 0 && !account && !chainId) ||
      !creditScore.attributes ||
      !creditScore.simpleScore
    ) {
      return
    }

    setIsGlobalRefreshing(true)
    try {
      const { cores, pools } = await createAllCapsules(creditScore)
      if (cores.size === 0 || pools.size === 0) {
        throw new Error('CreditContext: No cores or pools capsules found')
      }

      // If this is not the first time this hook is ran, refresh the capsules
      if (cores.size > 0) {
        await refreshCapsules(
          arcxApi,
          Array.from(cores.values()),
          Array.from(pools.values()),
          account,
          library,
          creditScore,
        )
      }

      setCoreCapsules(cores)
      setPoolCapsules(pools)
    } catch (err) {
      console.error('CreditContext: Error refreshing capsules and credit score', err)
      setCoreCapsules(new Map())
      setPoolCapsules(new Map())
    } finally {
      setIsGlobalRefreshing(false)
    }
  }, [library, account, chainId, arcxApi, creditScore.attributes, creditScore.simpleScore, creditScore.proof])

  const creditContext: ICreditContext = {
    coreCapsules,
    poolCapsules,
    creditScore,
    isGlobalRefreshing,
    refreshAllCapsules: () =>
      refreshCapsules(
        arcxApi,
        Array.from(coreCapsules.values()),
        Array.from(poolCapsules.values()),
        account,
        library,
        creditScore,
      ),
  }

  return <CreditContext.Provider value={creditContext}>{children}</CreditContext.Provider>
}

export const useCreditContext = () => useContext(CreditContext)
