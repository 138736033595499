import html2canvas, { Options } from 'html2canvas'
import { RefObject } from 'react'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const nodeToPNG = async (node: RefObject<any>, html2CanvasOptions?: Partial<Options>) => {
  if (!node.current) {
    throw new Error('Node must be a RefObject')
  }

  const canvas = await html2canvas(node.current, {
    scrollY: -window.scrollY,
    useCORS: true,
    ...html2CanvasOptions,
  })

  const data = canvas.toDataURL('image/png', 1.0)
  return data
}
