import { CapsuleRefreshEmitter } from 'entities'
import { useEffect, useState } from 'react'

export const useCapsulesLoading = (capsules: CapsuleRefreshEmitter[]) => {
  const [loadingStates, setLoadingStates] = useState<Record<string, boolean>>({})

  const onRefreshStarted = (coreAddress: string) => {
    setLoadingStates({
      ...loadingStates,
      [coreAddress]: true,
    })
  }

  const onRefreshFinished = (coreAddress: string) => {
    setLoadingStates({
      ...loadingStates,
      [coreAddress]: false,
    })
  }

  useEffect(() => {
    capsules.forEach((capsule) => {
      capsule.addListener('refreshStarted', onRefreshStarted)
      capsule.addListener('refreshFinished', onRefreshFinished)
    })

    return () => {
      capsules.forEach((capsule) => {
        capsule.removeListener('refreshStarted', onRefreshStarted)
        capsule.removeListener('refreshFinished', onRefreshFinished)
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [capsules])

  return Object.values(loadingStates).find((loading) => loading === true) || false
}
