import { BaseERC20Factory } from '@arcxgame/contracts/src/typings'
import { formatEther } from '@ethersproject/units'
import { useRollbar } from '@rollbar/react'
import { useWeb3React } from '@web3-react/core'
import { useAutoConnectStatus } from 'components/AutoConnect'
import { getConfig } from 'config'
import { useAnalytics, useCreditContext } from 'contexts'
import { BigNumber, providers, utils } from 'ethers'
import { usePassport } from 'hooks'
import { FC, useEffect, useRef } from 'react'
import { useLocation } from 'react-router-dom'
import { PassportMetadata, SimpleScore } from 'types'
import { loadContract } from 'utils'

export const AnalyticsWrapper: FC = ({ children }) => {
  const { account, chainId, active, library } = useWeb3React()
  const { identify, page } = useAnalytics()
  const location = useLocation()
  const rollbar = useRollbar()
  const isAnonymousCalled = useRef<boolean>(false)
  const { isAutoConnecting } = useAutoConnectStatus()
  const { passportMetadata, isLoadingPassport } = usePassport()
  const identifiedAccount = useRef<string | null>(null)

  const params = new URLSearchParams(location.search)
  const sourceRef = useRef(params.get('source'))

  const { creditScore, coreCapsules, isGlobalRefreshing } = useCreditContext()
  const simpleScore = creditScore?.simpleScore

  const pagePath = location.pathname.split('/')[1]

  useEffect(() => {
    // Wait for the auto connection to finish before tracking page views
    if (isAutoConnecting) {
      return
    }
    const sharedProperties = {
      ...(sourceRef.current && { source: sourceRef.current }),
      WalletStatus: account ? 'Connected' : 'Disconnected',
    }
    const properties = { ...sharedProperties, chainId }

    page(pagePath, properties)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pagePath, account, chainId, isAutoConnecting])

  useEffect(() => {
    if (
      isAutoConnecting ||
      isLoadingPassport ||
      isGlobalRefreshing ||
      identifiedAccount.current !== null ||
      isGlobalRefreshing === null
    ) {
      if (isGlobalRefreshing) {
        // If the credit context refreshes globally it means the account or chain ID were changed,
        // so we will reset the identified account to call identify again
        identifiedAccount.current = null
      }

      // Wait for the auto connection to finish before identifying the user
      return
    }

    const identifyUser = async (
      chainId: number,
      account: string,
      passportMetadata: PassportMetadata,
      simpleScore: SimpleScore,
    ) => {
      async function getArcxBalance() {
        try {
          const { address: tokenAddress } = loadContract({
            source: 'ArcxTokenV2',
            network: '1',
          })
          const provider = new providers.JsonRpcProvider(getConfig(1).rpcUrl)
          const token = BaseERC20Factory.connect(tokenAddress, provider)
          return token.balanceOf(account)
        } catch (error) {
          rollbar.warn(
            `AnalyticsWrapper::getArcxBalance: Contracts for Chain ID ${chainId} not found.`,
            error,
          )
        }
      }

      let totalDebt = BigNumber.from(0)
      let totalInterest = BigNumber.from(0)

      for (const coreCapsule of coreCapsules.values()) {
        const { accountProperties } = coreCapsule
        if (accountProperties) {
          totalDebt = totalDebt.add(accountProperties.borrowedAmountScaled)
          totalInterest = totalInterest.add(accountProperties.interestAccrued)
        }
      }

      const [arcxBalance, ethBalance] = await Promise.all([
        getArcxBalance(),
        library.getBalance(account),
      ])

      try {
        const analyticsAttributes = {
          ETHBalance: formatEther(ethBalance),
          ARCxBalance: arcxBalance && formatEther(arcxBalance),
          PassportCreatedTimestamp: passportMetadata.dateIssued || '-',
          PassportCreatedDate: passportMetadata.dateIssued
            ? new Date(passportMetadata.dateIssued).toLocaleDateString('en-UK')
            : '-',
          CreditScore: simpleScore?.score,
          LoanOutstanding: utils.formatEther(totalDebt),
          InterestOutstanding: utils.formatEther(totalInterest),
          chainId,
        }
        identify(account, analyticsAttributes)
      } catch (error) {
        console.error(error)
        rollbar.error(`AnalyticsWrapper: ${error}`)
      }
    }

    if (active && chainId && account && passportMetadata && simpleScore) {
      identifyUser(chainId, account, passportMetadata, simpleScore)
      identifiedAccount.current = account
    } else if (!isAnonymousCalled.current) {
      identify()
      isAnonymousCalled.current = true
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    account,
    chainId,
    active,
    library,
    passportMetadata,
    simpleScore,
    isAutoConnecting,
    isGlobalRefreshing,
  ])

  return <>{children}</>
}
