import styled, { keyframes } from 'styled-components'
import { Breakpoints, Colors } from '../../constants'

const rotate360 = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`

export const Spinner = styled.div`
  animation: ${rotate360} 1s linear infinite;
  transform: translateZ(0);

  border-top: 2px solid ${Colors.GRAY_2};
  border-right: 2px solid ${Colors.GRAY_2};
  border-bottom: 2px solid ${Colors.GRAY_2};
  border-left: 2px solid ${Colors.WHITE};
  border-radius: 50%;
  background: transparent;

  @media only screen and (max-width: ${Breakpoints.MOBILE_S}px) {
    width: 14px;
    height: 14px;
  }

  @media only screen and (min-width: ${Breakpoints.MOBILE_S}px) {
    width: 16px;
    height: 16px;
  }
`
