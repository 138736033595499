import { UnsupportedChainIdError, useWeb3React } from '@web3-react/core'
import { Text } from 'components'
import { rpcUrls } from 'config'
import { providers, Signer } from 'ethers'
import { useAsync, useResponsive } from 'hooks'
import { createContext, useContext, useRef } from 'react'
import styled from 'styled-components'

interface ISupportedChainsWrapper {
  children: React.ReactNode
  supportedChains?: number[]
  functionality: string
}

interface DefaultChainContext {
  chainId: number
}

interface ISupportedWeb3 {
  chainId: number
  provider: providers.JsonRpcProvider
  signer: Signer | undefined
  account: string | undefined
}

export const DefaultChainContext = createContext<DefaultChainContext>({
  chainId: 1,
})

/**
 * This default chain ID will be passed down to the children through the `DefaultChainContext`.
 */
export const SupportedChainsWrapper = ({
  children,
  supportedChains = [1, 137, 5, 80001],
}: ISupportedChainsWrapper) => {
  const { chainId, error, account } = useWeb3React()
  const { width } = useResponsive()
  const isMobile = width < 800
  const isOnUnsupportedChain =
    (error && error instanceof UnsupportedChainIdError) ||
    (account && !supportedChains.includes(chainId))

  return (
    <DefaultChainContext.Provider value={{ chainId: supportedChains[0] }}>
      {isOnUnsupportedChain && (
        <UnsupportedNetworkBanner>
          <Text weight="regular" size="s">
            ARCx Credit is not available on this network. {isMobile && <br />}
            Please switch to one of the supported networks.
          </Text>
        </UnsupportedNetworkBanner>
      )}
      {children}
    </DefaultChainContext.Provider>
  )
}

export const useSupportedWeb3 = (): ISupportedWeb3 => {
  const { chainId, library, account } = useWeb3React<providers.Web3Provider>()
  const { chainId: defaultChainId } = useContext(DefaultChainContext)
  const defaultProviderRef = useRef(new providers.JsonRpcProvider(rpcUrls[defaultChainId]))

  const [signer] = useAsync<Signer>(async () => account && library.getSigner(), [library, account])

  if (account) {
    return { chainId, signer, account, provider: library }
  } else {
    return {
      chainId: defaultChainId,
      provider: defaultProviderRef.current,
      signer: undefined,
      account: undefined,
    }
  }
}

const UnsupportedNetworkBanner = styled.div`
  bottom: 0;
  background-color: #df6c62;
  z-index: 99999;
  padding: 15px;
  width: 100%;
  position: fixed;
  text-align: center;
  left: 0;
  word-wrap: break-word;
`
