import { DuplicateIcon, ExternalLinkIcon, LogoutIcon } from '@heroicons/react/outline'
import { useWeb3React } from '@web3-react/core'
import { DropDownArrow, MetamaskIconAsset, WalletConnectIconAsset } from 'assets'
import { BodyText } from 'components'
import { Colors } from 'constants/colors'
import { useENS, useModal } from 'contexts'
import { constants } from 'ethers'
import { useAsync } from 'hooks'
import { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import { formatAddress, getBlockExplorerAddressLink, getSpacing } from 'utils'
import { useConnect } from '../../hooks'
import { ConnectInterface } from '../ConnectInterface'

const WalletInformationContainer = styled.div`
  background-color: ${Colors.PURPLE_8};
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-radius: 12px;
  cursor: pointer;
  padding: 10px 13px;
  &:hover {
    background-color: ${Colors.PURPLE_7};
  }
`

export const Wallet = () => {
  const { openWith } = useModal()
  const { account, getActiveConnectorName, onDisconnectWalletClicked } = useConnect()
  const { chainId } = useWeb3React()
  const address = account || constants.AddressZero
  const { searchName } = useENS()
  const [isOpen, setIsOpen] = useState(false)
  const ref = useRef<HTMLDivElement>()

  useEffect(() => {
    const checkIfClickedOutside = (e: Event) => {
      if (open && ref.current && !ref.current.contains(e.target as Node)) {
        setIsOpen(false)
      }
    }

    document.addEventListener('mousedown', checkIfClickedOutside)

    return () => {
      document.removeEventListener('mousedown', checkIfClickedOutside)
    }
  }, [isOpen, setIsOpen])

  const [ensAddress] = useAsync(async () => searchName(address), [address], undefined, 'Wallet.tsx')

  let icon
  const connectorName = getActiveConnectorName()
  if (connectorName === 'Metamask') {
    icon = MetamaskIconAsset
  } else if (connectorName === 'WalletConnect') {
    icon = WalletConnectIconAsset
  }

  const onCopyClicked = () => {
    if (!account) return

    navigator.clipboard.writeText(account)
  }

  const onViewExplorerClicked = () => {
    if (!account || !chainId) return
    window.open(getBlockExplorerAddressLink(chainId, account), '_blank')
  }

  const onConnectButtonClicked = () => {
    if (account) {
      setIsOpen(!isOpen)
    } else {
      openWith(<ConnectInterface />)
    }
  }

  return (
    <WalletInformationContainer
      style={{ padding: '12.5px' }}
      onClick={() => onConnectButtonClicked()}
    >
      {icon && <img style={{ width: '22px', marginRight: getSpacing('s') }} src={icon} />}
      <BodyText weight="semi-bold" size="xs">
        {ensAddress || formatAddress(address, 4)}
      </BodyText>
      <img style={{ width: '12px', marginLeft: getSpacing('xs') }} src={DropDownArrow} />
      {isOpen && (
        <DropList ref={ref}>
          <DropItem
            onClick={() => {
              onCopyClicked()
              setIsOpen(false)
            }}
          >
            <DuplicateIcon style={{ height: '30px', marginRight: '10px' }} />
            <span>Copy Address</span>
          </DropItem>
          <DropItem
            onClick={() => {
              onViewExplorerClicked()
              setIsOpen(false)
            }}
          >
            <ExternalLinkIcon style={{ height: '30px', marginRight: '10px' }} />
            <span>View on explorer</span>
          </DropItem>
          <DropItem
            onClick={() => {
              onDisconnectWalletClicked()
              setIsOpen(false)
            }}
          >
            <LogoutIcon style={{ height: '30px', marginRight: '10px' }} />
            <span>Disconnect wallet</span>
          </DropItem>
        </DropList>
      )}
    </WalletInformationContainer>
  )
}

const DropList = styled.div`
  position: absolute;
  top: 6em;
  background-color: ${Colors.PURPLE_8};
  border-radius: 14px;
  @media (max-width: 600px) {
    right: unset;
  }
  z-index: 99999;
`

const DropItem = styled.div`
  font-size: 14px;
  font-weight: 400;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px 14px;
  border-radius: 14px;
  width: auto;
  cursor: pointer;
  transition: 0.15s;

  &:hover {
    background-color: white;
    color: ${Colors.PURPLE_10};
  }
`
