import { BigNumberish, utils } from 'ethers'

export function formatBigNumber(value?: BigNumberish, precision = 2, decimals = 18) {
  if (!value) {
    return '0'
  }
  const formattedValue = cutZeroFromInteger(
    cutPrecision(utils.formatUnits(value, decimals), precision),
  )
  if (Number(formattedValue) >= 1000) {
    return numberWithCommas(formattedValue)
  }
  return formattedValue
}

export function cutZeroFromInteger(value: string) {
  const splitValue = value.split('.')
  return splitValue[1] === '0' ? splitValue[0] : value
}

export function cutPrecision(value: string, precision: number) {
  const splitValue = value.split('.')

  if (precision === 0) {
    return splitValue[0]
  }

  let decimalPart = splitValue[1]?.slice(0, precision)

  if (!decimalPart) {
    decimalPart = Array(precision).fill('0').join('')
  } else if (decimalPart.length < precision) {
    decimalPart += Array(precision - decimalPart.length)
      .fill('0')
      .join('')
  }

  return `${splitValue[0]}.${decimalPart}`
}

/**
 * Adds thousand separator. Taken from
 * https://stackoverflow.com/questions/2901102/how-to-print-a-number-with-zcommas-as-thousands-separators-in-javascript
 */
export function numberWithCommas(x: string) {
  const parts = x.toString().split('.')
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  return parts.join('.')
}
