import { Box, Text, TextSize, Tooltip } from 'components'
import { ColorType } from 'constants/colors'
import { getSpacing } from 'utils'

interface IMetricComponentProps {
  title: string
  value: string
  alignItems?: 'flex-start' | 'flex-end'
  tooltip?: string
  valueColor?: ColorType
  futureValue?: string
  futureValueColor?: ColorType
  labelSize?: TextSize
}

export const MetricComponent = ({
  title,
  value,
  tooltip,
  valueColor,
  alignItems,
  futureValue,
  futureValueColor,
  labelSize = 's',
}: IMetricComponentProps) => {
  return (
    <Box flexDirection="column" paddingRight={getSpacing('s')} alignItems={alignItems}>
      <Box flexDirection="row" alignItems={'flex-end'}>
        <Text size={labelSize} weight="thin" color="TEXT_DARK">
          {title}
        </Text>
        {tooltip && (
          <Box marginLeft={getSpacing('xs')}>
            <Tooltip content={tooltip} />
          </Box>
        )}
      </Box>
      <Box flexDirection="row" marginTop={getSpacing('xs')}>
        <Text overwriteSize={18} weight="regular" color={valueColor || 'WHITE'}>
          {value}
        </Text>
        {futureValue && (
          <Box marginLeft={getSpacing('xs')}>
            <Text overwriteSize={18} weight="regular" color={futureValueColor || 'WHITE'}>
              {`-> ${futureValue}`}
            </Text>
          </Box>
        )}
      </Box>
    </Box>
  )
}
