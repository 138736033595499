import { useWeb3React } from '@web3-react/core'
import { Box, Spinner } from 'components'
import { providers } from 'ethers'
import { usePassport } from 'hooks'
import { useEffect, useState } from 'react'
import { Redirect, Route, Switch } from 'react-router'
import { useRouteMatch } from 'react-router-dom'
import { PassportClaim } from './PassportClaim'
import { PassportUpdate } from './PassportUpdate'

type PassportState = 'Loading' | 'Claim' | 'Update' | 'Passport'

export interface PassportRoutesProps {
  address?: string
  protocol?: string
}

export const Passport = () => {
  const match = useRouteMatch<PassportRoutesProps>()
  const [passportState, setPassportState] = useState<PassportState>('Loading')
  const { account } = useWeb3React<providers.Web3Provider>()
  const isMe = match.params.address === 'me'
  const { passportMetadata } = usePassport(isMe ? account : match.params.address ?? account)

  useEffect(() => {
    const hasPassport = passportMetadata && passportMetadata.status === 'active'
    const hasAddress = account || (!isMe && match.params.address)

    if (!passportMetadata && hasAddress) {
      setPassportState('Loading')
    } else if (!hasPassport || !hasAddress) {
      setPassportState('Claim')
    } else if (hasPassport) {
      setPassportState('Passport')
    } else {
      throw Error('invalid passport state')
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account, passportMetadata, match.params.address])

  switch (passportState) {
    case 'Loading':
      return (
        <Box
          style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'transform: translate(-50%, -50%)',
          }}
        >
          <Spinner />
        </Box>
      )
    case 'Claim':
      if (match.params.address) {
        return <Redirect to="/passport" />
      }

      return (
        <Box alignItems="center" justifyContent="center">
          <PassportClaim />
        </Box>
      )
    case 'Passport':
      return (
        <Switch>
          <Route path={['/passport/:address', '/passport/:address/:protocol']} exact>
            {/* if we don't have an address router will pick another route */}
            {/* eslint-disable-next-line @typescript-eslint/no-non-null-assertion */}
            <PassportUpdate address={match.params.address!} />
          </Route>
          <Route path={match.path}>{account && <Redirect to={`${match.path}/me`} />}</Route>
        </Switch>
      )
    default:
      throw new Error('Invalid state in Passport')
  }
}
