import {
  DiscordFooterIcon,
  PoweredByPoly,
  SubstackFooterIcon,
  TwitterFooterIcon,
  YouTubeFooterIcon,
} from 'assets'
import { AppNavigationLinkItem } from 'components/AppNavigation/components/AppNavigationLinkItem'
import { ILink } from 'components/AppNavigation/types'
import { useAnalytics } from 'contexts'
import { Link as NavigationLink, Route } from 'react-router-dom'
import { Link, Statistics } from '../../components'

type TrackedLink = {
  name: string
  url: string
}

export const AppFooter = () => {
  const { page } = useAnalytics()
  const links: ILink[] = [
    {
      title: 'Passport',
      to: '/passport',
    },
    {
      title: 'Staking',
      to: '/staking',
    },
    {
      title: 'Careers',
      to: 'https://arcx.notion.site/Careers-b5b62a746c0c4b9eb98f1a6a102a0a80',
      external: true,
    },
  ]

  const footerIcons = [
    {
      name: 'Discord',
      url: 'https://discord.gg/arcx',
      src: DiscordFooterIcon,
    },
    {
      name: 'Twitter',
      url: 'https://twitter.com/arcxmoney',
      src: TwitterFooterIcon,
    },
    {
      name: 'Substack',
      url: 'https://arcx.substack.com',
      src: SubstackFooterIcon,
    },
    {
      name: 'YouTube',
      url: 'https://www.youtube.com/channel/UCxzWjsNQHrfa96wIfqk4vyw',
      src: YouTubeFooterIcon,
    },
  ]

  const trackPageClick = ({ name, url }: TrackedLink) => {
    page(name.toLowerCase(), {
      url,
    })
  }

  return (
    <div className="sm:flex-row sm:mx-[16px] sm:gap-6 sm:mx-16 sm:justify-between sm:items-center sm:my-12 flex flex-col gap-6 mb-6">
      <div className="sm:order-last">
        <Route path={['/borrow', '/supply', '/rankings']}>
          <Statistics />
        </Route>
      </div>
      <div className="flex items-center gap-6 justify-center">
        {links.map((link, key) => (
          <div key={`fotterLink_${key}`}>
            {link.external ? (
              <Link
                overwriteSize={18}
                externalURL={link.to}
                color={'GRAY_7'}
                onClick={() => trackPageClick({ name: link.title, url: link.to })}
              >
                {link.title}
              </Link>
            ) : (
              <NavigationLink to={link.to} style={{ textDecoration: 'none' }}>
                <AppNavigationLinkItem link={link} />
              </NavigationLink>
            )}
          </div>
        ))}
      </div>
      <div className="sm:order-2 sm:content-start sm:flex-grow">
        <a href="https://polygon.technology" target="_blank" rel="noreferrer">
          <img
            src={PoweredByPoly}
            alt="Powered by Polygon"
            className="h-6 sm:min-w-min mx-auto sm:mx-0"
          />
        </a>
      </div>
      <div className="flex justify-center gap-6 sm:justify-start">
        {footerIcons.map((iconInfo, iconIndex) => (
          <div
            key={`footerIcon_${iconIndex}`}
            onClick={() => trackPageClick({ name: iconInfo.name, url: iconInfo.url })}
          >
            <a
              href={iconInfo.url}
              target="_blank"
              rel="noopener noreferrer"
              className="hover:cursor-pointer"
            >
              <img src={iconInfo.src} className="sm:h-5" />
            </a>
          </div>
        ))}
      </div>
    </div>
  )
}
