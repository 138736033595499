import { Contract as EthersContract } from 'ethers'
import { Contract as MulticallContract, Provider } from 'ethers-multicall'
import { FormatTypes } from 'ethers/lib/utils'

type FunctionCall = [string, string[]] | string

export async function ethMulticaller<T extends unknown[]>(
  contract: EthersContract,
  functionCalls: FunctionCall[],
) {
  const ethcallProvider = new Provider(contract.provider)
  await ethcallProvider.init()

  const abi = contract.interface.format(FormatTypes.full) as string[]
  const contractMulticall = new MulticallContract(contract.address, abi)

  const res = await ethcallProvider.all<T>(
    functionCalls
      .filter((functionCall) => !!functionCall) // filter out empty calls
      .map((functionCall) => {
        if (typeof functionCall === 'string') {
          return contractMulticall[functionCall]()
        } else {
          return contractMulticall[functionCall[0]](...functionCall[1])
        }
      }),
  )

  return res
}
