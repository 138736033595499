import { Text } from 'components'
import { Colors } from 'constants/colors'

import { useState } from 'react'
import styled from 'styled-components'

const StyledCopyButton = styled.button`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 5px 8px;
  border: none;
  cursor: pointer;
  width: 46px;
  height: 27px;
  background: ${Colors.WHITE_10};
  border-radius: 8px;
`

interface ICopyButton {
  address: string
}

export const CopyButton = ({ address }: ICopyButton) => {
  const [hover, setHover] = useState(false)

  function copyToClipboard() {
    navigator.clipboard.writeText(address)
  }

  return (
    <StyledCopyButton
      style={{ backgroundColor: hover ? Colors.WHITE : Colors.WHITE_10 }}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      onClick={copyToClipboard}
    >
      <Text size="xs" color={hover ? 'GRAY_8' : 'WHITE_50'}>
        Copy
      </Text>
    </StyledCopyButton>
  )
}
