import { asObject, asOptional, asString } from '@restless/sanitizers'
import { asScoreMetadata, ScoreMetadata } from './scoreMetadata'
import { SimpleScore } from './simpleScore'

export type FullScore = SimpleScore & {
  metadata: ScoreMetadata
}

export const asFullScore = asObject<FullScore>({
  account: asString,
  protocol: asString,
  score: asString,
  metadata: asOptional(asScoreMetadata),
})
