import { ExclamationIcon, HeartIcon, InformationCircleIcon } from '@heroicons/react/solid'
import { configConstants } from 'config'
import { Colors } from 'constants/colors'
import { CoreCapsuleWithAccount } from 'entities'
import { BigNumber, utils } from 'ethers'

export function getGrowthBarDetails(
  borrowUsage: BigNumber,
  coreCapsules: CoreCapsuleWithAccount[],
) {
  let suggestionIcon: JSX.Element
  let suggestionText: string
  let creditGrowth: string

  const coresAtRisk: CoreCapsuleWithAccount[] = coreCapsules.filter(
    (coreCapsule) => coreCapsule.accountProperties?.borrowUsage > configConstants.atRiskThreshold,
  )

  if (coresAtRisk.length > 0) {
    const vaultTitle =
      coresAtRisk.length === 1
        ? `your ${coresAtRisk[0].creditLimitAttributes.title} vault is`
        : 'multiple vaults are'
    suggestionIcon = <ExclamationIcon width={'24px'} fill={'ORANGE'} />
    suggestionText = `Warning, ${vaultTitle} at risk of liquidation. Add collateral or repay your loan.`
    creditGrowth = 'Not Growing'
  } else if (borrowUsage.gt(configConstants.atRiskThreshold)) {
    throw new Error(
      `IMPOSSIBLE STATE: if borrow usage is above ${configConstants.atRiskThreshold}, There MUST be a core at risk.`,
    )
  } else if (borrowUsage.gt(utils.parseEther('0.7'))) {
    creditGrowth = 'Slow'
    suggestionIcon = <InformationCircleIcon width={'24px'} />
    suggestionText = 'Reduce your borrow usage to accelerate your credit score growth.'
  } else if (borrowUsage.gt(utils.parseEther('0.5'))) {
    suggestionIcon = <HeartIcon width={'24px'} color={Colors.GREEN_5} fill={Colors.GREEN_5} />
    suggestionText = 'Maintain your borrow usage to optimally grow your credit score.'
    creditGrowth = 'Optimal'
  } else if (borrowUsage.gt(utils.parseEther('0.25'))) {
    suggestionIcon = <InformationCircleIcon width={'24px'} />
    suggestionText = 'Increase your borrow usage to accelerate your credit score growth'
    creditGrowth = 'Moderate'
  } else if (borrowUsage.gt(0)) {
    suggestionIcon = <InformationCircleIcon width={'24px'} />
    suggestionText = 'Increase your borrow usage to accelerate your credit score growth'
    creditGrowth = 'Slow'
  } else {
    suggestionIcon = <InformationCircleIcon width={'24px'} />
    suggestionText = 'Start borrowing to build your credit score.'
    creditGrowth = 'Not Growing'
  }

  return {
    suggestionText,
    suggestionIcon,
    creditGrowth,
  }
}
