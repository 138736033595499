import { cast } from '@restless/sanitizers'
import { useWeb3React } from '@web3-react/core'
import { BodyText, Box, Button, Spinner, Text, Title, TwitterButton } from 'components'
import { Colors } from 'constants/colors'
import { useAnalytics, useModal } from 'contexts'
import { useArcxApi, useAsync, useResponsive } from 'hooks'
import React, { ReactNode, useRef } from 'react'
import { useHistory } from 'react-router-dom'
import { asScoreMetadata } from 'types'
import { getIconUrlFromGCP, getSpacing, nodeToPNG } from 'utils'
import { ArcxApi } from '../../entities'
import { CloseButton } from './CloseButton'
import { DistributionCard } from './DistributionCard'
import { DownloadButton } from './DownloadButton'
import { ExplanationsList } from './ExplanationsList'
import { LearnMore } from './LearnMore'
import { ScoreListItemProps } from './ScoreListItem'
import { SourceIcon } from './SourceIcon'

const TWITTER_SHARE_LINK = 'https://twitter.com/intent/tweet'
const MODAL_BACKGROUND_COLOR = '#110f18'

interface ScoreModalMobileProps extends ScoreListItemProps {
  arcxApi: ArcxApi
}

const ScoreModalMobile = (props: ScoreModalMobileProps) => {
  const { simpleScore, metadata, attributes, arcxApi } = props
  const { percentile, explanations } = cast(metadata, asScoreMetadata)
  const { title, name, description, sources } = attributes
  const modalRef = useRef(null)
  const { account } = useWeb3React()
  const history = useHistory()
  const { closeModal } = useModal()
  const { track } = useAnalytics()

  return (
    <div ref={modalRef}>
      <Box
        width="90vw"
        borderRadius="12px"
        style={{
          backgroundColor: MODAL_BACKGROUND_COLOR,
        }}
      >
        <Box padding="25px">
          <Box
            flexDirection="row"
            justifyContent="space-between"
            alignItems="center"
            marginBottom={getSpacing('s')}
          >
            <Box alignItems="center" flexDirection="row">
              <img src={getIconUrlFromGCP(name)} style={{ width: '38px', marginRight: '8px' }} />
              <Title size="m" weight="semi-bold" tag="h3">
                {title}
              </Title>
            </Box>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
              }}
              id="ignore-snapshot"
            >
              <TwitterButton
                onClick={() => {
                  onTwitterBtnClicked(modalRef, arcxApi, title)
                  track('ScoreShared', {
                    ProtocolName: name,
                    ScoreName: title,
                    ScoreReceived: simpleScore.score,
                    WalletAddress: account,
                  })
                }}
              />
              <Box marginLeft="4px">
                <CloseButton />
              </Box>
            </div>
          </Box>
          <BodyText
            weight="medium"
            style={{ color: Colors.WHITE_50, marginBottom: '24px' }}
            size="s"
          >
            {description}
          </BodyText>
          <Box marginBottom="24px" alignItems="center">
            <DistributionCard
              scoreAttributes={attributes}
              percentile={percentile}
              score={simpleScore.score}
            />
          </Box>
          <Box marginBottom="24px" alignItems="center">
            <Box width="230px">
              <Button
                onClick={() => {
                  history.push(`/rankings/${name}`)
                  closeModal()
                }}
                variant="secondary"
                size="s"
                text="View Rankings"
              />
            </Box>
          </Box>
          <ExplanationsList explanations={explanations} />
          <Box
            marginTop={getSpacing('s')}
            flexDirection="row"
            alignItems="center"
            justifyContent="center"
          >
            <Text weight="medium" style={{ color: Colors.WHITE_50 }} size="s">
              Indexed On
            </Text>
            {sources.map((s, i) => (
              <Box paddingLeft="10px" key={`source_${i}`}>
                <SourceIcon source={s} />
              </Box>
            ))}
          </Box>
        </Box>
      </Box>
    </div>
  )
}

export const ScoreModal = (props: ScoreListItemProps) => {
  const { simpleScore, metadata, attributes, isOtherAccount } = props
  const { title, name, description, sources, externalUri } = attributes
  const { percentile, explanations } = cast(metadata, asScoreMetadata)
  const modalRef = useRef(null)
  const { width } = useResponsive()
  const arcxApi = useArcxApi()
  const { account, chainId } = useWeb3React()
  const history = useHistory()
  const { closeModal } = useModal()
  const { track } = useAnalytics()
  const [scoreAttributes] = useAsync(async () => arcxApi?.getScoreAttributes(name), [arcxApi])

  const onDownloadClicked = async () => {
    const modalData = await nodeToPNG(modalRef, {
      backgroundColor: null,
      ignoreElements: (element) => element.id === 'ignore-snapshot',
    })

    const link = document.createElement('a')

    link.href = modalData
    link.download = `${title}.png`
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }

  if (!scoreAttributes || !arcxApi) {
    return <Spinner />
  } else if (width < 800) {
    return <ScoreModalMobile {...props} arcxApi={arcxApi} />
  } else {
    return (
      <div ref={modalRef}>
        <Box
          padding="25px"
          paddingBottom="22px"
          borderRadius="28px"
          style={{
            backgroundColor: MODAL_BACKGROUND_COLOR,
            width: '750px',
          }}
        >
          <Box flexDirection="row" justifyContent="space-between" alignItems="center">
            <DistributionCard
              scoreAttributes={scoreAttributes}
              score={simpleScore.score}
              percentile={percentile}
            />
            <Box style={{ width: '100%' }} marginLeft={getSpacing('xl')}>
              <Box marginLeft="6px">
                <Box
                  flexDirection="row"
                  alignItems="center"
                  justifyContent="space-between"
                  marginBottom={getSpacing('xs')}
                >
                  <Box flexDirection="row" alignItems="center">
                    <img
                      src={getIconUrlFromGCP(name)}
                      style={{ width: '38px', marginRight: '10px' }}
                    />
                    <Title size="l" weight="semi-bold" tag="h3">
                      {title}
                    </Title>
                  </Box>
                  <Box flexDirection="row" alignItems="center">
                    {!isOtherAccount && (
                      <>
                        <div id="ignore-snapshot">
                          <Box marginRight={getSpacing('xs')}>
                            <DownloadButton
                              onClick={() => {
                                track('ScoreDownloaded', {
                                  ProtocolName: name,
                                  ScoreName: title,
                                  ScoreReceived: simpleScore,
                                  WalletAddress: account,
                                })
                                onDownloadClicked()
                              }}
                            />
                          </Box>
                        </div>
                        <div id="ignore-snapshot">
                          <TwitterButton
                            onClick={() => {
                              onTwitterBtnClicked(modalRef, arcxApi, title)
                              track('ScoreShared', {
                                ProtocolName: name,
                                ScoreName: title,
                                ScoreReceived: simpleScore.score,
                                WalletAddress: account,
                              })
                            }}
                          />
                        </div>
                      </>
                    )}
                  </Box>
                </Box>
                <BodyText
                  weight="medium"
                  style={{ color: Colors.WHITE_50, marginBottom: '16px' }}
                  size="s"
                >
                  {description}
                </BodyText>
              </Box>
              <ExplanationsList
                explanations={explanations.length > 0 ? explanations : attributes.defaults}
              />
            </Box>
          </Box>
          <Box
            margin={`${getSpacing('s')} ${getSpacing('xs')} 0px 0px`}
            flexDirection="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Box flexDirection="row" alignItems="center">
              <div id="ignore-snapshot">
                <Box width="230px">
                  <Button
                    onClick={() => {
                      history.push(`/rankings/${name}`)
                      closeModal()
                    }}
                    variant="secondary"
                    size="s"
                    text="View Rankings"
                  />
                </Box>
              </div>
            </Box>
            <Box flexDirection="row" alignItems="center">
              <Text weight="medium" style={{ color: Colors.WHITE_50 }} size="s">
                Indexed On
              </Text>
              {sources.map((s, i) => (
                <Box paddingLeft="10px" key={`source_${i}`}>
                  <SourceIcon source={s} />
                </Box>
              ))}
              <div id="ignore-snapshot">
                {externalUri && externalUri !== '' && (
                  <Box marginLeft={getSpacing('s')}>
                    <Box
                      onClick={() => {
                        track('ScoreLearnMoreClicked', {
                          ProtocolName: name,
                          ScoreName: title,
                          ScoreReceived: simpleScore.score,
                          WalletAddress: account,
                          chainId,
                        })
                      }}
                    >
                      <LearnMore externalUri={externalUri} />
                    </Box>
                  </Box>
                )}
              </div>
            </Box>
          </Box>
        </Box>
      </div>
    )
  }
}

async function onTwitterBtnClicked(
  modalRef: React.MutableRefObject<ReactNode>,
  arcxApi: ArcxApi,
  scoreTitle: string,
) {
  const modalData = await nodeToPNG(modalRef, {
    backgroundColor: MODAL_BACKGROUND_COLOR,
    ignoreElements: (element) => element.id === 'ignore-snapshot',
  })

  const twitterImgLink = await arcxApi.getShareToTwitterURL(modalData)

  const link = `${TWITTER_SHARE_LINK}?url=${twitterImgLink}&text=Check out my ${scoreTitle}! @arcxmoney`

  window.open(link, '_blank', 'noopener')
}
