import { asArray, asNumber, asObject, asString } from '@restless/sanitizers'
import axios from 'axios'
import {
  asFullScore,
  asPassportMetadata,
  asScoreAttributes,
  asScoreHistory,
  asScoresResponse,
  asScoreWithProof,
  asSimpleScore,
  asSkinMetadata,
  CompleteScore,
  FullScore,
  PendingImpactInput,
  ScoreAttributes,
  ScoreWithProof,
  SimpleScore,
} from 'types'
import { extendedCast, handleHttpResponse, sortByTitle } from 'utils'

export class ArcxApi {
  constructor(private arcxUrl: string, private defaultChainId: string) {}

  async getScore(
    account: string,
    protocol: string,
    format: 'full' | 'proof' | 'simple' = 'full',
  ): Promise<FullScore | ScoreWithProof | SimpleScore | undefined> {
    const response = await this.fetchFromApi(
      `/v1/scores?format=${format}&account=${account}&protocol=${protocol}`,
    )

    if (response.total === 0) {
      return
    }

    if (format === 'full') {
      return extendedCast(response.data[0], asFullScore, 'FullScore')
    } else if (format === 'proof') {
      return extendedCast(response.data[0], asScoreWithProof, 'ScoreWithProof')
    } else {
      return extendedCast(response.data[0], asSimpleScore, 'SimpleScore')
    }
  }

  async getScoreHistory(account: string, protocol: string) {
    const response = await this.fetchFromApi(
      `/history/${account}/${protocol}`,
      this.getCrossChainFetchOptions(),
    )

    return extendedCast(response, asScoreHistory, 'Score History')
  }

  async getContractScoreProof(
    account: string,
    protocol: string,
  ): Promise<ScoreWithProof | undefined> {
    const score = await this.getScore(account, protocol, 'proof')
    if (!score?.score) return

    return extendedCast(score, asScoreWithProof, 'Contract Score Proof')
  }

  async getScoresByAccount(account: string): Promise<FullScore[]> {
    const response = await this.fetchFromApi(`/v1/scores?account=${account}&format=full`)

    return extendedCast(response.data, asArray(asFullScore), 'FullScores')
  }

  async getAllScoreAttributes(): Promise<ScoreAttributes[]> {
    const response = await this.fetchFromApi('/v1/protocols', this.getCrossChainFetchOptions())
    const protocolStats = extendedCast(response, asProtocolsStats, 'All Protocol Stats')
    return protocolStats.data
  }

  async getScoreAttributes(protocol: string): Promise<ScoreAttributes> {
    const response = await this.fetchFromApi(
      `/v1/protocols?names=${protocol}`,
      this.getCrossChainFetchOptions(),
    )

    const protocolStats = extendedCast<ScoreAttributes>(
      response.data[0],
      asScoreAttributes,
      'Protocol Stats',
    )
    return protocolStats
  }

  async getScoresWithAttributes(account: string) {
    type CustomReturnType = Required<Pick<CompleteScore, 'simpleScore' | 'metadata' | 'attributes'>>
    const res: CustomReturnType[] = []

    const [userScores, scoresAttributes] = await Promise.all([
      this.getScoresByAccount(account),
      this.getAllScoreAttributes(),
    ])

    scoresAttributes.forEach((attributes) => {
      const score = userScores.find((score) => score.protocol === attributes.name)

      let scoreWithAttributes: CustomReturnType

      if (!score) {
        scoreWithAttributes = {
          simpleScore: {
            score: '',
            account,
            protocol: attributes.name,
          },
          metadata: {
            rank: 0,
            percentile: 0,
            explanations: [],
          },
          attributes,
        }
      } else {
        scoreWithAttributes = {
          simpleScore: {
            score: score.score,
            account,
            protocol: attributes.name,
          },
          metadata: score.metadata,
          attributes,
        }
      }

      res.push(scoreWithAttributes)
    })

    return res
  }

  async getPassportMetadata(account: string) {
    const response = await this.fetchFromApi(`/passports/${account}`)
    return extendedCast(response, asPassportMetadata, 'Passport Metadata')
  }

  async getOwnedSkins(account: string) {
    const response = await this.fetchFromApi(`/passports/ownedSkins/${account}`)
    return extendedCast(response, asArray(asSkinMetadata), 'Owned Skins')
  }

  async getAllSkins() {
    const response = await this.fetchFromApi('/skins')
    const allSkins = extendedCast(response, asArray(asSkinMetadata), 'Skin Metadata')
    return sortByTitle(allSkins)
  }

  /**
   * Posts the given image to twitter and returns an URL to pass to the
   * Twitter share button
   * @param data Data of image to share
   */
  async getShareToTwitterURL(data: string) {
    const response = await this.fetchFromApi('/twitter/get-share-to-twitter-url', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        imageData: data,
      }),
    })

    return extendedCast(response, asString, 'Twitter URL')
  }

  async postClaimPassport(args: SignatureRequest): Promise<boolean> {
    const headers = this.getCrossChainFetchOptions()
    const res = await axios.post(`${this.arcxUrl}/passports/claim`, args, headers)

    return res.status === 201
  }

  async postChangeSkin(args: SignatureRequest): Promise<boolean> {
    const res = await axios.post(
      `${this.arcxUrl}/passports/apply-skin`,
      args,
      this.getCrossChainFetchOptions(),
    )
    return res.status === 201
  }

  async postPendingImpactTx(args: PendingImpactInput) {
    const res = await axios.post(
      `${this.arcxUrl}/borrow/submit-pending-impact`,
      args,
      this.getCrossChainFetchOptions(),
    )
    return res.status === 201
  }

  async getScoresByProtocol(protocol: string, limit = 25, offset = 0) {
    const response = await this.fetchFromApi(
      `/v1/scores?format=full&protocol=${protocol}&limit=${limit}&offset=${offset}&sortBy=rank`,
    )
    return extendedCast(response, asScoresResponse, `getScoresByProtocol ${protocol}`)
  }

  //
  // Private methods
  //

  private async fetchFromApi(url: string, args?: RequestInit) {
    const chainHeaders = this.getCrossChainFetchOptions()

    return fetch(
      `${this.arcxUrl}${url}`,
      args ? { ...args, headers: { ...args.headers, ...chainHeaders.headers } } : chainHeaders,
    ).then(handleHttpResponse)
  }

  private getCrossChainFetchOptions() {
    return {
      headers: {
        chainid: this.defaultChainId,
      },
    }
  }
}

interface SignatureRequest {
  token: string
  message: string
  signature: string
  account: string
}

const asProtocolsStats = asObject({
  total: asNumber,
  current: asNumber,
  data: asArray(asScoreAttributes),
})
