export type SpacingType = 'xxxxl' | 'xxxl' | 'xxl' | 'xl' | 'l' | 'm' | 's' | 'xs'

type SpacingsType = {
  [key in SpacingType]: number
}

const base = 8

const Spacing: SpacingsType = {
  xxxxl: base * 8,
  xxxl: base * 7,
  xxl: base * 6,
  xl: base * 5,
  l: base * 4,
  m: base * 3,
  s: base * 2,
  xs: base,
}

export function getSpacing(size: SpacingType = 's'): string {
  return `${Spacing[size]}px`
}
