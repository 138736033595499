import { getEnv } from 'utils'
import { sharedConfig } from './config.shared'
import { IConfig } from './IConfig'

const config: IConfig = {
  ...sharedConfig,
  rpcUrl: `https://polygon-mumbai.g.alchemy.com/v2/${getEnv(
    'REACT_APP_MUMBAI_ALCHEMY_KEY',
    'vguOCzG8QRyrOTWcQyhgumeeYgwZDQKa',
  )}`,
  farms: [],
}

export default config
