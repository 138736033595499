import { Passport } from '@arcxmoney/passport-js'
import { useWeb3React } from '@web3-react/core'
import { ArcPassportCard, Box, Button, Link, Spinner, Text, Title } from 'components'
import { AnimationToggle } from 'components/AppNavigation/components/AnimationToggle'
import { ProfileCircle } from 'components/ProfileCircle'
import { ScoreList } from 'components/ScoreList'
import { getConfig } from 'config'
import { useENS } from 'contexts'
import { useAnimationMode, useAsync, useConfig, usePassport, useResponsive } from 'hooks'
import { useEffect, useRef } from 'react'
import { useHistory } from 'react-router'
import { PassportMetadata } from 'types'
import { eqIgnoreCase, formatAddress, getSpacing } from 'utils'
import { CopyButton } from './CopyButton'

interface PassportUpdateProps {
  address: string
}

interface PassportUpdateMobileProps extends PassportUpdateProps {
  backendUrl: string
  viewingOwnerAccount: boolean
  displayAddress: string
  isMe: boolean
  passportMetadata: PassportMetadata
}
const PassportUpdateMobile = ({
  address,
  backendUrl,
  viewingOwnerAccount,
  displayAddress,
  isMe,
  passportMetadata,
}: PassportUpdateMobileProps) => {
  const { chainId } = useWeb3React()
  const { width } = useResponsive()
  const { showAnimation } = useAnimationMode()

  return (
    <Box>
      <Box
        marginBottom={getSpacing('m')}
        justifyContent="center"
        alignItems="center"
        flexDirection="row"
      >
        <ProfileCircle width={70} />
        <Box marginLeft={getSpacing('s')}>
          <Box flexDirection="row" alignItems="center">
            <Title size="xl" tag="h1" weight="semi-bold">
              {displayAddress}
            </Title>
            {isMe && (
              <Text
                style={{ marginLeft: getSpacing('s') }}
                color="WHITE_50"
                weight="medium"
                size="xs"
              >
                You
              </Text>
            )}
          </Box>
          <Box flexDirection="row" marginTop={getSpacing('xs')}>
            <Text color="WHITE_80" weight="medium" size="s">
              {formatAddress(address)}
            </Text>
            <Box marginLeft={getSpacing('xs')}>
              <CopyButton address={address} />
            </Box>
          </Box>
        </Box>
      </Box>
      {showAnimation ? (
        <Box flexDirection="row" justifyContent="center">
          <Passport
            account={address}
            width={width * 0.75}
            height={width * 0.75}
            position="flex"
            closable={false}
            chainId={chainId}
            arcxUrl={backendUrl}
            showSpinner
          />
        </Box>
      ) : (
        <Box justifyContent="center" flexDirection="row" marginBottom={getSpacing('s')}>
          {passportMetadata && passportMetadata.activeSkin && (
            <ArcPassportCard
              size={200}
              src={
                passportMetadata.imageUrlFront ||
                `${getConfig(1).backendUrl}/skins/image/arcx/purple`
              }
            />
          )}
        </Box>
      )}
      <Box
        marginBottom={getSpacing('l')}
        flexDirection="row"
        justifyContent="center"
        alignItems="center"
      >
        <Box width="200px">
          <Link to="/skins">
            <Button size="s" variant="secondary" text="Change Skin" fullWidth />
          </Link>
        </Box>
        <Box marginLeft="8px">
          <AnimationToggle />
        </Box>
      </Box>
      <ScoreList account={address} isOtherAccount={!viewingOwnerAccount} />
    </Box>
  )
}

/**
 * Displays the passport page for the given address. If the wallet is not
 * connected, display the mainnet data. Otherwise, use the current chain ID.
 */
export const PassportUpdate = ({ address: addressInURL }: PassportUpdateProps) => {
  const history = useHistory()
  const { account } = useWeb3React()
  const { accountToUse, chainId, viewingOwnerAccount, ensName, displayAddress, isMe } =
    usePassportUpdate(addressInURL)
  const { passportMetadata } = usePassport(accountToUse)
  const { showAnimation } = useAnimationMode()
  const { width } = useResponsive()
  const { backendUrl } = useConfig()

  if (passportMetadata && passportMetadata.status === 'unclaimed') {
    history.push('/passport')
  }

  if (!passportMetadata || !accountToUse || !displayAddress) {
    return (
      <Box
        style={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'transform: translate(-50%, -50%)',
        }}
      >
        <Spinner />
      </Box>
    )
  }

  // On load, width is 0 which causes the PassportUpdateMobile to render and make a
  // useless api call. Setting width > 0 will prevent that
  if (width < 800 && width > 0) {
    return (
      <PassportUpdateMobile
        address={accountToUse}
        backendUrl={backendUrl}
        passportMetadata={passportMetadata}
        isMe={isMe}
        viewingOwnerAccount={viewingOwnerAccount}
        displayAddress={displayAddress}
      />
    )
  }

  return (
    <Box style={{ justifyContent: 'center' }} flexDirection="row">
      <Box marginRight={getSpacing('xxxl')}>
        {showAnimation ? (
          <Passport
            account={accountToUse}
            width={335}
            height={335}
            position="flex"
            closable={false}
            chainId={chainId}
            arcxUrl={backendUrl}
            showSpinner
          />
        ) : (
          <Box alignItems="center" marginBottom={getSpacing('s')}>
            <ArcPassportCard
              size={200}
              src={
                passportMetadata?.imageUrlFront ||
                `${getConfig(1).backendUrl}/skins/image/arcx/purple`
              }
            />
          </Box>
        )}
        <Box flexDirection="row" justifyContent="center" alignItems="center">
          <Box width="175px">
            <Link to="/skins">
              <Button
                size="s"
                variant="secondary"
                text="Change Skin"
                disabled={!eqIgnoreCase(addressInURL, account) && addressInURL !== 'me'}
                fullWidth
              />
            </Link>
          </Box>
          <Box marginLeft="8px">
            <AnimationToggle />
          </Box>
        </Box>
      </Box>
      <Box>
        <Box padding={`0px 0px 0px ${getSpacing('s')}`}>
          <Box marginBottom={getSpacing('m')} alignItems="center" flexDirection="row">
            <ProfileCircle width={70} />
            <Box marginLeft={getSpacing('s')}>
              <Box flexDirection="row" alignItems="center">
                <Title size="xl" tag="h1" weight="semi-bold">
                  {displayAddress}
                </Title>
                {(addressInURL === 'me' || addressInURL === account) && (
                  <Text
                    style={{ marginLeft: getSpacing('s') }}
                    color="WHITE_50"
                    weight="medium"
                    size="xs"
                  >
                    You
                  </Text>
                )}
              </Box>
              <Box flexDirection="row" marginTop={getSpacing('xs')}>
                {!!ensName && (
                  <Box marginRight={getSpacing('xs')}>
                    <Text color="WHITE_80" weight="medium" size="s">
                      {formatAddress(accountToUse)}
                    </Text>
                  </Box>
                )}
                <CopyButton address={accountToUse} />
              </Box>
            </Box>
          </Box>
        </Box>
        <ScoreList account={accountToUse} isOtherAccount={!viewingOwnerAccount} />
      </Box>
    </Box>
  )
}

// Logic of the PassportUpdate component.
const usePassportUpdate = (addressInURL: string) => {
  const { resolveAddress, searchName } = useENS()
  const history = useHistory()
  const { chainId, account: connectedAccount } = useWeb3React()
  const initialConnectedAddy = useRef(connectedAccount)
  const isMe = addressInURL === 'me'

  const [accountToUse] = useAsync(async () => {
    if (isMe) {
      return connectedAccount
    } else {
      return resolveAddress(addressInURL)
    }
  }, [])
  useEffect(() => {
    if (initialConnectedAddy.current !== connectedAccount) {
      history.push('/passport')
      initialConnectedAddy.current = connectedAccount
    }
  }, [connectedAccount, history])

  const chainToUse = chainId ?? '1'
  const [ensName] = useAsync(
    async () => searchName(addressInURL === 'me' ? connectedAccount ?? addressInURL : addressInURL),
    [addressInURL],
  )
  const displayAddress = ensName || (accountToUse && formatAddress(accountToUse))
  const viewingOwnerAccount = accountToUse === connectedAccount

  return {
    accountToUse,
    chainId: chainToUse,
    viewingOwnerAccount,
    ensName,
    displayAddress,
    isMe,
  }
}
