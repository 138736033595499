import { Box } from '../Box'
import { IGrowthSteps } from './types'

export interface IColoredBarProps {
  /**
   * Assumption is that steps are ordered by the higher component
   */
  orderedSteps: IGrowthSteps[]
}

export const ColoredBar = ({ orderedSteps }: IColoredBarProps) => {
  const lastStepIndex = orderedSteps.length - 1

  return (
    <Box flexDirection="row" width="100%" height="100%">
      {orderedSteps.map((step, i) => (
        <Box
          key={i}
          borderRadius={getBorderRadius(i, lastStepIndex)}
          style={{ background: step.background }}
          flexGrow={i === 0 ? step.step / 100 : (step.step - orderedSteps[i - 1].step) / 100}
          marginRight={i === lastStepIndex ? '' : '1px'}
        />
      ))}
    </Box>
  )
}

function getBorderRadius(i: number, lastStepIndex: number): string {
  return `${i === 0 ? '8px' : 0} ${i === lastStepIndex ? '8px 8px' : '0 0'} ${i === 0 ? '8px' : 0}`
}
