import { asObject, asOptional, asString } from '@restless/sanitizers'

export type ScoreExplanation = {
  id?: string
  suggestion?: string
  explanation?: string
  impact?: string
  source?: string
  timestamp?: string
  transactionId?: string
}

export const asScoreExplanation = asObject<ScoreExplanation>({
  id: asOptional(asString),
  suggestion: asOptional(asString),
  explanation: asOptional(asString),
  impact: asOptional(asString),
  source: asOptional(asString),
  timestamp: asOptional(asString),
  transactionId: asOptional(asString),
})
