import { asArray, asNumber, asObject } from '@restless/sanitizers'
import { asFullScore, FullScore } from './fullScore'

export type ScoresResponse = {
  total: number
  current: number
  limit: number
  offset: number
  data: FullScore[]
}

export const asScoresResponse = asObject<ScoresResponse>({
  total: asNumber,
  current: asNumber,
  limit: asNumber,
  offset: asNumber,
  data: asArray(asFullScore),
})
