import CSS from 'csstype'
import styled, { css } from 'styled-components'
import { Colors } from '../../constants'
import { ButtonSize, ButtonVariant } from './types'

export const getButtonVariantCSS = (
  variant: ButtonVariant = 'primary',
  disabled?: boolean,
  loading?: boolean,
) => {
  if (disabled || loading) {
    return css`
      color: #9895a1;
      background-color: #2f2c33;
      cursor: ${disabled ? 'not-allowed' : 'pointer'};
    `
  }
  switch (variant) {
    case 'primary':
      return css`
        color: black;
        background-color: white;
        transition: 0.25s;
        &:hover {
          color: white;
          border: solid 3px white;
          background-color: black;
        }
      `
    case 'secondary':
      return css`
        background-color: #ffffff15;
        color: ${Colors.WHITE};
        transition: 0.25s;
        &:hover {
          background-color: white;
          color: #07050f;
        }
      `
    case 'tertiary':
      return css`
        background-color: #d94cd6;
        color: ${Colors.WHITE};
        transition: 0.25s;
        &:hover {
          background-color: #d94cd640;
          color: #d94cd6;
        }
      `
    case 'gradient':
      return css`
        background: linear-gradient(108.2deg, #d94cd6 12.66%, #51b6f9 121.19%);
        color: ${Colors.WHITE};
        transition: 0.25s;
        -moz-background-clip: padding;
        -webkit-background-clip: padding;
        background-clip: padding-box;
        &:hover {
          background: #d94cd6;
          color: white;
        }
      `
    case 'fancy':
      return css`
        font-size: 1rem;
        color: white;
        transition: 0.25s;
        box-shadow: 0 0 6px 0 rgba(157, 96, 212, 0.5);
        background-image: linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 0)),
          linear-gradient(108.2deg, #d94cd6 12.66%, #51b6f9 121.19%, rgba(217, 76, 214, 0) 121.2%);
        background-origin: border-box;
        background-clip: content-box, border-box;
        box-shadow: 2px 1000px 1px ${Colors.PURPLE_8} inset;
        display: flex;
        align-items: center;
        justify-content: center;
        &:hover {
          box-shadow: none;
          background-color: linear-gradient(
            108.2deg,
            #d94cd6 12.66%,
            #51b6f9 121.19%,
            rgba(217, 76, 214, 0) 121.2%
          );
        }
      `
    case 'translucent':
      return css`
        background-color: #d94cd640;
        color: white;
        transition: 0.25s;
        &:hover {
          background-color: #d94cd6;
        }
      `
  }
}

const sizes = {
  s: {
    padding: '8px 14px',
    fontSize: '14px',
    minHeight: '42px',
  },
  m: {
    padding: '10px 16px',
    fontSize: '16px',
    minHeight: '46px',
  },
  l: {
    padding: '14px 18px',
    fontSize: '18px',
    minHeight: '50px',
  },
}

interface StyledButtonProps {
  variant: ButtonVariant
  size: ButtonSize
  $disabled: boolean
  $loading: boolean
  $fullWidth: boolean
  width?: CSS.Property.Width
  borderWidth?: CSS.Property.BorderWidth
}

export const StyledButton = styled.button<StyledButtonProps>`
  display: inline;
  color: ${Colors.WHITE};
  border: 0;
  cursor: pointer;
  border-radius: 12px;
  border: solid ${(p) => p.borderWidth || '3px'} transparent;
  font-family: Matter;
  font-weight: 500;
  min-width: 100px;
  padding: ${(p) => sizes[p.size].padding};
  ${(p) => getButtonVariantCSS(p.variant, p.$disabled, p.$loading)}
  ${(p) => css`
    font-size: ${sizes[p.size].fontSize};
    min-height: ${sizes[p.size].minHeight};
  `}
  ${(p) => (p.width ? `width: ${p.width};` : '')}
  ${(p) => (p.$fullWidth ? 'width: 100%;' : '')}
`
