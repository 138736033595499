import { PassportScoreProof } from '@arcxgame/contracts/arc-types/sapphireTypes'
import { configConstants } from 'config'
import { useEffect, useState } from 'react'
import {
  CompleteScore,
  FullScore,
  HistoryEntry,
  ScoreAttributes,
  ScoreMetadata,
  ScoreWithProof,
  SimpleScore,
} from 'types'
import { useArcxApi } from './useArcxApi'

export const useCreditScore = (account: string): CompleteScore => {
  const arcxApi = useArcxApi()
  const [simpleScore, setSimpleScore] = useState<SimpleScore | undefined>()
  const [attributes, setAttributes] = useState<ScoreAttributes | undefined>()
  const [metadata, setMetadata] = useState<ScoreMetadata | undefined>()
  const [history, setHistory] = useState<HistoryEntry[] | undefined>()
  const [proof, setProof] = useState<PassportScoreProof | undefined>()

  useEffect(() => {
    if (!account && (simpleScore || attributes || metadata || history || proof)) {
      // Reset all values if account was disconnected
      setSimpleScore(undefined)
      setAttributes(undefined)
      setMetadata(undefined)
      setHistory(undefined)
      setProof(undefined)
      return
    }

    if (!account || !arcxApi) {
      return
    }

    // Get simple score
    arcxApi
      .getScore(account, configConstants.creditProtocol, 'simple')
      .then((score: SimpleScore) => {
        if (!account) return
        setSimpleScore(
          score || {
            account,
            protocol: configConstants.creditProtocol,
            score: '0',
          },
        )
      })

    // Get metadata
    arcxApi
      .getScore(account, configConstants.creditProtocol, 'full')
      .then((scoreWithMetadata: FullScore) => {
        if (!account) return
        if (scoreWithMetadata) {
          setMetadata(scoreWithMetadata.metadata)
        }
      })

    // Get attributes
    arcxApi.getScoreAttributes(configConstants.creditProtocol).then((attributes) => {
      if (!account) return
      setAttributes(attributes)
    })

    // Get history
    arcxApi.getScoreHistory(account, configConstants.creditProtocol).then((history) => {
      if (!account) return
      setHistory(history.history)
    })

    // Get proof
    arcxApi
      .getScore(account, configConstants.creditProtocol, 'proof')
      .then((scoreWithProof: ScoreWithProof) => {
        if (!account) return
        if (scoreWithProof) {
          setProof({
            account: scoreWithProof.account,
            merkleProof: scoreWithProof.merkleProof,
            score: scoreWithProof.score,
            protocol: scoreWithProof.protocol,
          })
        }
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account, arcxApi])

  return {
    simpleScore,
    attributes,
    metadata,
    history,
    proof,
  }
}
