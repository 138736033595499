import { useWeb3React } from '@web3-react/core'
import Notify, { API } from 'bnc-notify'
import { FC, useEffect, useState } from 'react'
import { getBlockExplorersBaseLink, getBlockExplorerTxLink } from 'utils'

export const NotificationsWrapper: FC = ({ children }) => {
  const { chainId, account } = useWeb3React()
  const [notify] = useState<API>(() => {
    // Documentation: https://docs.blocknative.com/notify
    return Notify({
      dappId: 'bd60f4c8-b723-4b66-9306-bfba7930c44c',
      networkId: chainId || 1,
      darkMode: true,
      desktopPosition: 'topLeft',
      notifyMessages,
    })
  })

  useEffect(() => {
    // Chain ID 80001 is unsupported
    if (!chainId || !account || chainId === 80001) {
      return
    }
    notify.config({ networkId: chainId })
    const { emitter } = notify.account(account)

    emitter.on('all', (transaction) => {
      return {
        autoDismiss: 5000,
        link: transaction.hash
          ? getBlockExplorerTxLink(chainId, transaction.hash)
          : getBlockExplorersBaseLink(chainId),
      }
    })

    return () => notify.unsubscribe(account)
  }, [account, chainId, notify])
  return <>{children}</>
}

const notifyMessages = {
  en: {
    transaction: {
      txRequest: 'Your transaction is waiting for you to confirm',
      nsfFail: 'You have insufficient funds to complete this transaction',
      txUnderpriced:
        'The gas price for your transaction is too low, try again with a higher gas price',
      txRepeat: 'This could be a repeat transaction',
      txAwaitingApproval: 'You have a previous transaction waiting for you to confirm',
      txConfirmReminder:
        'Please confirm your transaction to continue, the transaction window may be behind your browser',
      txSendFail: 'You rejected the transaction',
      txSent: 'Your transaction has been sent to the network',
      txStallPending: 'Your transaction has stalled and has not entered the transaction pool',
      txPool: 'Your transaction has started',
      txStallConfirmed: 'Your transaction has stalled and is not confirmed',
      txSpeedUp: 'Your transaction has been sped up',
      txCancel: 'Your transaction is being canceled',
      txFailed: 'Your transaction has failed',
      txConfirmed: 'Your transaction has succeeded',
      txError: 'Oops something went wrong, please try again',
    },
    watched: {
      txPool: 'Transaction submitted',
      txSpeedUp: 'Transaction has been sped up',
      txCancel: 'Transaction cancelled',
      txConfirmed: 'Transaction received!',
      txFailed: 'Transaction failed!',
    },
    time: {
      minutes: 'min',
      seconds: 'sec',
    },
  },
}
