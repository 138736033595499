import { useWeb3React } from '@web3-react/core'
import { ARCxCircleWhiteBetaAsset } from 'assets'
import { Box, ChainSelector, ConnectButton, Icon, Wallet } from 'components'
import { useAnalytics } from 'contexts'
import { Link } from 'react-router-dom'
import { getSpacing } from 'utils'
import { Link as ExternalLink } from '../../Typography'
import { ILink, INavigationProps } from '../types'
import { AppNavigationLinkItem } from './AppNavigationLinkItem'

export const AppNavigationLinks = ({ links = [] }: INavigationProps) => {
  const { account } = useWeb3React()
  const { page } = useAnalytics()
  return (
    <Box
      style={{ width: '100%' }}
      flexDirection="row"
      alignItems="center"
      justifyContent="space-between"
    >
      <Box flexDirection="row" alignItems="center" justifyContent="center">
        <Link to="/">
          <Box marginRight={getSpacing('m')}>
            <Icon src={ARCxCircleWhiteBetaAsset} alt="ARCx Logo" size="m" />
          </Box>
        </Link>
        <div className="flex gap-6">
          {links.map((link: ILink, index: number) => (
            <div key={index}>
              {link.external ? (
                <Box>
                  <ExternalLink
                    overwriteSize={18}
                    externalURL={link.to}
                    color={'GRAY_7'}
                    onClick={() => page(link.title.toLowerCase(), { url: link.to })}
                  >
                    {link.title}
                  </ExternalLink>
                </Box>
              ) : (
                <Link style={{ textDecoration: 'none' }} to={link.to}>
                  <AppNavigationLinkItem link={link} />
                </Link>
              )}
            </div>
          ))}
        </div>
      </Box>
      <Box flexDirection="row" alignItems="center" justifyContent="flex-end">
        <Box paddingRight="12px">
          <ChainSelector />
        </Box>
        {account ? <Wallet /> : <ConnectButton />}
      </Box>
    </Box>
  )
}
