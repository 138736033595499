import { useResponsive } from 'hooks'
import { useState } from 'react'
import styled from 'styled-components'
import { getSpacing } from 'utils'
import { Colors } from '../../constants'
import { Box } from '../Box'
import { Text } from '../Typography'

export const TabsContainer = styled.div``
export const TabSectionContainer = styled.div`
  cursor: pointer;
`

interface Section {
  name: string
  component: JSX.Element
}

interface ITabs {
  sections: Section[]
  activeTab?: number
  setActiveTab?: (tab: number) => void
}

export const Tabs = ({ sections = [], ...props }: ITabs) => {
  const [activeTab, setActiveTab] = useState(0)
  const { width } = useResponsive()
  const isMobile = width < 800

  // If not specified by props, use internal state
  const currentTab = props.activeTab || activeTab
  const setCurrentTab = props.setActiveTab || setActiveTab

  return (
    <Box>
      <Box justifyContent={isMobile ? 'center' : 'flex-start'} flexDirection="row" flexWrap="wrap">
        {sections.map((section, index) => (
          <TabSectionContainer onClick={() => setCurrentTab(index)} key={index}>
            <Box marginRight={getSpacing('m')} paddingBottom={getSpacing('xs')}>
              <Text
                size="m"
                weight="semi-bold"
                style={{
                  lineHeight: '21px',
                  color: currentTab === index ? Colors.WHITE : Colors.WHITE_30,
                }}
              >
                {section.name}
              </Text>
            </Box>
          </TabSectionContainer>
        ))}
      </Box>
      <Box marginTop={getSpacing('s')}>{sections[currentTab].component}</Box>
    </Box>
  )
}
