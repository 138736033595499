import { getEnv } from 'utils'
import { sharedConfig } from './config.shared'
import { IConfig } from './IConfig'

const config: IConfig = {
  ...sharedConfig,
  rpcUrl: `https://polygon-mainnet.g.alchemy.com/v2/${getEnv(
    'REACT_APP_POLYGON_ALCHEMY_KEY',
    'OFfnTyq_m3mt8zAnL0JWcVHLAHRSZXlQ',
  )}`,
  farms: [],
}

export default config
