import { XIcon } from '@heroicons/react/solid'
import { SocialButtonContainer } from 'components'
import { Colors } from 'constants/colors'
import { useModal } from 'contexts'
import { useState } from 'react'

export const CloseButton = () => {
  const { closeModal } = useModal()
  const [hover, setHover] = useState(false)
  return (
    <SocialButtonContainer
      onClick={closeModal}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      <XIcon width="19px" color={hover ? Colors.PURPLE_9 : Colors.WHITE} />
    </SocialButtonContainer>
  )
}
