import { BigNumber } from 'ethers'
import { Dispatch, SetStateAction, useEffect, useState } from 'react'

export const useDefaultInput = (
  defaultSource?: BigNumber,
): [BigNumber | undefined, Dispatch<SetStateAction<BigNumber | undefined>>] => {
  const [inputBN, setInputBN] = useState<BigNumber | undefined>(defaultSource)
  const [defaultInput, setDefaultInput] = useState(defaultSource)
  useEffect(() => {
    if (!defaultSource || defaultInput) {
      return
    }
    setInputBN(defaultSource)
    setDefaultInput((prev) => prev || defaultSource)
  }, [defaultSource, defaultInput])
  return [inputBN, setInputBN]
}
