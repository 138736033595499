import { DownloadIcon } from '@heroicons/react/solid'
import { SocialButton } from 'components'
import { Colors } from 'constants/colors'
import { useState } from 'react'

interface IDownloadButton {
  onClick: () => void
}

export const DownloadButton = ({ onClick }: IDownloadButton) => {
  const [hover, setHover] = useState(false)
  return (
    <SocialButton
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      onClick={onClick}
      tooltip="Download Score"
    >
      <DownloadIcon style={{ width: 20 }} color={hover ? Colors.PURPLE_7 : Colors.WHITE} />
    </SocialButton>
  )
}
