import styled from 'styled-components'
import { Text } from '../Typography'

export interface ITextButtonProps {
  children: JSX.Element | string
  onClick: () => void
  disabled?: boolean
}

export const TextButton = ({ children, onClick, disabled }: ITextButtonProps) => {
  return (
    <ButtonContainer onClick={() => !disabled && onClick()}>
      <Text size="xs" weight="medium" color="TEXT_DARK" style={{ opacity: disabled ? 0.5 : 1 }}>
        {children}
      </Text>
    </ButtonContainer>
  )
}

interface IButtonContainerProps {
  disabled?: boolean
}

const ButtonContainer = styled.div<IButtonContainerProps>`
  cursor: ${(p) => (p.disabled ? '' : 'pointer')};
  user-select: none;
`
