import { useWeb3React } from '@web3-react/core'
import { Box, SkeletonRectangle, Text } from 'components'
import { useCreditContext } from 'contexts'
import { BigNumber, providers } from 'ethers'
import { useResponsive } from 'hooks'
import { formatBigNumber, getSpacing } from 'utils'

type RenderableStat = {
  title: string
  value: string
}

export const Statistics = () => {
  const { active } = useWeb3React<providers.Web3Provider>()
  const { width } = useResponsive()
  const { coreCapsules, poolCapsules } = useCreditContext()

  let totalBorrowed = BigNumber.from(0)
  let totalValueLocked = BigNumber.from(0)
  if (coreCapsules.size > 0 && poolCapsules.size > 0) {
    for (const poolCapsule of poolCapsules.values()) {
      totalBorrowed = totalBorrowed.add(poolCapsule.fundsBorrowed)
      totalValueLocked = totalValueLocked.add(poolCapsule.fundsAvailable)
    }
    for (const coreCapsule of coreCapsules.values()) {
      totalValueLocked = totalValueLocked.add(coreCapsule.totalValueLocked)
    }
  }

  const statsToRender: RenderableStat[] = [
    // {
    //   title: 'Active Users',
    //   value: 'Coming soon',
    // },
    {
      title: 'Total Value Locked',
      value: totalValueLocked.eq(BigNumber.from(0))
        ? ''
        : `$${formatBigNumber(totalValueLocked, 0, 18)}`,
    },
    {
      title: 'Total Debt Borrowed',
      value: totalValueLocked.eq(BigNumber.from(0))
        ? ''
        : `$${formatBigNumber(totalBorrowed, 0, 18)}`,
    },
  ]

  if (width < 800) {
    return (
      <Box alignItems="center">
        <Box display="grid" style={{ gridTemplateColumns: 'auto auto' }}>
          {statsToRender.map((stat, index) => (
            <Box
              marginTop={index === 2 || index === 3 ? '16px' : '0px'}
              width="150px"
              alignItems="center"
              key={`stat__${index}`}
            >
              <SingleStat title={stat.title} value={stat.value} active={active} centerItems />
            </Box>
          ))}
        </Box>
      </Box>
    )
  }

  return (
    <Box flexDirection="row" justifyContent="flex-end" alignItems="center">
      {statsToRender.map((stat, index) => (
        <Box marginLeft={getSpacing('m')} key={`stat__${index}`}>
          <SingleStat title={stat.title} value={stat.value} active={active} />
        </Box>
      ))}
    </Box>
  )
}

interface ISingleStat {
  title: string
  value: string
  active: boolean
  centerItems?: boolean
}

const SingleStat = ({ title, value, active, centerItems }: ISingleStat) => {
  const content = (
    <>
      <Text color="GRAY_6" size="xs">
        {title}
      </Text>
      <Box>
        {value ? (
          <Text>{value}</Text>
        ) : active ? (
          <SkeletonRectangle style={{ marginTop: '6px' }} />
        ) : (
          <Text size="m">N/A</Text>
        )}
      </Box>
    </>
  )

  if (centerItems) {
    return (
      <Box display="flex" flexDirection="column" alignItems="center">
        {content}
      </Box>
    )
  }

  return content
}
