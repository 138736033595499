import { memo } from 'react'
import styled from 'styled-components'

export const Circle = styled.div`
  background: linear-gradient(180deg, #d2fe57 0%, #70d036 100%);
`

interface IProfileCircle {
  width?: number
}

/* Show a random colored profile circle */
export const ProfileCircle = memo(({ width = 28 }: IProfileCircle) => {
  const hexValues = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', 'a', 'b', 'c', 'd', 'e']

  function populate(a: string) {
    for (let i = 0; i < 6; i++) {
      const x = Math.round(Math.random() * 14)
      const y = hexValues[x]
      a += y
    }
    return a
  }

  const newColor1 = populate('#')
  const newColor2 = populate('#')
  const angle = Math.round(Math.random() * 360)

  const gradient = 'linear-gradient(' + angle + 'deg, ' + newColor1 + ', ' + newColor2 + ')'

  return (
    <Circle
      style={{
        background: gradient,
        width: `${width}px`,
        height: `${width}px`,
        borderRadius: `${width / 2}px`,
      }}
    />
  )
})
