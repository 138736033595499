import { ArrowRightIcon } from '@heroicons/react/solid'
import { useWeb3React } from '@web3-react/core'
import { BodyText, Box, PassportRoutesProps, Text, Title } from 'components'
import { Colors } from 'constants/colors'
import { useAnalytics, useModal } from 'contexts'
import { useEffect, useState } from 'react'
import { useHistory, useRouteMatch } from 'react-router'
import { CompleteScore } from 'types'
import { getIconUrlFromGCP, getSpacing } from 'utils'
import { ScoreModal } from '.'
import { SourceIcon } from './SourceIcon'

export type ScoreListItemProps = CompleteScore & {
  isOtherAccount: boolean
}

export const ScoreListItemMobile = (props: ScoreListItemProps) => {
  const score = props.simpleScore?.score
  const { title, name, description, sources } = props.attributes
  const { onScoreListItemClick } = useScoreListItem(props)

  return (
    <div onClick={onScoreListItemClick}>
      <Box
        style={{
          cursor: 'pointer',
          backgroundColor: 'transparent',
          borderTop: `1px solid ${Colors.WHITE_10}`,
          borderBottom: `1px solid ${Colors.WHITE_10}`,
        }}
        padding={`${getSpacing('m')} ${getSpacing('s')}`}
      >
        <Box marginBottom={getSpacing('m')} flexDirection="row" justifyContent="space-between">
          <Title weight="semi-bold" size="m" tag="h3">
            {title}
          </Title>
          <Box flexDirection="row" alignItems="center">
            <SourceIcon style={{ width: '25px', marginRight: '5px' }} source={name} />
            <Text
              style={{
                color: score ? Colors.WHITE : Colors.WHITE_50,
              }}
              weight="medium"
              size="l"
            >
              {score || '—'}
            </Text>
          </Box>
        </Box>
        <BodyText
          style={{
            marginBottom: getSpacing('s'),
          }}
          color="WHITE_80"
          size="s"
          weight="regular"
        >
          {description}
        </BodyText>
        <Box flexDirection="row" justifyContent="space-between" alignItems="center">
          <Box flexDirection="row" alignItems="center">
            <Text
              size="s"
              weight="regular"
              style={{
                color: Colors.WHITE_80,
              }}
            >
              Indexed On
            </Text>
            {sources.map((s, i) => (
              <Box paddingLeft="10px" key={`source_${i}`}>
                <SourceIcon source={s} />
              </Box>
            ))}
          </Box>
          <Text color="WHITE_80" weight="regular" size="s">
            View Details
          </Text>
        </Box>
      </Box>
    </div>
  )
}

export const ScoreListItemDesktop = (props: ScoreListItemProps) => {
  const score = props.simpleScore?.score
  const { title, description, sources, name } = props.attributes
  const [hover, setHover] = useState(false)
  const { onScoreListItemClick } = useScoreListItem(props)

  return (
    <div
      onClick={onScoreListItemClick}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      <Box
        style={{
          cursor: 'pointer',
          backgroundColor: hover ? Colors.WHITE_05 : 'transparent',
          borderTop: `1px solid ${Colors.WHITE_10}`,
          borderBottom: `1px solid ${Colors.WHITE_10}`,
        }}
        padding={`${getSpacing('m')} ${getSpacing('s')}`}
      >
        <Box
          style={{
            marginBottom: getSpacing('xs'),
          }}
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Title weight="semi-bold" size="l" tag="h3">
            {title}
          </Title>
          <Box flexDirection="row" alignItems="center">
            <img style={{ width: '35px', marginRight: '12px' }} src={getIconUrlFromGCP(name)} />
            <Text
              style={{
                color: score ? Colors.WHITE : Colors.WHITE_50,
              }}
              weight="medium"
              size="xl"
            >
              {score || '—'}
            </Text>
          </Box>
        </Box>
        <BodyText
          style={{
            width: '80%',
            marginBottom: getSpacing('s'),
            color: hover ? Colors.WHITE : Colors.WHITE_80,
          }}
          size="s"
          weight="regular"
        >
          {description}
        </BodyText>
        <Box flexDirection="row" justifyContent="space-between" alignItems="center">
          <Box flexDirection="row" alignItems="center">
            <Text
              size="s"
              weight="regular"
              style={{
                color: Colors.WHITE_80,
              }}
            >
              Indexed On
            </Text>
            {sources.map((s, i) => (
              <Box paddingLeft="10px" key={`source_${i}`}>
                <SourceIcon style={{ width: '28px' }} source={s} />
              </Box>
            ))}
          </Box>
          <Box flexDirection="row" alignItems="center">
            <Text
              style={{
                color: hover ? Colors.WHITE : Colors.WHITE_80,
              }}
              weight="regular"
              size="s"
            >
              View Details
            </Text>
            <ArrowRightIcon
              style={{
                marginLeft: '4px',
                marginTop: '1px',
                color: hover ? Colors.WHITE : Colors.WHITE_80,
              }}
              width="16px"
            />
          </Box>
        </Box>
      </Box>
    </div>
  )
}

/**
 * Common logic for ScoreListItem
 */
const useScoreListItem = (scoreModalProps: ScoreListItemProps) => {
  const match = useRouteMatch<PassportRoutesProps>()
  const { openWith, closeModal } = useModal()
  const history = useHistory()
  const score = scoreModalProps.simpleScore
  const { name, title } = scoreModalProps.attributes
  const { chainId, account } = useWeb3React()
  const { track } = useAnalytics()

  useEffect(() => {
    if (match.params.protocol === name) {
      openWith(<ScoreModal {...scoreModalProps} />, {
        onClose: () => {
          if (!history.location.pathname.startsWith('/rankings')) {
            history.push(`/passport/${match.params.address}`)
          }
          closeModal()
        },
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [match, scoreModalProps])

  const onScoreListItemClick = () => {
    history.push(`/passport/${match.params.address}/${name}`)
    track('ScoreViewed', {
      ScoreName: title,
      ProtocolName: name,
      ScoreReceived: score,
      WalletStatus: account ? 'Connected' : 'Disconnected',
      chainId,
    })
  }

  return { onScoreListItemClick }
}
