import ReactTooltip from 'react-tooltip'
import styled from 'styled-components'
import { v4 as uuidv4 } from 'uuid'
import { TooltipIcon } from '../../assets'
import { Text } from '../Typography'

export interface ITooltipProps {
  content: string | JSX.Element
  width?: number | string
  height?: number | string
}

export const Tooltip = ({ content, width = 16, height = 16 }: ITooltipProps) => {
  const tooltipId = 'tooltip-' + uuidv4()

  return (
    <>
      <img
        src={TooltipIcon}
        alt="Tooltip"
        width={width}
        height={height}
        data-for={tooltipId}
        data-tip
      />
      <StyledReactToolTip id={tooltipId} effect="solid" place="top" arrowColor="#524A75">
        {typeof content === 'string' ? (
          <Text size="xxs" weight="regular" color="WHITE">
            {content}
          </Text>
        ) : (
          content
        )}
      </StyledReactToolTip>
    </>
  )
}

// !important is needed by the library
export const StyledReactToolTip = styled(ReactTooltip)`
  background-color: #524a75 !important;
  opacity: 1 !important;
  width: 200px !important;
  border-radius: 10px !important;
`
