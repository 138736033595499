import { BigNumber } from 'ethers'

export const SECONDS_IN_YEAR = BigNumber.from(365 * 24 * 60 * 60)

export const nowInSeconds = () => Math.floor(Date.now() / 1000)

export function secondsToDhm(seconds: number) {
  seconds = Number(seconds)
  const d = Math.floor(seconds / (3600 * 24))
  const h = Math.floor((seconds % (3600 * 24)) / 3600)
  const m = Math.floor((seconds % 3600) / 60)

  const dDisplay = d > 0 ? d + 'd ' : ''
  const hDisplay = h > 0 ? h + 'h ' : ''
  const mDisplay = m > 0 ? m + 'min ' : ''
  const sDisplay = seconds < 60 ? seconds + 's ' : ''
  return dDisplay + hDisplay + mDisplay + sDisplay
}

export function formatTimestamp(timestampInSeconds: number) {
  const date = new Date(timestampInSeconds * 1000)
  return date.getDate() + '.' + (date.getMonth() + 1) + '.' + date.getFullYear()
}
