import { useRollbar } from '@rollbar/react'
import { useWeb3React } from '@web3-react/core'
import { providers } from 'ethers'
import { PassportContext, useArcxApi } from 'hooks'
import { useContext, useState } from 'react'
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'
import { useAnalytics } from '../contexts/AnalyticsContext'

export function useClaim() {
  const { active, library, account, chainId } = useWeb3React<providers.Web3Provider>()
  const { updatePassportMetadata } = useContext(PassportContext)
  const rollbar = useRollbar()
  const arcxApi = useArcxApi()
  const { track } = useAnalytics()

  const [error, setError] = useState<string>(null)
  const [loading, setLoading] = useState(false)

  const { executeRecaptcha } = useGoogleReCaptcha()

  const claim = async () => {
    if (!active) {
      throw new Error('Wallet is not connected')
    }

    setError(null)
    setLoading(true)

    try {
      const token = await executeRecaptcha('claimPassport')

      const signer = library.getSigner()

      const message = JSON.stringify({
        intent: 'I want to claim a passport',
        expiry: (new Date().getTime() / 1000 + 60).toFixed(0),
      })

      const signature = await signer.signMessage(message)

      const hasClaimed = await arcxApi.postClaimPassport({
        token,
        account,
        signature,
        message,
      })

      if (hasClaimed) {
        track('PassportClaimed', {
          WalletAddress: account,
          chainId: chainId,
        })
      }

      await updatePassportMetadata()
    } catch (error) {
      console.error(`useClaim::claim: error on claim: ${error}`)
      rollbar.error('useClaim: An error occurred during claim process', error, { account })
      setError('An error has occurred.')
    }

    setLoading(false)
  }

  return {
    error,
    loading,
    claim,
  }
}
