import { asObject, asString } from '@restless/sanitizers'

export type SimpleScore = {
  account: string

  /**
   * Protocol in string format (not base64)
   */
  protocol: string

  score: string
}

export const asSimpleScore = asObject<SimpleScore>({
  account: asString,
  protocol: asString,
  score: asString,
})
