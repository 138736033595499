import { keyframes } from 'styled-components'

export const loading = keyframes`
  from {
    left -300px;
  }
  to {
    left: 100%;
  }
`
