import { BigNumber, utils } from 'ethers'
import _ from 'lodash'
import styled from 'styled-components'
import { ColoredBar } from './ColoredBar'
import { CurrentGrowthBar } from './CurrentGrowthBar'
import { IGrowthSteps } from './types'

export interface ICreditScoreBarProps {
  /**
   * The current growth percentage [0, 100]
   */
  currentGrowth: BigNumber
  /**
   * The growth steps of the bar. Each step has to be [0, 100].
   * There *must* be step 0 and 100 or else the component will throw.
   */
  steps?: IGrowthSteps[]
}

const BackgroundBar = styled.div`
  opacity: 50%;
  height: 100%;
`

const BarContainer = styled.div`
  position: relative;
  height: 16px;
`

export const CreditScoreBar = ({ currentGrowth, steps = [] }: ICreditScoreBarProps) => {
  const stepsCopy = _.cloneDeep(steps)
  stepsCopy.sort((a, b) => a.step - b.step)

  return (
    <BarContainer>
      <BackgroundBar>
        <ColoredBar orderedSteps={stepsCopy} />
      </BackgroundBar>
      <CurrentGrowthBar
        orderedSteps={stepsCopy}
        currentGrowthPercentage={parseFloat(utils.formatEther(currentGrowth))}
      />
    </BarContainer>
  )
}
