import { BASE } from '@arcxgame/contracts'
import {
  BaseERC20Factory,
  SapphireCoreV1,
  SapphireCoreV1Factory,
  SapphirePoolFactory,
} from '@arcxgame/contracts/src/typings'
import { ISapphireOracleFactory } from '@arcxgame/contracts/src/typings/ISapphireOracleFactory'
import { ArcxApi } from 'entities/ArcxApi'
import { BigNumber, providers, Signer } from 'ethers'
import { ScoreAttributes } from 'types'
import { ethMulticaller, quickWeiValuePow, scaleValue, SECONDS_IN_YEAR } from 'utils'
import { CapsuleRefreshEmitter } from './CapsuleRefreshEmitter'

/**
 * A SapphireCoreV1 Capsule
 */
export class CoreCapsule extends CapsuleRefreshEmitter {
  /* -------------------------------------------------------------------------- */
  /*                                 Properties                                 */
  /* -------------------------------------------------------------------------- */

  /* --------------------------- Private properties --------------------------- */
  protected _core: SapphireCoreV1

  /* --------------------------- Static properties --------------------------- */
  creditScoreProtocol!: string
  creditLimitProtocol!: string
  supportedBorrowAssetAddresses!: string[]
  borrowPoolAddress!: string
  oracleAddress!: string
  collateralAddress!: string
  collateralSymbol!: string

  creditLimitAttributes!: ScoreAttributes
  creditScoreAttributes!: ScoreAttributes

  collateralDecimals!: number
  collateralPrice!: BigNumber
  interestRatePerSecond!: BigNumber
  liquidationFee!: BigNumber
  defaultBorrowLimit!: BigNumber
  borrowFee!: BigNumber
  poolFee!: BigNumber
  currentBorrowIndex!: BigNumber
  highCollateralRatio!: BigNumber
  lowCollateralRatio!: BigNumber
  usageInPool!: {
    amountUsed: BigNumber
    limit: BigNumber
    remaining: BigNumber
  }
  // expectedEpochWithProof: BigNumber //TODO: https://app.asana.com/0/1202046606479267/1202548213215221

  /* ---------------------------- Dynamic properties --------------------------- */
  totalCollateral!: BigNumber

  /* --------------------------- Computed properties -------------------------- */
  get maxLTV(): BigNumber {
    return BASE.mul(BASE).div(this.lowCollateralRatio)
  }

  get minLTV(): BigNumber {
    return BASE.mul(BASE).div(this.highCollateralRatio)
  }

  get yearlyInterestRate(): BigNumber {
    return quickWeiValuePow(BASE.add(this.interestRatePerSecond), SECONDS_IN_YEAR).sub(BASE)
  }

  get totalValueLocked(): BigNumber {
    return scaleValue(this.totalCollateral, this.collateralDecimals)
      .mul(this.collateralPrice)
      .div(BASE)
  }

  get scoreThreshold(): number {
    return parseInt(this.creditLimitAttributes.threshold || '0')
  }

  /* -------------------------------------------------------------------------- */
  /*                                   Methods                                  */
  /* -------------------------------------------------------------------------- */

  /* ------------------------------- Constructor ------------------------------ */

  constructor(
    public readonly contractAddress: string,
    public provider: providers.JsonRpcProvider | Signer, // public account?: string,
  ) {
    super(contractAddress, provider)

    this._core = SapphireCoreV1Factory.connect(contractAddress, provider)
  }

  /* ----------------------------- Public methods ----------------------------- */

  // Override method, but eslint pre-commit doesn't like it
  async initializeState(arcxApi: ArcxApi) {
    if (!this.creditScoreAttributes) {
      // Set the credit score attributes first, to avoid doing the same call multiple times
      throw new Error(
        `CoreCapsule::initializeState: Core ${this.contractAddress} is missing credit score attributes`,
      )
    }

    type MulticallTypes = [
      BigNumber,
      BigNumber,
      BigNumber,
      BigNumber,
      BigNumber,
      BigNumber,
      BigNumber,
      BigNumber,

      string,
      string,
      string[],
      string,
      string,
      string,
    ]
    ;[
      this.defaultBorrowLimit,
      this.interestRatePerSecond,
      this.liquidationFee,
      this.borrowFee,
      this.lowCollateralRatio,
      this.highCollateralRatio,
      this.currentBorrowIndex,
      this.poolFee,

      this.creditScoreProtocol,
      this.creditLimitProtocol,
      this.supportedBorrowAssetAddresses,
      this.borrowPoolAddress,
      this.oracleAddress,
      this.collateralAddress,
      // expectedEpochWithProof,  //TODO: https://app.asana.com/0/1202046606479267/1202548213215221
    ] = await ethMulticaller<MulticallTypes>(this._core, [
      'defaultBorrowLimit',
      'interestRate',
      'liquidatorDiscount',
      'borrowFee',
      'lowCollateralRatio',
      'highCollateralRatio',
      'currentBorrowIndex',
      'poolInterestFee',

      ['getProofProtocol', ['0']],
      ['getProofProtocol', ['1']],
      'getSupportedBorrowAssets',
      'borrowPool',
      'oracle',
      'collateralAsset',
    ])

    const oracle = ISapphireOracleFactory.connect(this.oracleAddress, this.provider)
    const collateral = BaseERC20Factory.connect(this.collateralAddress, this.provider)
    const pool = SapphirePoolFactory.connect(this.borrowPoolAddress, this.provider)

    const collateralMulticallPromise = ethMulticaller<[number, string, BigNumber]>(collateral, [
      'decimals',
      'symbol',
      ['balanceOf', [this.contractAddress]],
    ])

    const [
      priceInfo,
      [collateralDecimals, collateralSymbol, totalCollateral],
      creditLimitAttributes,
      usageInPool,
    ] = await Promise.all([
      oracle.fetchCurrentPrice(),
      collateralMulticallPromise,
      arcxApi.getScoreAttributes(this.creditLimitProtocol),
      pool.coreBorrowUtilization(this.contractAddress),
    ])
    this.collateralPrice = priceInfo.price
    this.collateralDecimals = collateralDecimals
    this.collateralSymbol = collateralSymbol
    this.creditLimitAttributes = creditLimitAttributes
    this.totalCollateral = totalCollateral

    const remainingUsage = usageInPool.amountUsed.gte(usageInPool.limit)
      ? BigNumber.from(0)
      : usageInPool.limit.sub(usageInPool.amountUsed)
    this.usageInPool = {
      amountUsed: usageInPool.amountUsed,
      limit: usageInPool.limit,
      remaining: remainingUsage,
    }
  }

  /* ----------------------- Protected / private methods ---------------------- */
  protected async _refresh(): Promise<void> {
    // It is possible the provider changed because of a wallet reconnection. In that case,
    // make sure the contract uses the updated provider/signer
    if (this.provider !== this._core.provider) {
      this._core = SapphireCoreV1Factory.connect(this.contractAddress, this.provider)
    }

    /**
     * Refresh the dynamic properties of the contract and the account's
     */
    const erc20 = BaseERC20Factory.connect(this.collateralAddress, this.provider)

    this.totalCollateral = await erc20.balanceOf(this.contractAddress)
  }
}
