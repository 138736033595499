import { Box, CreditScoreBar, Text } from 'components'
import { GrowthSuggestion } from 'components/GrowthBar/GrowthSuggestion'
import { CoreCapsuleWithAccount } from 'entities'
import { BigNumber } from 'ethers'
import { getSpacing } from 'utils'
import { getGrowthBarDetails } from './getGrowthBarDetails'
import { growthSteps } from './growthSteps'

export interface IGrowthBarProps {
  borrowUsage: BigNumber
  coreCapsules: CoreCapsuleWithAccount[]
  children?: JSX.Element | JSX.Element[]
  hideCurrentGrowthText?: boolean
}

export const GrowthBar = ({
  borrowUsage,
  coreCapsules,
  hideCurrentGrowthText,
  children,
}: IGrowthBarProps) => {
  const { creditGrowth } = getGrowthBarDetails(borrowUsage, coreCapsules)

  return (
    <>
      <Box paddingBottom={getSpacing('s')}>
        <CreditScoreBar currentGrowth={borrowUsage.mul(100)} steps={growthSteps} />
      </Box>

      {!hideCurrentGrowthText && <CurrentGrowth creditGrowth={creditGrowth} />}

      {children}

      <Box marginTop={getSpacing('s')}>
        <GrowthSuggestion coreCapsules={coreCapsules} borrowUsage={borrowUsage} />
      </Box>
    </>
  )
}

interface ICurrentGrowthProps {
  creditGrowth: string
}

const CurrentGrowth = ({ creditGrowth }: ICurrentGrowthProps) => {
  return (
    <Box flexDirection="row">
      <Text size="xs" weight="thin" color="TEXT_DARK">
        Credit score growth:&nbsp;
      </Text>
      <Text size="xs" weight="thin">
        {creditGrowth}
      </Text>
    </Box>
  )
}
