import { useWeb3React } from '@web3-react/core'
import { ARCxCircleWhiteBetaAsset } from 'assets'
import {
  Box,
  ChainSelector,
  ConnectButton,
  Icon,
  Link as ExternalLink,
  Text,
  Wallet,
} from 'components'
import { Colors } from 'constants/colors'
import { useAnalytics } from 'contexts'
import { useEffect, useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { getSpacing } from 'utils'
import { INavigationProps } from '../types'

const AppNavigationContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: ${getSpacing('l')} ${getSpacing('m')};
`
const LinksContainer = styled.div`
  position: absolute;
  width: calc(100vw - 40px);
  background-color: ${Colors.WHITE_20};
  top: 60px;
  right: 0;
  bottom: 0;
  border-radius: 12px;
  z-index: 999999;
`

const LinkContainer = styled.div<{ first: boolean; last: boolean }>`
  background-color: ${Colors.PURPLE_7};
  padding: 20px 15px;
  &:hover {
    background-color: ${Colors.PURPLE_5};
  }
  cursor: pointer;
  ${(p) => (p.first ? 'border-radius: 12px 12px 0px 0px;' : '')}
  ${(p) => (p.last ? 'border-radius: 0px 0px 12px 12px;' : '')}
  ${(p) => (!p.last ? 'border-bottom: 1px solid #ffffff10;' : '')}
`

interface ILinks extends INavigationProps {
  closeHamburger: () => void
}

const Links = ({ links = [], closeHamburger }: ILinks) => {
  const { page } = useAnalytics()

  return (
    <LinksContainer>
      {links.map((link, index) => {
        if (link.external) {
          return (
            <ExternalLink
              key={`nav_link_${index}`}
              style={{ textDecoration: 'none' }}
              externalURL={link.to}
            >
              <LinkContainer
                first={index === 0}
                last={index === links.length - 1}
                onClick={() => {
                  page(link.title.toLowerCase(), { url: link.to })
                  closeHamburger()
                }}
              >
                <Text>{link.title}</Text>
              </LinkContainer>
            </ExternalLink>
          )
        } else {
          return (
            <Link key={`nav_link_${index}`} style={{ textDecoration: 'none' }} to={link.to}>
              <LinkContainer
                first={index === 0}
                last={index === links.length - 1}
                onClick={() => closeHamburger()}
              >
                <Text>{link.title}</Text>
              </LinkContainer>
            </Link>
          )
        }
      })}
    </LinksContainer>
  )
}

const Hamburger = ({ links = [] }: INavigationProps) => {
  const [hover, setHover] = useState(false)
  const [isHaburgerOpened, setIsHaburgerOpened] = useState(false)
  const ref = useRef<ILinks>()

  useEffect(() => {
    const checkIfClickedOutside = (e: Event) => {
      if (isHaburgerOpened && ref.current) {
        setIsHaburgerOpened(false)
      }
    }

    document.addEventListener('mousedown', checkIfClickedOutside)

    return () => {
      document.removeEventListener('mousedown', checkIfClickedOutside)
    }
  }, [isHaburgerOpened])

  return (
    <div style={{ position: 'relative' }}>
      <Box
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
        onClick={() => setIsHaburgerOpened((open) => !open)}
        style={{
          backgroundColor: hover ? Colors.WHITE_50 : Colors.WHITE_20,
          cursor: 'pointer',
        }}
        alignItems="center"
        justifyContent="center"
        height="48px"
        width="48px"
        borderRadius="12px"
      >
        <svg
          width="20"
          height="16"
          viewBox="0 0 20 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            // eslint-disable-next-line max-len
            d="M0.900391 1.5002C0.900391 1.15541 1.03735 0.824754 1.28115 0.580957C1.52495 0.337159 1.85561 0.200195 2.20039 0.200195H17.8004C18.1452 0.200195 18.4758 0.337159 18.7196 0.580957C18.9634 0.824754 19.1004 1.15541 19.1004 1.5002C19.1004 1.84498 18.9634 2.17564 18.7196 2.41943C18.4758 2.66323 18.1452 2.8002 17.8004 2.8002H2.20039C1.85561 2.8002 1.52495 2.66323 1.28115 2.41943C1.03735 2.17564 0.900391 1.84498 0.900391 1.5002ZM0.900391 8.0002C0.900391 7.65541 1.03735 7.32475 1.28115 7.08096C1.52495 6.83716 1.85561 6.7002 2.20039 6.7002H17.8004C18.1452 6.7002 18.4758 6.83716 18.7196 7.08096C18.9634 7.32475 19.1004 7.65541 19.1004 8.0002C19.1004 8.34498 18.9634 8.67564 18.7196 8.91943C18.4758 9.16323 18.1452 9.3002 17.8004 9.3002H2.20039C1.85561 9.3002 1.52495 9.16323 1.28115 8.91943C1.03735 8.67564 0.900391 8.34498 0.900391 8.0002ZM0.900391 14.5002C0.900391 14.1554 1.03735 13.8248 1.28115 13.581C1.52495 13.3372 1.85561 13.2002 2.20039 13.2002H17.8004C18.1452 13.2002 18.4758 13.3372 18.7196 13.581C18.9634 13.8248 19.1004 14.1554 19.1004 14.5002C19.1004 14.845 18.9634 15.1756 18.7196 15.4194C18.4758 15.6632 18.1452 15.8002 17.8004 15.8002H2.20039C1.85561 15.8002 1.52495 15.6632 1.28115 15.4194C1.03735 15.1756 0.900391 14.845 0.900391 14.5002Z"
            fill="white"
          />
        </svg>
      </Box>
      {isHaburgerOpened && (
        <Links closeHamburger={() => setIsHaburgerOpened(false)} links={links} />
      )}
    </div>
  )
}

export const AppNavigationMobile = ({ links = [] }: INavigationProps) => {
  const { account } = useWeb3React()
  return (
    <AppNavigationContainer>
      <Link to="/">
        <Box marginRight={getSpacing('m')}>
          <Icon src={ARCxCircleWhiteBetaAsset} alt="ARCx Logo" size="m" />
        </Box>
      </Link>
      <Box flexDirection="row" alignItems="center">
        <Box marginRight="12px">
          <ChainSelector />
        </Box>
        <Box marginRight="12px">{account ? <Wallet /> : <ConnectButton />}</Box>
        <Hamburger links={links} />
      </Box>
    </AppNavigationContainer>
  )
}
