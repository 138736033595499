import { asArray, asBoolean, asObject, asOptional, asString } from '@restless/sanitizers'
import { asRecord } from 'utils'
import { asScoreExplanation, ScoreExplanation } from './scoreExplanation'

export interface CategoryProperties {
  title?: string
  description?: string
  uri?: string
}

export const asCategoryProperties = asObject<CategoryProperties>({
  title: asOptional(asString),
  description: asOptional(asString),
  uri: asOptional(asString),
})

/* -------------------------------------------------------------------------- */

export interface ScoreCategories {
  [name: string]: CategoryProperties
}

export const asScoreCategories = asRecord<CategoryProperties>(asCategoryProperties)

/* -------------------------------------------------------------------------- */

export type ScoreAttributes = {
  name: string
  nameByte32: string
  active: boolean
  title: string
  description: string
  externalUri: string
  sources: string[]
  categories: ScoreCategories
  defaults: ScoreExplanation[]
  populations: string[]
  max: string
  min: string
  ranges: string[][]
  threshold?: string
}

export const asScoreAttributes = asObject<ScoreAttributes>({
  name: asString,
  nameByte32: asString,
  active: asBoolean,
  title: asString,
  description: asString,
  externalUri: asString,
  sources: asArray(asString),
  categories: asScoreCategories,
  defaults: asArray(asScoreExplanation),
  max: asString,
  min: asString,
  ranges: asArray(asArray(asString)),
  populations: asArray(asString),
  threshold: asOptional(asString),
})
