import styled, { css } from 'styled-components'
import { combinedCss } from './styles'
import { Tag, TextInterface, TextProps } from './types'

const StyledH1 = styled.h1<TextInterface>`
  ${(p) => css`
    ${combinedCss(p)}
  `}
`
const StyledH2 = styled.h2<TextInterface>`
  ${(p) => css`
    ${combinedCss(p)}
  `}
`
const StyledH3 = styled.h3<TextInterface>`
  ${(p) => css`
    ${combinedCss(p)}
  `}
`
const StyledH4 = styled.h4<TextInterface>`
  ${(p) => css`
    ${combinedCss(p)}
  `}
`
const StyledH5 = styled.h5<TextInterface>`
  ${(p) => css`
    ${combinedCss(p)}
  `}
`
const StyledH6 = styled.h6<TextInterface>`
  ${(p) => css`
    ${combinedCss(p)}
  `}
`

interface IProps extends TextProps {
  tag: Tag
}

export const Title = ({ children, tag, ...props }: IProps) => {
  let TitleTag
  switch (tag) {
    case 'h1':
      TitleTag = StyledH1
      break
    case 'h2':
      TitleTag = StyledH2
      break
    case 'h3':
      TitleTag = StyledH3
      break
    case 'h4':
      TitleTag = StyledH4
      break
    case 'h5':
      TitleTag = StyledH5
      break
    case 'h6':
      TitleTag = StyledH6
      break
  }
  return <TitleTag {...props}>{children}</TitleTag>
}
