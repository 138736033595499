import styled from 'styled-components'
import { combinedCss } from './styles'
import { TextInterface, TextProps } from './types'

const StyledBodyText = styled.p<TextInterface>`
  ${(p) => combinedCss(p)}
`

export const BodyText = ({ children, ...props }: TextProps) => {
  return <StyledBodyText {...props}>{children}</StyledBodyText>
}
