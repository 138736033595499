import { useWeb3React } from '@web3-react/core'
import { useENS } from 'contexts'
import { useAsync } from 'hooks'
import { createContext, useCallback, useContext, useEffect, useState } from 'react'
import { PassportMetadata } from 'types'
import { eqIgnoreCase } from 'utils'
import { useArcxApi } from './useArcxApi'

interface IPassportContext {
  isLoadingPassport: boolean
  updatePassportMetadata: () => Promise<void>
  passportMetadata?: PassportMetadata
}

export interface PassportSkin {
  imageUrl: string
  address: string
  tokenId: string
}

export const PassportContext = createContext<IPassportContext>({
  passportMetadata: undefined,
  isLoadingPassport: true,
  updatePassportMetadata: async () => undefined,
})

export const PassportProvider = ({ children }: JSX.ElementChildrenAttribute) => {
  const [passportMetadata, setPassportMetadata] = useState<PassportMetadata>()
  const [isLoadingPassport, setIsLoadingPassport] = useState(true)
  const { account } = useWeb3React()
  const arcxApi = useArcxApi()

  const updatePassportMetadata = useCallback(async () => {
    setIsLoadingPassport(true)
    setPassportMetadata(account && arcxApi ? await arcxApi.getPassportMetadata(account) : undefined)
    setIsLoadingPassport(false)
  }, [arcxApi, account])

  useEffect(() => {
    updatePassportMetadata()
  }, [arcxApi, account, updatePassportMetadata])

  return (
    <PassportContext.Provider
      value={{ passportMetadata, updatePassportMetadata, isLoadingPassport }}
    >
      {children}
    </PassportContext.Provider>
  )
}

export const usePassport = (
  id?: string | null,
): { passportMetadata: PassportMetadata | undefined; isLoadingPassport: boolean } => {
  const { passportMetadata: currentMetadata, isLoadingPassport } = useContext(PassportContext)
  const { account: connectedAddress } = useWeb3React()
  const [passportMetadata, setPassportMetadata] = useState<PassportMetadata>()
  const arcxApi = useArcxApi()
  const { resolveAddress } = useENS()

  const [resolvedAddress] = useAsync(
    async () => id && resolveAddress(id),
    [id],
    undefined,
    'usePassport',
  )

  const showPassportOfOtherAccount =
    resolvedAddress && !eqIgnoreCase(resolvedAddress, connectedAddress)

  useEffect(() => {
    updatePassportMetadata()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resolvedAddress, arcxApi])

  async function updatePassportMetadata() {
    if (id && resolvedAddress) {
      showPassportOfOtherAccount
        ? setPassportMetadata(await arcxApi?.getPassportMetadata(resolvedAddress))
        : setPassportMetadata(undefined)
    }
  }

  return {
    passportMetadata: showPassportOfOtherAccount ? passportMetadata : currentMetadata,
    isLoadingPassport,
  }
}
