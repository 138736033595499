import { BigNumber, BigNumberish, constants } from 'ethers'

export function scaleValue(value: BigNumberish, decimals: number) {
  return BigNumber.from(value).mul(BigNumber.from(10).pow(18 - decimals))
}

export function reduceValue(value: BigNumberish, decimals: number) {
  return BigNumber.from(value).div(BigNumber.from(10).pow(18 - decimals))
}

export function bigMul(a: BigNumber, b: BigNumber) {
  return a.mul(b).div(constants.WeiPerEther)
}

export function bigDiv(a: BigNumber, b: BigNumber) {
  return a.mul(constants.WeiPerEther).div(b)
}
