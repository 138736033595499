import { useRef, useState } from 'react'
import styled from 'styled-components'
import { getSpacing } from 'utils'
import { Colors } from '../../constants'
import { useOutsideClickCallback } from '../../hooks'
import { Text } from '../Typography'

export type DropdownListOption = {
  label: string
  value: string
}

export interface ISelectorProps {
  value: string
  onChange: (value: DropdownListOption) => void
  options: DropdownListOption[]
}

export const DropdownList = ({ value, options, onChange }: ISelectorProps) => {
  const containerRef = useRef<HTMLDivElement | null>(null)
  const [isOpen, setIsOpen] = useState(false)
  useOutsideClickCallback(containerRef, () => setIsOpen(false))

  const selected = options.find((option) => option.value === value)

  if (!selected) {
    throw new Error('DropdownList: the given value does not match any option')
  }

  const onOptionClick = (option: DropdownListOption) => {
    setIsOpen(false)
    onChange(option)
  }

  return (
    <DropdownContainer ref={containerRef}>
      <DropDownHeader onClick={() => setIsOpen(!isOpen)}>
        <Text color="WHITE" size="s">
          {selected.label}
        </Text>
      </DropDownHeader>
      <CustomList style={{ display: isOpen ? 'block' : 'none' }}>
        {options.map((option, i) => (
          <ListItem key={i} onClick={() => onOptionClick(option)}>
            <Text color="WHITE" size="s">
              {option.label}
            </Text>
          </ListItem>
        ))}
      </CustomList>
    </DropdownContainer>
  )
}

const DropdownContainer = styled.div`
  width: 100%;
  margin: 0 auto;
  position: relative;
`

const DropDownHeader = styled.div`
  padding: ${getSpacing('s')};
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: ${getSpacing('s')};
  user-select: none;
  display: grid;
  grid-template-areas: 'select';
  align-items: center;

  span,
  &:after {
    grid-area: select;
  }

  &:hover {
    cursor: pointer;
    background-color: rgba(255, 255, 255, 0.2);
  }

  // Dropdown arrow
  &:after {
    content: '';
    width: 8px;
    height: 4.5px;
    background-color: rgba(255, 255, 255, 0.5);
    clip-path: polygon(100% 0, 0 0, 50% 100%);
    justify-self: end;
  }
`

const CustomList = styled.ul`
  padding-left: 0;
  border-radius: ${getSpacing('s')};
  background-color: ${Colors.GRAY_7};
  position: absolute;
  display: none;
  z-index: 1;
  width: 100%;
`

const ListItem = styled.li`
  list-style: none;
  padding: ${getSpacing('s')};
  &:hover {
    cursor: pointer;
    background-color: rgba(255, 255, 255, 0.2);
    -webkit-border-radius: 14px;
    -moz-border-radius: 14px;
    border-radius: 14px;

    &:first-child {
      border-radius: ${getSpacing('s')} ${getSpacing('s')} 0 0;
    }

    &:last-child {
      border-radius: 0 0 ${getSpacing('s')} ${getSpacing('s')};
    }
  }
`
