import { asEthAddress } from '@restless/ethereum'
import { asArray, asNumber, asObject, asString } from '@restless/sanitizers'

export interface HistoryEntry {
  score: string
  epoch: number
  timestamp: number
}

export const asHistoryEntry = asObject<HistoryEntry>({
  score: asString,
  epoch: asNumber,
  timestamp: asNumber,
})

/* -------------------------------------------------------------------------- */

export interface ScoreHistory {
  account: string
  protocol: string
  history: HistoryEntry[]
}

export const asScoreHistory = asObject<ScoreHistory>({
  account: asEthAddress,
  protocol: asString,
  history: asArray(asHistoryEntry),
})
