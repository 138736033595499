import { ExclamationIcon } from '@heroicons/react/solid'
import { EthereumAsset, GoerliAsset, MumbaiAsset, PolygonAsset } from 'assets'
import { Box } from 'components/Box'
import { useSupportedWeb3 } from 'components/SupportedChainsWrapper'
import { useChainSwitcher, useDebugMode } from 'hooks'
import { useEffect, useRef, useState } from 'react'
import { ButtonContainer, ChainItem, ChainList } from './styles'

const ChainLogo = ({ asset }: { asset: string }) => <img className="w-6" src={asset} />

const UnsupportedIcon = () => <ExclamationIcon color="#eec26b" style={{ width: 25 }} />

const getLogoForChainId = (chainId?: number) => {
  switch (chainId) {
    case 1:
      return <ChainLogo asset={EthereumAsset} />
    case 5:
      return <ChainLogo asset={GoerliAsset} />
    case 137:
      return <ChainLogo asset={PolygonAsset} />
    case 80001:
      return <ChainLogo asset={MumbaiAsset} />
    default:
      return <UnsupportedIcon />
  }
}

export const ChainSelector = () => {
  const { chainId } = useSupportedWeb3()
  const [open, setOpen] = useState(false)
  /* eslint-disable-next-line  @typescript-eslint/no-explicit-any */
  const ref = useRef<any>()
  const { switchChainId } = useChainSwitcher()
  const { debug } = useDebugMode()

  useEffect(() => {
    const checkIfClickedOutside = (e: Event) => {
      if (open && ref.current && !ref.current.contains(e.target)) {
        setOpen(false)
      }
    }

    document.addEventListener('mousedown', checkIfClickedOutside)

    return () => {
      document.removeEventListener('mousedown', checkIfClickedOutside)
    }
  }, [open, setOpen])

  const debugChains = [
    {
      name: 'Goerli Testnet',
      chainId: 5,
    },
    {
      name: 'Mumbai Testnet',
      chainId: 80001,
    },
  ]
  const currentChainIsTestNet = debugChains.filter((c) => c.chainId === chainId).length > 0
  const chains = [
    {
      name: 'Ethereum Mainnet',
      chainId: 1,
    },
    {
      name: 'Polygon Mainnet',
      chainId: 137,
    },
    ...(debug || currentChainIsTestNet ? debugChains : []),
  ]

  return (
    <Box style={{ position: 'relative' }}>
      <ButtonContainer onClick={() => setOpen((prev) => !prev)}>
        {getLogoForChainId(chainId)}
      </ButtonContainer>
      {open && (
        <ChainList ref={ref}>
          {chains.map((chain, index) => (
            <ChainItem
              onClick={() => {
                switchChainId(chain.chainId)
                setOpen(false)
              }}
              key={`chain_${index}`}
            >
              <div className="flex gap-3">
                {getLogoForChainId(chain.chainId)}
                <span className="whitespace-nowrap">{chain.name}</span>
              </div>
            </ChainItem>
          ))}
        </ChainList>
      )}
    </Box>
  )
}
