import { useWeb3React } from '@web3-react/core'
import { Box, Text } from 'components'
import { BigNumber } from 'ethers'
import { useArcxApi, useAsync, useResponsive } from 'hooks'
import { useAllScoreAttributes } from 'hooks/useAllScoreAttributes'
import { useState } from 'react'
import { CompleteScore } from 'types'
import { getSpacing } from 'utils'
import { ScoreListItemDesktop, ScoreListItemMobile } from './ScoreListItem'
import { ScoreListSkeleton } from './ScoreListSkeleton'

type Tab = 0 | 1

interface IScoreListTabs {
  selectTab: (tab: Tab) => void
  selected: Tab
  isOtherAccount: boolean
  hasScores: boolean
}

const ScoreListTabs = ({ selectTab, selected, isOtherAccount, hasScores }: IScoreListTabs) => {
  const [hover0, setHover0] = useState(false)
  const [hover1, setHover1] = useState(false)
  const { width } = useResponsive()
  const isMobile = width < 800
  return (
    <Box
      paddingLeft={isMobile ? '0px' : getSpacing('s')}
      justifyContent={isMobile ? 'center' : 'flex-start'}
      flexDirection="row"
      marginBottom={getSpacing('m')}
    >
      {hasScores && (
        <Box
          onClick={() => selectTab(0)}
          onMouseEnter={() => setHover0(true)}
          onMouseLeave={() => setHover0(false)}
          marginRight={getSpacing('s')}
        >
          <Text
            style={{ cursor: 'pointer' }}
            size="l"
            weight="medium"
            color={selected === 0 || hover0 ? 'WHITE' : 'WHITE_30'}
          >
            {isOtherAccount ? 'Scores' : 'Your Scores'}
          </Text>
        </Box>
      )}
      <Box
        onClick={() => selectTab(1)}
        onMouseEnter={() => setHover1(true)}
        onMouseLeave={() => setHover1(false)}
      >
        <Text
          style={{ cursor: 'pointer' }}
          size="l"
          weight="medium"
          color={!hasScores || selected === 1 || hover1 ? 'WHITE' : 'WHITE_30'}
        >
          {isOtherAccount ? 'Other Scores' : 'All Scores'}
        </Text>
      </Box>
    </Box>
  )
}

interface IScoreList {
  account: string
  isOtherAccount: boolean
}

export const ScoreList = ({ account, isOtherAccount }: IScoreList) => {
  const arcxApi = useArcxApi()
  const { width } = useResponsive()
  const { chainId, account: connectedAccount } = useWeb3React()
  const [selectedTab, setSelectedTab] = useState<Tab>(0)
  const { userScoreWithAttributes } = useAllScoreAttributes()

  const [scores] = useAsync<CompleteScore[] | undefined>(async () => {
    if (account !== connectedAccount && chainId && arcxApi) {
      return arcxApi.getScoresWithAttributes(account)
    } else {
      return userScoreWithAttributes
    }
  }, [account, chainId, arcxApi, userScoreWithAttributes])

  if (!scores) {
    return <ScoreListSkeleton />
  }

  const ownScores = scores.filter(
    ({ simpleScore }) => simpleScore && BigNumber.from(simpleScore.score || '0').gt(0),
  )
  const hasScores = ownScores.length > 0

  const otherScores = scores.filter((score) => !ownScores.includes(score))

  let scoresToShow: CompleteScore[] = []
  if (selectedTab === 1 || !hasScores) {
    scoresToShow = otherScores
  } else if (selectedTab === 0) {
    scoresToShow = ownScores
  }

  if (width < 800) {
    return (
      <Box style={{ width: '100%' }}>
        <ScoreListTabs
          isOtherAccount={isOtherAccount}
          selected={selectedTab}
          selectTab={setSelectedTab}
          hasScores={hasScores}
        />

        {scoresToShow.map((score, i) => (
          <ScoreListItemMobile key={i} {...score} isOtherAccount={isOtherAccount} />
        ))}
      </Box>
    )
  }

  return (
    <Box style={{ width: '550px' }}>
      <ScoreListTabs
        isOtherAccount={isOtherAccount}
        selected={selectedTab}
        selectTab={setSelectedTab}
        hasScores={hasScores}
      />
      {scoresToShow.map((score, i) => (
        <ScoreListItemDesktop key={i} {...score} isOtherAccount={isOtherAccount} />
      ))}
    </Box>
  )
}
