const fontSizes = {
  xxxxs: 8,
  xxxs: 10,
  xxs: 12,
  xs: 14,
  s: 16,
  m: 20,
  l: 24,
  xl: 28,
  xxl: 40,
  xxxl: 48,
}

export function getFontSize(size: keyof typeof fontSizes = 's'): string {
  return `${fontSizes[size]}px`
}
