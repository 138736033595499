import { getEnv } from 'utils'

export const sharedConfig = {
  arcxAnalyticsApiKey: getEnv('REACT_APP_ARCX_API_KEY'),
  reCaptchaSiteKey: getEnv('REACT_APP_RECAPTCHA_SITE_KEY'),
  backendUrl: getEnv('REACT_APP_BACKEND_URL', 'https://stage.api.arcx.money'),
  rollbarConfig: {
    accessToken: getEnv('REACT_APP_ROLLBAR_TOKEN'),
    captureUncaught: true,
    captureUnhandledRejections: true,
    environment: getEnv('REACT_APP_ENVIRONMENT', 'dev'),
  },
}
