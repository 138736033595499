import { css } from 'styled-components'
import { getFontSize } from 'utils'
import { Colors } from '../../constants'
import { TextInterface, TextWeight } from './types'

const weightCss = (weight: TextWeight = 'medium') => css`
  ${() => {
    switch (weight) {
      case 'thin':
        return '200'
      case 'regular':
        return '300'
      case 'medium':
        return '400'
      case 'semi-bold':
        return '600'
      case 'bold':
        return '700'
      case 'heavy':
        return '900'
      default:
        return '400'
    }
  }}
`

export const combinedCss = ({
  size = 'm',
  weight = 'medium',
  color = 'WHITE',
  fontFamily = 'Matter',
  center,
  overwriteSize,
}: TextInterface) => css`
  margin: 0;
  padding: 0;
  color: ${color ? Colors[color] : Colors.WHITE};
  font-family: ${fontFamily};
  font-size: ${overwriteSize ? `${overwriteSize}px` : getFontSize(size)};
  font-weight: ${weightCss(weight)};
  ${center ? 'text-align: center;' : ''}}
`
