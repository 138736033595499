import { getSpacing } from 'utils'
import { BodyText, Box, Link } from '..'

interface IErrorBox {
  error?: string
}

export const ErrorBox = ({ error }: IErrorBox) => {
  return (
    <Box justifyContent="center" alignItems="center">
      <Box
        borderRadius="10px"
        padding={`${getSpacing('s')} ${getSpacing('m')}`}
        flexDirection="column"
        style={{
          background:
            'linear-gradient(275.35deg, rgba(222, 97, 97, 0.2) 4.41%, rgba(222, 97, 97, 0) 72.69%)',
          width: '400px',
          border: '1px solid rgba(222, 97, 97, 0.4)',
        }}
      >
        <BodyText style={{ lineHeight: '30px' }} color="RED_4" size="s">
          <>
            Oops, something went wrong. Please notify us on
            <Link
              style={{ textDecoration: 'underline' }}
              size="s"
              color="RED_4"
              externalURL="https://discord.gg/skwz6je"
            >
              {' '}
              Discord
            </Link>
            .
          </>
        </BodyText>
        {error && (
          <BodyText color="RED_4" size="s" style={{ marginTop: getSpacing('m') }}>
            {error}
          </BodyText>
        )}
      </Box>
    </Box>
  )
}
