export type BreakpointType = 'MOBILE_S' | 'MOBILE' | 'TABLET' | 'TABLET_LANDSCAPE' | 'DESKTOP'

type BreakpointsType = {
  [key in BreakpointType]: number
}

export const Breakpoints: BreakpointsType = {
  MOBILE_S: 480,
  MOBILE: 600,
  TABLET: 768,
  TABLET_LANDSCAPE: 1024,
  DESKTOP: 1350,
}
