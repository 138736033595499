import { Box, Text } from 'components'
import { Colors } from 'constants/colors'
import styled from 'styled-components'
import { getSpacing } from 'utils'

const IconWrapperStyled = styled.div`
  width: 50px;
  height: 50px;
  border-radius: 25px;
  margin-right: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${Colors.WHITE_10};
  font-size: 24px;
`

interface IDescriptionPoint {
  primaryMessage: string
  secondaryMessage: string
  icon: string
}

export const DescriptionPoint = ({ primaryMessage, secondaryMessage, icon }: IDescriptionPoint) => (
  <Box
    style={{ maxWidth: '320px' }}
    alignItems="center"
    flexDirection="row"
    marginBottom={getSpacing('m')}
  >
    <IconWrapperStyled>{icon}</IconWrapperStyled>
    <Box>
      <Box>
        <Text size="m" weight="medium" color="WHITE">
          {primaryMessage}
        </Text>
      </Box>
      <Box>
        <Text weight="regular" size="s" style={{ color: Colors.GRAY_5 }}>
          {secondaryMessage}
        </Text>
      </Box>
    </Box>
  </Box>
)
