import { getEnv } from 'utils'
import { sharedConfig } from './config.shared'
import { IConfig } from './IConfig'

const config: IConfig = {
  ...sharedConfig,
  rpcUrl: `https://eth-goerli.alchemyapi.io/v2/${getEnv(
    'REACT_APP_GOERLI_ALCHEMY_KEY',
    'OVhc4wQbG1nzadxsglVOsUSvDCc67PsP',
  )}`,
  farms: [],
}

export default config
