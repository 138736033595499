import { Box, Text } from 'components'
import { CoreCapsuleWithAccount } from 'entities'
import { BigNumber } from 'ethers'
import { getSpacing } from 'utils'
import { getGrowthBarDetails } from './getGrowthBarDetails'

export interface IGrowthSuggestionProps {
  borrowUsage: BigNumber
  coreCapsules: CoreCapsuleWithAccount[]
}

export const GrowthSuggestion = ({ borrowUsage, coreCapsules }: IGrowthSuggestionProps) => {
  const { suggestionIcon, suggestionText } = getGrowthBarDetails(borrowUsage, coreCapsules)

  return (
    <Box flexDirection="row" alignItems="center">
      {suggestionIcon}
      <Box paddingLeft={getSpacing('s')}>
        <Text size="xs" weight="thin">
          {suggestionText}
        </Text>
      </Box>
    </Box>
  )
}
