import { Row } from 'components'
import { Colors } from 'constants/colors'
import { matchPath, useLocation } from 'react-router-dom'
import styled from 'styled-components'
import { ILink } from '../types'

const AppNavigationListItemContainer = styled.div<{ active: boolean }>`
  color: ${(p) => (p.active ? Colors.WHITE : Colors.GRAY_7)};
  &:hover {
    cursor: pointer;
    color: ${Colors.WHITE};
  }
`

const AppNavigationListItem = styled.span`
  font-size: 18px;
  font-weight: 500;
`

export const AppNavigationLinkItem = ({ link }: { link: ILink }) => {
  const location = useLocation()

  const active = !!matchPath(location.pathname, link.to)

  return (
    <AppNavigationListItemContainer active={active}>
      <Row alignItems="center">
        <AppNavigationListItem>{link.title}</AppNavigationListItem>
      </Row>
    </AppNavigationListItemContainer>
  )
}
