import { BigNumber, constants } from 'ethers'

export function quickWeiValuePow(value: BigNumber, power: BigNumber, splitter = 1000): BigNumber {
  if (power.isZero()) {
    return constants.WeiPerEther
  } else if (power.lte(splitter)) {
    return value.pow(power).div(constants.WeiPerEther.pow(power.sub(1)))
  } else {
    const reducedPower = power.div(splitter)
    const restPower = power.sub(reducedPower.mul(splitter))
    let result = quickWeiValuePow(
      value.pow(splitter).div(constants.WeiPerEther.pow(splitter - 1)),
      reducedPower,
      splitter,
    )
    for (let i = 0; i < restPower.toNumber(); i++) {
      result = result.mul(value).div(constants.WeiPerEther)
    }
    return result
  }
}
