import { asString, Result, Sanitizer, SanitizerFailure } from '@restless/sanitizers'

export const asRecord =
  <U>(asValue: Sanitizer<U>): Sanitizer<Record<string, U>> =>
    (value: unknown, path) => {
      if (typeof value !== 'object' || value === null) {
        return Result.error([{ path, expected: 'object' }])
      }
      const results = {}
      const errors: SanitizerFailure[] = []
      for (const key in value) {
        const keyResult = asString(key, `${key} should be a string`)
        if (Result.isError(keyResult)) {
          errors.push(...keyResult.error)
        }

        // Cast will remove these keys from target object
        if (value[key] === null || value[key] === undefined) {
          continue
        }

        const result = asValue(value[key], `${path}.${key}`)
        if (Result.isOk(result)) {
          results[key] = result.ok
        } else {
          errors.push(...result.error)
        }
      }
      return errors.length > 0 ? Result.error(errors) : Result.ok(results)
    }
