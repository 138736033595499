import { approve, BASE, getEmptyScoreProof } from '@arcxgame/contracts'
import { PassportScoreProof } from '@arcxgame/contracts/arc-types/sapphireTypes'
import { BaseERC20Factory } from '@arcxgame/contracts/src/typings'
import { configConstants } from 'config/configConstants'
import { BigNumber, ContractTransaction, providers, Signer, utils } from 'ethers'
import { CompleteScore, ScoreWithProof } from 'types'
import { bigDiv, bigMul, reduceValue, scaleValue } from 'utils'
import { ArcxApi } from '../ArcxApi'
import { CoreCapsule } from './CoreCapsule'
import { PoolCapsule } from './PoolCapsule'
import { PoolCapsuleWithAccount } from './PoolCapsuleWithAccount'

export type AccountProperties = {
  /* ----------------------- Contract-derived properties ---------------------- */
  principalAmount: BigNumber
  interestAccrued: BigNumber
  borrowedAmountScaled: BigNumber
  collateralAmount: BigNumber
  collateralAmountScaled: BigNumber
  collateralValue: BigNumber
  accountCollateralBalance: BigNumber

  /* ------------------ Credit score derived properties ----------------- */
  currentLTV: BigNumber
  liquidationPrice: BigNumber
  borrowUsage: BigNumber
  maxLTV: BigNumber

  /* --------------------------------- Scores --------------------------------- */
  creditLimit: CompleteScore
  creditScore: CompleteScore
}

export class CoreCapsuleWithAccount extends CoreCapsule {
  /* -------------------------------------------------------------------------- */
  /*                                 Properties                                 */
  /* -------------------------------------------------------------------------- */

  accountProperties?: AccountProperties

  /* ------------------------------- Calculated ------------------------------- */

  get accountCanView(): boolean {
    if (!this.accountProperties) {
      return false
    }

    const { creditScore } = this.accountProperties
    const { threshold } = this.creditLimitAttributes

    return (
      BigNumber.from(threshold || '0').eq(0) ||
      BigNumber.from(creditScore?.simpleScore?.score || '0').gte(BigNumber.from(threshold))
    )
  }

  get accountCanBorrow(): boolean {
    if (!this.accountProperties || !this.accountCanView) {
      return false
    }

    const { creditLimit } = this.accountProperties

    const limitProofRequired = this.defaultBorrowLimit.eq(0)

    // Open vault, no proof needed, anyone can Borrow
    if (!limitProofRequired) {
      return true
    }

    const hasProof = !!creditLimit.proof

    return limitProofRequired && hasProof
  }

  /* -------------------------------------------------------------------------- */
  /*                                   Methods                                  */
  /* -------------------------------------------------------------------------- */

  constructor(
    public readonly contractAddress: string,
    public provider: providers.JsonRpcProvider | Signer,
    public account?: string,
  ) {
    super(contractAddress, provider)
  }

  /* ----------------------------- Public methods ----------------------------- */

  async initializeAccountProperties(
    arcxApi: ArcxApi,
    creditScoreInfo: CompleteScore,
  ): Promise<void> {
    const [scores, contractDerivedProperties] = await Promise.all([
      this._getLimitScore(arcxApi),
      this._getAccountContractDerivedProperties(),
    ])
    const { simpleScore } = creditScoreInfo

    const scoreDerivedProps = this._getScoreDerivedProperties(
      contractDerivedProperties.borrowedAmountScaled,
      contractDerivedProperties.collateralAmount,
      BigNumber.from(simpleScore?.score || 0),
    )

    this.accountProperties = {
      ...contractDerivedProperties,
      creditLimit: {
        simpleScore: scores.fullLimitScore,
        proof: scores.limitProof,
      },
      creditScore: creditScoreInfo,
      ...scoreDerivedProps,
    }
  }

  getAccountMaxBorrowAmount(
    poolCapsule: PoolCapsule,
    borrowAssetAddress: string,
    additionalCollateral?: BigNumber,
    additionalBorrowAmt?: BigNumber,
  ): BigNumber {
    if (!this.accountProperties || !this.accountCanBorrow) {
      return BigNumber.from(0)
    }

    const { collateralAmount, borrowedAmountScaled, creditLimit, maxLTV } = this.accountProperties
    const borrowAsset = poolCapsule.supportedDepositAssets.find(
      (asset) => asset.address === borrowAssetAddress,
    )

    // Altered amounts used to calculated future max borrow amounts
    const consideredCollateralAmt = collateralAmount.add(additionalCollateral || 0)
    // additionalCollateral or additionalBorrowAmt could be negative numbers which can result in
    // consideredCollateralAmt being less than 0
    if (consideredCollateralAmt.lte(0)) {
      return BigNumber.from(0)
    }

    const consideredBorrowAmtScaled = borrowedAmountScaled.add(
      additionalBorrowAmt ? scaleValue(additionalBorrowAmt, borrowAsset.decimals) : 0,
    )
    const consideredCurrentLTV = consideredBorrowAmtScaled
      .mul(BASE)
      .div(consideredCollateralAmt.mul(this.collateralPrice).div(BASE))

    // Find available amount in pool
    const borrowAssetPoolBalanceScaled = scaleValue(borrowAsset.balance, borrowAsset.decimals)
    const scaledAvailableBorrowAmount = borrowAssetPoolBalanceScaled.gte(this.usageInPool.remaining)
      ? this.usageInPool.remaining
      : borrowAssetPoolBalanceScaled

    // Get total max borrow amount
    const consideredBorrowUsage = consideredCurrentLTV.mul(BASE).div(maxLTV)
    let maxLoanScaled: BigNumber
    if (consideredBorrowUsage.gt(0)) {
      maxLoanScaled = bigDiv(consideredBorrowAmtScaled, consideredBorrowUsage)
    } else {
      maxLoanScaled = bigMul(bigMul(consideredCollateralAmt, this.collateralPrice), maxLTV)
    }

    let maxAdditionalBorrowAmtScaled = maxLoanScaled.sub(consideredBorrowAmtScaled)

    /* ---------------------------- Apply any limits ---------------------------- */

    // Credit limits
    let enforcedBorrowLimit = this.defaultBorrowLimit
    const creditLimitFromProof = BigNumber.from(creditLimit.simpleScore?.score || 0)
    // Use the greater of the default limit and the user's credit limit, if they have one
    if (creditLimitFromProof.gt(this.defaultBorrowLimit)) {
      enforcedBorrowLimit = creditLimitFromProof
    }

    if (maxAdditionalBorrowAmtScaled.add(consideredBorrowAmtScaled).gt(enforcedBorrowLimit)) {
      maxAdditionalBorrowAmtScaled = enforcedBorrowLimit.sub(consideredBorrowAmtScaled)
    }

    // Availability in pool
    if (maxAdditionalBorrowAmtScaled.gt(scaledAvailableBorrowAmount)) {
      maxAdditionalBorrowAmtScaled = scaledAvailableBorrowAmount
    }

    return reduceValue(maxAdditionalBorrowAmtScaled, borrowAsset.decimals)
  }

  /**
   * Returns the max withdraw amount derived from the current LTV of the user's position and the
   * max LTV on the core.
   */
  getAccountMaxWithdrawAmount(): BigNumber {
    if (!this.accountProperties) {
      return BigNumber.from(0)
    }
    const { collateralAmountScaled, maxLTV, borrowedAmountScaled } = this.accountProperties

    const maxWithdrawScaled = collateralAmountScaled.sub(
      bigDiv(borrowedAmountScaled, bigMul(maxLTV, this.collateralPrice)),
    )

    return reduceValue(maxWithdrawScaled, this.collateralDecimals)
  }

  getAccountMaxRepayAmount(
    poolCapsule: PoolCapsuleWithAccount,
    repayAssetAddress: string,
  ): BigNumber {
    if (!this.accountProperties || !poolCapsule.accountProperties) {
      return BigNumber.from(0)
    }

    if (poolCapsule.account !== this.account) {
      throw new Error(
        'CoreCapsuleWithAccount::getAccountMaxRepayAmount: poolCapsule.account !== this.account',
      )
    }

    const repayAsset = poolCapsule.supportedDepositAssets.find(
      (asset) => asset.address === repayAssetAddress,
    )
    if (!repayAsset) {
      throw new Error('CoreCapsuleWithAccount::getAccountMaxRepayAmount: Repay asset not found')
    }

    const { borrowedAmountScaled: borrowedAmount } = this.accountProperties

    const userBalance = poolCapsule.accountProperties.depositAssetBalances[repayAssetAddress]
    const userBalanceScaled = scaleValue(userBalance, repayAsset.decimals)

    return userBalanceScaled.gte(borrowedAmount)
      ? reduceValue(borrowedAmount, repayAsset.decimals)
      : userBalance
  }

  async deposit(amount: BigNumber): Promise<ContractTransaction> {
    await approve(amount, this.collateralAddress, this.contractAddress, this.provider as Signer)
    return this._core.deposit(amount, this._getScoreProofsForAction())
  }

  borrow(amount: BigNumber, assetAddress: string): Promise<ContractTransaction> {
    return this._core.borrow(amount, assetAddress, this._getScoreProofsForAction())
  }

  async repay(amount: BigNumber, assetAddress: string): Promise<ContractTransaction> {
    await approve(amount, assetAddress, this.contractAddress, this.provider as Signer)

    return this._core.repay(amount, assetAddress, this._getScoreProofsForAction())
  }

  withdraw(amount: BigNumber): Promise<ContractTransaction> {
    return this._core.withdraw(amount, this._getScoreProofsForAction())
  }

  async exit(assetInfo: { address: string; decimals: number }): Promise<ContractTransaction> {
    await approve(
      reduceValue(
        this.accountProperties.borrowedAmountScaled.add(utils.parseEther('0.01')),
        assetInfo.decimals,
      ),
      assetInfo.address,
      this._core.address,
      this.provider as Signer,
    )

    return this._core.exit(assetInfo.address, this._getScoreProofsForAction())
  }

  /* ----------------------------- Private methods ---------------------------- */

  protected async _refresh(): Promise<void> {
    await super._refresh()

    /**
     * Refresh the dynamic properties of the contract and the account's
     */
    const erc20 = BaseERC20Factory.connect(this.collateralAddress, this.provider)
    const [totalCollateral, accountProps] = await Promise.all([
      erc20.balanceOf(this.contractAddress),
      this._getAccountContractDerivedProperties(),
    ])

    this.totalCollateral = totalCollateral

    if (accountProps) {
      const scoreDerivedProps = this._getScoreDerivedProperties(
        accountProps.borrowedAmountScaled,
        accountProps.collateralAmount,
        BigNumber.from(this.accountProperties.creditScore.simpleScore?.score || '0'),
      )

      this.accountProperties = {
        ...this.accountProperties,
        ...accountProps,
        ...scoreDerivedProps,
      }
    } else {
      // Account was disconnected. Clean account properties
      delete this.accountProperties
    }
  }

  private async _getAccountContractDerivedProperties() {
    if (!this.account) {
      return undefined
    }

    const erc20 = BaseERC20Factory.connect(this.collateralAddress, this.provider)

    const [vault, accountCollateralBalance] = await Promise.all([
      this._core.vaults(this.account),
      erc20.balanceOf(this.account),
    ])

    const { principal: principalAmount, normalizedBorrowedAmount, collateralAmount } = vault
    const borrowedAmountScaled = normalizedBorrowedAmount.mul(this.currentBorrowIndex).div(BASE)
    const interestAccrued = borrowedAmountScaled.sub(principalAmount)
    const collateralAmountScaled = scaleValue(collateralAmount, this.collateralDecimals)

    return {
      principalAmount,
      interestAccrued,
      borrowedAmountScaled,
      collateralAmount,
      collateralAmountScaled,
      accountCollateralBalance,
    }
  }

  private async _getLimitScore(arcxApi: ArcxApi) {
    const proofReadyScore: ScoreWithProof | undefined = (await arcxApi.getScore(
      this.account,
      this.creditLimitProtocol,
      'proof',
    )) as ScoreWithProof | undefined
    const limitProof: PassportScoreProof | undefined = proofReadyScore?.merkleProof && {
      account: proofReadyScore.account,
      protocol: proofReadyScore.protocol,
      score: BigNumber.from(proofReadyScore.score),
      merkleProof: proofReadyScore.merkleProof,
    }

    return {
      fullLimitScore: proofReadyScore,
      limitProof,
    }
  }

  private _getScoreDerivedProperties(
    borrowedAmountScaled: BigNumber,
    collateralAmount: BigNumber,
    creditScore: BigNumber,
  ) {
    const scaledCollateralAmount = scaleValue(collateralAmount, this.collateralDecimals)
    const collateralValue = scaledCollateralAmount.mul(this.collateralPrice).div(BASE)

    let currentLTV = BigNumber.from(0)
    if (borrowedAmountScaled.gt(0)) {
      currentLTV = borrowedAmountScaled.mul(BASE).div(collateralValue)
    }

    let accountMaxLTV = this.minLTV
    let userMinCRatio = this.highCollateralRatio
    if (creditScore.gt(0)) {
      const creditScoreRatioScaled = utils.parseEther(
        (creditScore.toNumber() / configConstants.maxCreditScore).toString(),
      )
      userMinCRatio = this.highCollateralRatio.sub(
        bigMul(creditScoreRatioScaled, this.highCollateralRatio.sub(this.lowCollateralRatio)),
      )
      accountMaxLTV = bigDiv(BASE, userMinCRatio)
    }

    const borrowUsage = bigDiv(currentLTV, accountMaxLTV)

    let liquidationPrice = BigNumber.from(0)
    if (borrowUsage.gt(0)) {
      liquidationPrice = userMinCRatio.mul(borrowedAmountScaled).div(scaledCollateralAmount)
    }

    return {
      currentLTV,
      maxLTV: accountMaxLTV,
      borrowUsage,
      collateralValue,
      liquidationPrice,
    }
  }

  private _getScoreProofsForAction() {
    if (!this.accountProperties) {
      return [
        getEmptyScoreProof(undefined, this.creditScoreProtocol),
        getEmptyScoreProof(undefined, this.creditLimitProtocol),
      ]
    }

    const {
      creditScore: { proof: creditScoreProof },
      creditLimit: { proof: creditLimitProof },
    } = this.accountProperties

    return [
      creditScoreProof ||
        getEmptyScoreProof(this.account, utils.formatBytes32String(this.creditScoreProtocol)),
      creditLimitProof ||
        getEmptyScoreProof(this.account, utils.formatBytes32String(this.creditLimitProtocol)),
    ]
  }
}
