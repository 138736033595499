import { getSpacing } from 'utils'
import { Box } from './Box'

export const HorizontalSpacer = () => (
  <Box
    marginTop={getSpacing('m')}
    marginBottom={getSpacing('m')}
    width="100%"
    height="1px"
    backgroundColor="TEXT_DARK"
    style={{ opacity: 0.5 }}
  />
)
