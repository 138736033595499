/* eslint-disable brace-style */
import { providers, Signer } from 'ethers'
import { TypedEmitter } from 'tiny-typed-emitter'

/**
 * The contract capsule is an interface that is meant to save the state of a contract so that
 * the client does not have to interact with the contract directly, while getting its state in
 * a synchronous manner.
 */
export interface IContractCapsule {
  provider: Signer | providers.JsonRpcProvider
  contractAddress: string
}

export interface CapsuleRefreshEmitterEvents {
  refreshStarted: (address: string) => void
  refreshFinished: (address: string) => void
}

/**
 * An abstract class meant to be used with other Capsule classes, which enforces the sub-class
 * to implement a refresh() method. Once called, this method will emit the `refreshStarted` and
 * `refreshFinished` events.
 *
 * The class is meant to be instantiated with its subclass' factory class.
 */
export abstract class CapsuleRefreshEmitter
  extends TypedEmitter<CapsuleRefreshEmitterEvents>
  implements IContractCapsule
{
  constructor(
    public readonly contractAddress: string,
    public provider: providers.JsonRpcProvider | Signer,
  ) {
    super()
  }

  async refresh() {
    this.emit('refreshStarted', this.contractAddress)
    await this._refresh()
    this.emit('refreshFinished', this.contractAddress)
  }

  abstract initializeState(...args: unknown[]): Promise<void>

  protected abstract _refresh(): Promise<void>
}
