import { ArcxApi } from 'entities'
import { providers, Signer } from 'ethers'
import { AbstractCapsuleFactory } from './AbstractCapsuleFactory'
import { PoolCapsuleWithAccount } from './PoolCapsuleWithAccount'

export class PoolCapsuleWithAccountFactory extends AbstractCapsuleFactory {
  async create(
    contractAddress: string,
    provider: providers.JsonRpcProvider | Signer,
    _arcxApi?: ArcxApi,
    account?: string,
  ): Promise<PoolCapsuleWithAccount> {
    const pool = new PoolCapsuleWithAccount(contractAddress, provider, account)
    await pool.initializeState()
    return pool
  }
}
