import { useWeb3React } from '@web3-react/core'
import { Box, Button, ConnectInterface, Link, Text } from 'components'
import { useModal } from 'contexts'
import { useClaim } from 'hooks'
import { getSpacing } from 'utils'

export const ClaimButton = () => {
  const { account } = useWeb3React()
  const { claim, loading, error } = useClaim()
  const { openWith } = useModal()
  const onConnectClick = () => openWith(<ConnectInterface />)

  return (
    <>
      <Button
        onClick={() => (account ? claim() : onConnectClick())}
        size="s"
        variant="fancy"
        loading={loading}
        text={account ? 'Claim passport' : 'Connect to claim'}
      />
      <Box flexDirection="row" justifyContent="center">
        <Box style={{ width: '300px' }}>
          <Text center style={{ marginTop: getSpacing('xs') }} color="WHITE_40" size="xxs">
            This site is protected by reCAPTCHA and the Google
            <Link size="xxs" color="PURPLE_5" externalURL="https://policies.google.com/privacy">
              {' '}
              Privacy Policy
            </Link>{' '}
            and
            <Link size="xxs" color="PURPLE_5" externalURL="https://policies.google.com/terms">
              {' '}
              Terms of Service
            </Link>{' '}
            apply.
          </Text>
        </Box>
      </Box>

      {error && (
        <Text center style={{ marginTop: getSpacing('xs') }} color="RED_5" size="s">
          An error occurred while claiming.
        </Text>
      )}
    </>
  )
}
