import styled from 'styled-components'
import { combinedCss } from './styles'
import { TextInterface, TextProps } from './types'

const StyledSpanText = styled.span<TextInterface>`
  ${(p) => combinedCss(p)}
`

export const Text = ({ children, ...props }: TextProps) => {
  return <StyledSpanText {...props}>{children}</StyledSpanText>
}
