import { ArrowRightIcon } from '@heroicons/react/solid'
import { Box, SkeletonRectangle, Text } from 'components'
import { Colors } from 'constants/colors'
import { useResponsive } from 'hooks'
import { getSpacing } from 'utils'

const ScoreListItemSkeleton = () => (
  <Box
    style={{
      backgroundColor: 'transparent',
      borderTop: `1px solid ${Colors.WHITE_10}`,
      borderBottom: `1px solid ${Colors.WHITE_10}`,
      width: '100%',
    }}
  >
    <Box padding={`${getSpacing('m')} ${getSpacing('s')}`}>
      <Box
        style={{
          marginBottom: getSpacing('m'),
        }}
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <SkeletonRectangle width="100px" />
        <SkeletonRectangle width="80px" />
      </Box>
      <Box width="80%" marginBottom={getSpacing('s')}>
        <SkeletonRectangle style={{ marginBottom: '8px' }} width="300px" />
        <SkeletonRectangle style={{ marginBottom: '8px' }} width="290px" />
        <SkeletonRectangle width="250px" />
      </Box>
      <Box flexDirection="row" justifyContent="space-between" alignItems="center">
        <Box flexDirection="row" alignItems="center">
          <Text
            size="s"
            weight="regular"
            style={{
              color: Colors.WHITE_80,
              marginRight: getSpacing('xs'),
            }}
          >
            Indexed On
          </Text>
          <Text size="s">
            <SkeletonRectangle width="60px" />
          </Text>
        </Box>
        <Box flexDirection="row" alignItems="center">
          <Text style={{ color: Colors.WHITE_80 }} weight="regular" size="s">
            <SkeletonRectangle width="60px" />
          </Text>
          <ArrowRightIcon
            style={{
              marginLeft: '4px',
              color: Colors.WHITE_80,
            }}
            width="16px"
          />
        </Box>
      </Box>
    </Box>
  </Box>
)

export const ScoreListSkeleton = () => {
  const { width } = useResponsive()

  return (
    <Box style={{ width: width < 800 ? '100vw' : '550px' }}>
      <ScoreListItemSkeleton />
      <ScoreListItemSkeleton />
    </Box>
  )
}
